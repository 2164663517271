import { useState } from "react";
// import { Button } from "@material-ui/core";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { Alert } from "@material-ui/lab";
import useStyles from "./loginStyle.js";
import CustomAppBar from "./AppBar";

const WebClientPasswordResetRequest = () => {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [serverResponse, setServerResponse] = useState("");

  const AgentPasswordResetRequestSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is Required*"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/user/post-reset-email`, //ProdEnv & DevEnvNgrok

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );
      const data = await response.json();
      //   console.log(data, "data");
      setServerResponse(data);
      setSubmitting(false);
      setSuccess(true);
    } catch (error) {
      console.log(error, "error");
      setSubmitting(false);
      setSuccess(false);
    }
  };

  if (success) {
    return (
      <>
        <CustomAppBar />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: " 30px",
          }}
        >
          <div style={{ margin: "10px 10px" }}>
            <div>
              <div>
                <div>
                  <h4
                    style={{
                      alignSelf: "center",
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: "7px",
                    }}
                  >
                    Password Reset Request Sent!
                  </h4>
                  <hr />
                  {/* <p className="text-center">{serverResponse.message}</p> */}
                  {serverResponse.message ===
                  "An email has been sent with instructions to reset your password." ? (
                    <Alert severity="info">{serverResponse.message}</Alert>
                  ) : (
                    <div className="text-center">
                      <Alert severity="error">{serverResponse.message}</Alert>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <CustomAppBar />
      <div
        style={{
          // backgroundColor: "#f5f5",
          display: "flex",
          margin: "0px 15px auto",
        }}
      >
        <div
          // className="container"
          style={{
            marginTop: "80px",
            width: "500px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <h1
            style={{
              fontSize: 30,
              fontWeight: "lighter",
              fontFamily: "Roboto, sans-serif",
              marginBottom: "20px",
            }}
          >
            Password Reset Request!
          </h1>
          <Formik
            initialValues={{
              email: "",
              url: "https://www.lusuku.ug/pwdResetForm", //This has to be changed to the production url but with client url
              // url: "http://localhost:8000/agent-reset-password",
              // url: "http://localhost:3000/pwdResetForm", //This has to be changed to the production url but with client url
              // url: "https://lusuku.shop/agent-reset-password",
              // url: `${process.env.REACT_APP_DASHBOARD_PROD_URL}/agent-reset-password`,
            }}
            validationSchema={AgentPasswordResetRequestSchema}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              // handleSubmit,
              handleChange,
              handleBlur,
              values,
              // touched,
              // errors,
            }) => (
              <Form>
                <div
                  style={
                    {
                      //   backgroundColor: "red",
                    }
                  }
                >
                  <label htmlFor="email">Email</label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    autoCapitalize="none"
                    autoCorrect="off"
                    placeholder="Enter your email address..."
                    className={classes.password_reset_field}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    values={values.email}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    //   className="invalid-feedback"
                    className={classes.password_reset_field_error}
                  />
                </div>
                <button
                  type="submit"
                  className={classes.reset_button}
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default WebClientPasswordResetRequest;
