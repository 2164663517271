import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, Table, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@mui/material/Container";
import momo from "../assets/img/momo.jpg";
import airtel from "../assets/img/airtel_money.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  Divider,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Image } from "react-bootstrap";
import styled from "styled-components";

import {
  createOrder,
  payAirtelMoneyCheckout,
  payMoMoCheckout,
  payCardCheckout,
} from "../redux/createOrderAction.js";
import Navbar from "../components/Navbar";
import { fetchShippingCost } from "../redux/shippingCostRedux";
import CreditCardForm from "../components/CreditCardForm/CreditCardForm";

const PayCardButton = styled.button`
  border: none;
  padding: 15px 20px;
  background-color: teal;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 4px;
  &:disabled {
    background-color: #ccc;
    color: #aaa;
    font-weight: 300;
    font-size: 14px;
    cursor: not-allowed;
  }
`;
const AirtelPayButton = styled.button`
  border: none;
  padding: 7px 15px;
  background-color: #f00;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  &:disabled {
    background-color: #ccc;
    color: #aaa;
    font-weight: 300;
    font-size: 14px;
    cursor: not-allowed;
  }
`;
const useStyles = makeStyles((theme) => ({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
      paddingBottom: "5px",
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  inputGreen: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "green",
      },
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
    },
    "& .MuiOutlinedInput-input": {
      color: "green",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "green",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "green",
    },
  },
  tableHeader: {
    backgroundColor: "#506266",
    color: "#ffffff",
    borderRadius: "8px",
  },
  tableHeaderCenter: {
    backgroundColor: "#506266",
    color: "#ffffff",
    borderRadius: "8px",
    textAlign: "center",
  },
   responsiveFont: {
    fontSize: 16,

    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },

    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: 24,
    },
  },
    responsiveImage: {
    width: '100%',
    // height: 'auto',

    [theme.breakpoints.up('md')]: {
      maxWidth: 600,
    },
  },
  responsiveCard: {
    width: '100%',
    // height: 'auto',
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.up('md')]: {
      maxWidth: 600,
    },
  },

  responsiveTable: {
    width: '100%',
    overflowX: 'auto',

    '& table': {
      minWidth: 500,
      width: '100%',

      [theme.breakpoints.up('md')]: {
        minWidth: 700,
      },
    },
  },
}));

const validationSchema = Yup.object().shape({
  cardNumber: Yup.string().required("Card Number is required."),
  expiry_month: Yup.string().required("Expiry Month is required."),
  expiry_year: Yup.string().required("Expiry Year is required."),
  cardCvv: Yup.string().required("CVV is required."),
  cardType: Yup.string().required("Card Type is required."),
  fullName: Yup.string().required("Full Name is required."),
});

const PlaceOrderScreen = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { shippingAddress, products, paymentMethod, total } = useSelector(
    (state) => state.cart
  );

  const user = useSelector((state) => state.user.currentUser);
  const [orderSource] = useState("Web");
  const { orderCreationStatus, paymentCreationStatus } = useSelector(
    (state) => state.orders
  );

  const { shippingCost } = useSelector((state) => state.shippingCosts);

  // if there are no products, redirect to cart page
  useEffect(() => {
    if (
      Object.keys(shippingAddress).length === 0 ||
      Object.keys(paymentMethod).length === 0 ||
      Object.keys(products).length === 0
    ) {
      history.push("/cart");
    }
  }, [shippingAddress, paymentMethod, products, history]);

  const formik = useFormik({
    initialValues: {
      cardNumber: "",
      expiry_month: "",
      expiry_year: "",
      cardCvv: "",
      cardType: "",
      fullName: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle your form submission here
      cardPlaceOrderHandler(values);
    },
  });

  const [postData, setPostData] = useState({
    mobile: "",
  });

  const userId = user?.result?._id;
  useEffect(() => {
    if (!userId) {
      history.push("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get shipping Cost from Server
  useEffect(() => {
    // dispatch(getShippingCost(shippingAddress?.client_id));
    dispatch(fetchShippingCost());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get Order Items on refresh.

  const serverShippingFees = shippingCost.map((cost) => cost.cost);
  const serverShippingFeesID = shippingCost.map((cost) => cost._id);

  const totalOrderCostPlusShipping = Number(total) + Number(serverShippingFees);

  // eslint-disable-next-line no-unused-expressions
  useEffect(() => {
    if (paymentMethod === "MOMO" && orderCreationStatus === "fulfilled") {
      history.push("/momoOrderPage");
    } else if (paymentMethod === "COD" && orderCreationStatus === "fulfilled") {
      history.push("/orderPage");
    }
    // eslint-disable-next-line no-sequences
  }),
    [orderCreationStatus, paymentMethod];

  const placeOrderHandler = (e) => {
    e.preventDefault();
    dispatch(
      createOrder({
        orderItems: products?.map((item) => ({
          ...item,
          vendorId: item.vendorId,
        })),
        shippingAddress: shippingAddress,
        shippingCost: serverShippingFees[0],
        shippingCost_id: serverShippingFeesID[0],
        paymentMethod: paymentMethod,
        itemsPrice: total,
        totalPrice: totalOrderCostPlusShipping,
        customerName: user?.result?.name,
        client_id: user?.result?._id,
        orderSrc: orderSource,
      })
    );
  };

  const payMoMoCheckoutHandler = (e) => {
    e.preventDefault();
    dispatch(
      payMoMoCheckout({
        mobile: postData.mobile,
        totalPrice: totalOrderCostPlusShipping,
        client_id: user?.result?._id,
        //The only payload required here is the mobile and client_id
      })

      // setOpenMomoModal(true)
    );
    dispatch(
      createOrder({
        orderItems: products,
        shippingAddress: shippingAddress,
        shippingCost: serverShippingFees[0],
        shippingCost_id: serverShippingFeesID[0],
        paymentMethod: paymentMethod,
        // itemsPrice: total,
        itemsPrice: total,
        totalPrice: totalOrderCostPlusShipping,
        customerName: user?.result?.name,
        // client_id: user?.result?._id,
        client_id: userId,
        orderSrc: orderSource,
      })
    );
  };

  // Airtel Money Pay
  const generateTxRef = (clientId) => {
    return clientId + '-' + Date.now();
  }
  const payAirtelMoneyCheckoutHandler = (e) => {
    e.preventDefault();
    const uniqueTxRef = generateTxRef(user.result._id);

    dispatch(
      payAirtelMoneyCheckout({
        mobile: postData.mobile,
        totalPrice: totalOrderCostPlusShipping,
        client_id: user?.result?._id,
        email: user?.result?.email,
        uniqueTxRef: uniqueTxRef,
        network: paymentMethod,
        redirect_url: `${window.location.origin}/airtelPay`,
      })
    ).then((action) => {
      if (!action.error && action.payload.data.meta.authorization) {
        window.location.href = action.payload.redirectUrl;
      }
    });

    dispatch(
      createOrder({
        orderItems: products,
        shippingAddress: shippingAddress,
        shippingCost: serverShippingFees[0],
        paymentMethod: paymentMethod,
        itemsPrice: total,
        totalPrice: totalOrderCostPlusShipping,
        customerName: user?.result?.name,
        client_id: userId,
        orderSrc: orderSource,
      })
    );
  };

  const cardPlaceOrderHandler = () => {
    const cardDetails = {
      // cardNumber: cardFormData.cardNumber,
      cardNumber: formik.values.cardNumber,
      expiry_month: formik.values.expiry_month,
      expiry_year: formik.values.expiry_year,
      cardCvv: formik.values.cardCvv,
      cardType: formik.values.cardType,
      fullName: formik.values.fullName,
    };

    dispatch(
      payCardCheckout({
        cardDetails,
        totalPrice: totalOrderCostPlusShipping,
        client_id: user?.result?._id,
        customer_name: user?.result?.name,
        email: user?.result?.email,
        network: paymentMethod,
        // redirect_url: `${window.location.origin}/pay/authorize`,
        redirect_url: `${window.location.origin}/cardOrder`,
      })
    )
      .then((action) => {
        // console.log(action, "action>>>");
        // console.log(action.meta.arg.redirect_url, "action>>>");
        if (action.payload.redirectUrl) {
          // console.log(action, "action2>>>");
          window.location.href = action.payload.redirectUrl;
        }
      })
      .catch((err) => {
        console.error("Error while dispatching payCardCheckout:", err);
      });

    dispatch(
      createOrder({
        orderItems: products,
        shippingAddress: shippingAddress,
        shippingCost: serverShippingFees[0],
        paymentMethod: paymentMethod,
        itemsPrice: total,
        totalPrice: totalOrderCostPlusShipping,
        customerName: user?.result?.name,
        client_id: userId,
        orderSrc: orderSource,
      })
    );
  };

  // Show specific button text based on redux state
  let airtelButtonText;
  switch (true) {
    case paymentCreationStatus === "processing payment":
      airtelButtonText = "Processing Payment...";
      break;
    case paymentCreationStatus === "fulfilled":
      airtelButtonText = "Payment Initiated!";
      break;
    case paymentCreationStatus === "rejected":
      airtelButtonText = "Payment attempt Failed, try again!";
      break;
    case orderCreationStatus === "pending":
      airtelButtonText = "Creating Order...";
      break;
    case orderCreationStatus === "fulfilled":
      airtelButtonText = "Order Created...";
      break;
    default:
      airtelButtonText = "Proceed to Pay";
  }

  return (
    <>
      <Navbar />

      <Container 
      maxWidth="100%"
      // maxWidth="lg" 
      style={{ marginTop: "30px" }}
      >
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography
                className={classes.responsiveFont}
                // className="responsive-font"
                  sx={{ fontSize: 18, fontStyle: "italic" }}
                  gutterBottom
                >
                  SHIPPING ADDRESS
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  variant="body"
                  sx={{ fontSize: 13 }}
                  color="text.secondary"
                >
                  <b>Address:</b>{" "}
                  <span
                    style={{
                      fontStyle: "italic",
                      fontSize: "12px",
                      color: "#00668bc4",
                    }}
                  >
                    {shippingAddress.locationName &&
                    Object.keys(shippingAddress.locationName).length !== 0
                      ? shippingAddress.locationName.name
                      : "Not available"}
                  </span>
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  variant="body"
                  sx={{ fontSize: 13 }}
                  color="text.secondary"
                >
                  <b>Customer Contact:</b>{" "}
                  <span
                    style={{
                      fontStyle: "italic",
                      fontSize: "12px",
                      color: "#00668bc4",
                    }}
                  >
                    {shippingAddress.customerNumber &&
                    Object.keys(shippingAddress.customerNumber).length !== 0
                      ? shippingAddress.customerNumber
                      : "Not available"}
                  </span>
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  variant="body"
                  sx={{ fontSize: 15 }}
                  color="text.secondary"
                >
                  <b>Delivery Zone:</b>{" "}
                  <span
                    style={{
                      fontStyle: "italic",
                      fontSize: "12px",
                      color: "#00668bc4",
                    }}
                  >
                    {shippingAddress.locationName &&
                    Object.keys(shippingAddress.locationName).length !== 0
                      ? shippingAddress.locationName.vicinity
                      : "Not available"}
                  </span>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body"
                  sx={{ fontSize: 13 }}
                  color="text.secondary"
                >
                  <b>Payment Method:</b>{" "}
                  <span
                    style={{
                      fontStyle: "italic",
                      fontSize: "12px",
                      color: "#b63700",
                    }}
                  >
                    {paymentMethod && Object.keys(paymentMethod).length !== 0
                      ? paymentMethod
                      : "Not available"}
                  </span>
                </Typography>
              </Grid>
            </Grid>

            <Divider style={{ paddingTop: "20px", marginBottom: "15px" }} />

            {/* Order Items Summary */}
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              ORDER ITEMS SUMMARY
            </Typography>
            <div className={classes.responsiveTable}>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader}>
                    PRODUCT IMAGE
                  </TableCell>
                  <TableCell className={classes.tableHeader}>NAME</TableCell>
                  <TableCell className={classes.tableHeaderCenter}>
                    PRICE
                  </TableCell>
                  <TableCell className={classes.tableHeaderCenter}>
                    QUANTITY
                  </TableCell>
                  <TableCell className={classes.tableHeaderCenter}>
                    SUM
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((item) => (
                  <TableRow key={item?._id} hover={true}>
                    <TableCell>
                      <Image
                      //  className="responsive-image"
                      className={classes.responsiveImage} 
                        style={{
                          width: "100px",
                          height: "90px",
                          borderRadius: "10%",
                        }}
                        src={item.imageUrl}
                        alt={item.imageUrl}
                        fluid
                        rounded
                      />
                    </TableCell>

                    <TableCell
                      variant="body"
                      sx={{ fontSize: 15 }}
                      color="text.secondary"
                    >
                      {item?.name}
                    </TableCell>
                    <TableCell
                      variant="body"
                      sx={{ fontSize: 15, textAlign: "center" }}
                      color="text.secondary"
                    >
                      {item?.price}
                    </TableCell>
                    <TableCell
                      variant="body"
                      sx={{ fontSize: 15, textAlign: "center" }}
                      color="text.secondary"
                    >
                      {item?.qty}
                    </TableCell>
                    <TableCell
                      variant="body"
                      sx={{ fontSize: 15, textAlign: "center" }}
                      color="text.secondary"
                    >
                      {(item?.qty * item?.price)
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* Place order */}
            <Card
              sx={{ minWidth: 275 }}
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              {paymentMethod === "COD" && (
                <CardContent>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{ fontSize: 24, fontStyle: "italic" }}
                          colSpan={2}
                        >
                          Order Cost Summary
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow>
                        <TableCell
                          variant="body"
                          sx={{ fontSize: 15 }}
                          color="text.secondary"
                        >
                          Shipping Cost:
                        </TableCell>

                        <TableCell
                          variant="body"
                          sx={{ fontSize: 15, color: "#004b67" }}
                        >
                          Shs {""}
                          {serverShippingFees
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          variant="body"
                          sx={{ fontSize: 15 }}
                          color="text.secondary"
                        >
                          <b>Total:</b>
                        </TableCell>

                        <TableCell
                          variant="body"
                          sx={{ fontSize: 15, color: "#004b67" }}
                        >
                          Shs {""}
                          {totalOrderCostPlusShipping
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                  <Divider
                    style={{ paddingTop: "10px", marginBottom: "10px" }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={placeOrderHandler}
                    // disabled={loading}
                    style={{
                      width: "100%",
                    }}
                  >
                    Place Order
                  </Button>
                </CardContent>
              )}

              {/* Visa */}
              {paymentMethod === "Visa" && (
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between", // Add space around items
                  }}
                >
                  {/* Part on the extreme left where the SVG of the selected card will be shown */}

                  <div
                    style={{
                      flexGrow: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "300px",
                      height: "300px",
                      // marginBottom: "40px",
                    }}
                  ></div>
                  {/* Middle part: Order Cost Summary Visa table */}
                  <div style={{ flexGrow: 1 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{ fontSize: 24, fontStyle: "italic" }}
                            colSpan={2}
                          >
                            Order Cost Summary Visa
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            variant="body"
                            sx={{
                              fontSize: 15,
                              width: "20%",
                              color: "text.secondary",
                            }}
                          >
                            <b>Shipping Cost:</b>
                          </TableCell>

                          <TableCell
                            variant="body"
                            sx={{ fontSize: 15, color: "#004b67" }}
                          >
                            Shs {""}
                            {serverShippingFees
                              ?.toString()
                              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            variant="body"
                            sx={{
                              fontSize: 15,
                              width: "20%",
                              color: "text.secondary",
                            }}
                          >
                            <b>Total:</b>
                          </TableCell>

                          <TableCell
                            variant="body"
                            sx={{ fontSize: 15, color: "#004b67" }}
                          >
                            Shs {""}
                            {totalOrderCostPlusShipping
                              ?.toString()
                              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>

                  {/* Part on the right: card input fields */}
                  <div 
                  className={classes.responsiveCard} 
                  // style={{ flexGrow: 1 }}
                  >
                    <CreditCardForm
                      // onFormChange={handleFormChange}
                      onFormChange={(data) => {
                        let expiry_month, expiry_year;
                        if (typeof data.expirationDate === "string") {
                          [expiry_month, expiry_year] =
                            data.expirationDate.split("/");
                        } else {
                          expiry_month = null;
                          expiry_year = null;
                        }

                        formik.setValues({
                          cardNumber: data.cardNumber,
                          expiry_month: expiry_month,
                          expiry_year: expiry_year,
                          cardCvv: data.cvv,
                          cardType: data.cardType,
                          fullName: data.fullName,
                        });
                        // setCardFormData(data);
                      }}
                      values={formik.values}
                      errors={formik.errors}
                      touched={formik.touched}
                      handleBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                    />
                    <Divider
                      style={{ paddingTop: "10px", marginBottom: "10px" }}
                    />
                    <PayCardButton
                      variant="contained"
                      color="primary"
                      // onClick={cardPlaceOrderHandler}
                      // onClick={handleSubmit}
                      onClick={formik.submitForm}
                      disabled={
                        paymentCreationStatus === "pending" ||
                        orderCreationStatus === "pending" ||
                        !formik.isValid
                        // !formik.dirty
                      }
                      style={{
                        width: "100%",
                      }}
                    >
                      {paymentCreationStatus === "pending" ||
                      orderCreationStatus === "pending"
                        ? "Processing..."
                        : "Pay"}
                    </PayCardButton>
                  </div>

                  <Divider
                    style={{ paddingTop: "10px", marginBottom: "10px" }}
                  />
                </CardContent>
              )}

              {/* MTN Mobile Money */}
              {paymentMethod === "MOMO" && (
                <CardContent
                  style={{
                    flex: "3",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{ fontSize: 24, fontStyle: "italic" }}
                          colSpan={2}
                        >
                          Order Cost Summary
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow>
                        <TableCell
                          variant="body"
                          sx={{
                            fontSize: 15,
                            width: "20%",
                            color: "text.secondary",
                          }} // adjust the width as per your requirement
                        >
                          <b>Shipping Cost:</b>
                        </TableCell>

                        <TableCell
                          variant="body"
                          sx={{ fontSize: 15, color: "#004b67" }}
                        >
                          Shs {""}
                          {serverShippingFees
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          variant="body"
                          sx={{
                            fontSize: 15,
                            width: "20%",
                            color: "text.secondary",
                          }} // adjust the width as per your requirement
                        >
                          <b>Total:</b>
                        </TableCell>

                        <TableCell
                          variant="body"
                          sx={{ fontSize: 15, color: "#004b67" }}
                        >
                          Shs {""}
                          {totalOrderCostPlusShipping
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                  <div style={{ paddingBottom: "20px", paddingTop: "10px" }}>
                    <Box
                      sx={{
                        // bgcolor: "background.paper",
                        bgcolor: theme.palette.background.paper,
                        boxShadow: "0px 3px 5px 2px rgba(0, 0, 0, .3)",
                        p: 1, // padding
                        borderRadius: 2,
                        width: { xs: "100%", sm: "100%", md: "28%" },
                        margin: "0px 0px 4px 0px",
                      }}
                    >
                      <TextField
                        className={`${classes.input} ${
                          postData?.mobile?.length === 10
                            ? classes.inputGreen
                            : ""
                        }`}
                        id="outlined-basic"
                        label="Enter MTN Number"
                        variant="outlined"
                        type="number"
                        color="secondary"
                        value={postData?.mobile}
                        //No character entry allowed after 10 digits
                        onChange={(e) => {
                          if (e.target.value.length <= 10) {
                            setPostData({
                              ...postData,
                              mobile: e.target.value,
                            });
                          }
                        }}
                        //Maximum allowed digits = 10
                        inputProps={{
                          maxLength: 10,
                        }}
                        // Prevent Keyboard arrow keys from de/incrementing
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                        fullWidth
                        name="mobile"
                        placeholder="Enter your MTN Number..."
                        required
                      />
                    </Box>
                  </div>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={payMoMoCheckoutHandler}
                    disabled={postData.mobile.length < 10}
                    // Cursor is disabled if the length of the input is less than 10
                    className="Button"
                  >
                    <img
                      style={{
                        width: 65,
                        height: 50,
                        borderRadius: 5,
                        alignSelf: "left",
                        paddingRight: "10px",
                      }}
                      src={momo}
                      // src={require("assets/img/momo.jpg").default}
                      alt="mtn-logo"
                    />
                    Proceed to Pay
                  </Button>
                </CardContent>
              )}
              {/* Airtel Money */}
              {paymentMethod === "Airtel" && (
                <CardContent
                  style={{
                    flex: "3",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{ fontSize: 24, fontStyle: "italic" }}
                          colSpan={2}
                        >
                          Order Cost Summary
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow>
                        <TableCell
                          variant="body"
                          sx={{
                            fontSize: 15,
                            width: "20%",
                            color: "text.secondary",
                          }} // adjust the width as per your requirement
                        >
                          <b>Shipping Cost:</b>
                        </TableCell>

                        <TableCell
                          variant="body"
                          sx={{ fontSize: 15, color: "#004b67" }}
                        >
                          Shs {""}
                          {serverShippingFees
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          variant="body"
                          sx={{
                            fontSize: 15,
                            width: "20%",
                            color: "text.secondary",
                          }} // adjust the width as per your requirement
                        >
                          <b>Total:</b>
                        </TableCell>

                        <TableCell
                          variant="body"
                          sx={{ fontSize: 15, color: "#004b67" }}
                        >
                          Shs {""}
                          {totalOrderCostPlusShipping
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                  <div style={{ paddingBottom: "20px", paddingTop: "10px" }}>
                    <Box
                      sx={{
                        // bgcolor: "background.paper",
                        bgcolor: theme.palette.background.paper,
                        boxShadow: "0px 3px 5px 2px rgba(0, 0, 0, .3)",
                        p: 1, // padding
                        borderRadius: 2,
                        width: { xs: "100%", sm: "100%", md: "28%" },
                        margin: "0px 0px 4px 0px",
                      }}
                    >
                      <TextField
                        className={`${classes.input} ${
                          postData?.mobile?.length === 10
                            ? classes.inputGreen
                            : ""
                        }`}
                        id="outlined-basic"
                        label="Enter Airtel Number"
                        variant="outlined"
                        type="number"
                        color="secondary"
                        value={postData?.mobile}
                        //No character entry allowed after 10 digits
                        onChange={(e) => {
                          if (e.target.value.length <= 10) {
                            setPostData({
                              ...postData,
                              mobile: e.target.value,
                            });
                          }
                        }}
                        // Maximum allowed digits = 10
                        inputProps={{
                          maxLength: 10,
                        }}
                        // Prevent Keyboard arrow keys from de/incrementing
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                        fullWidth
                        name="mobile"
                        placeholder="Enter your Airtel Number..."
                        required
                      />
                    </Box>
                  </div>

                  <AirtelPayButton
                    variant="contained"
                    color="primary"
                    onClick={payAirtelMoneyCheckoutHandler}
                    disabled={
                      postData.mobile.length < 10 ||
                      orderCreationStatus === "pending" ||
                      paymentCreationStatus === "pending" ||
                      paymentCreationStatus === "processing payment" ||
                      paymentCreationStatus === "fulfilled" ||
                      orderCreationStatus === "fulfilled"
                    }
                    // Cursor is disabled if the length of the input is less than 10
                    // className="Button"
                  >
                    <img
                      style={{
                        width: 65,
                        height: 55,
                        borderRadius: 5,
                        alignSelf: "left",
                        boxShadow: "0px 0px 10px rgba(255, 255, 255, 0.9)",
                      }}
                      src={airtel}
                      alt="airtel-logo"
                    />
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "white",
                        paddingLeft: "11px",
                      }}
                    >
                      {airtelButtonText}
                    </span>
                  </AirtelPayButton>
                </CardContent>
              )}
            </Card>
            </div>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default PlaceOrderScreen;
