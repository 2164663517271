import { useState, useEffect, useRef } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import logo from "../../assets/img/logo.png";
// import Navbar from "../../components/Navbar";
import useStyles from "./loginStyle.js";
import { Typography } from "@material-ui/core";
import { BounceLoader, MoonLoader } from "react-spinners";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { loginFailure } from "../../redux/userRedux";
import { login, loginWithGoogle } from "../../redux/apiCalls";
import Layout from "../../components/Global/Layout.js";

const Login = () => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID_AUTH_WEBAPP;
  const isMounted = useRef(false);
  const [loading, setLoading] = useState(false);
  const [isGoogleApiLoaded, setGoogleApiLoaded] = useState(false);

  const classes = useStyles();

  // Handles sideEffects
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false; // Set the flag to false when unmounted
    };
  }, []);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "profile email",
      });
    }
    gapi.load("client: auth2", () => {
      start();
      setGoogleApiLoaded(true);
    });
    // eslint-disable-next-line
  }, []);

  // const user = useSelector((state) => state.user.currentUser);
  const { currentUser, isFetching, error } = useSelector((state) => state.user); // get the currentUser from the store

  const [userData, setUserData] = useState({ email: "", password: "" });
  const dispatch = useDispatch();

  // Redirect back to previous page where the user was before getting prompted to login
  const { state } = useLocation();
  const { from } = state || { from: { pathname: "/" } };

  // Check if user is already logged in to prevent them from logging in again
  if (currentUser) {
    return <Redirect to={from} />; // render the app if currentUser is present
  }

  const handleGoogleLoginSuccess = async (response) => {
    setLoading(true);
    const { profileObj } = response;
    const { email, name, googleId } = profileObj;
    try {
      await loginWithGoogle(dispatch, {
        email,
        name,
        googleId
      });
    } catch (error) {
      console.log(error, "Error from Google Login...");
      toast.error("Failed to login with Google. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const onFailure = (res) => {
    toast.error(res);
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // dispatch(loginStart());
    try {
      await login(dispatch, userData);
    } catch (error) {
      // This will handle any errors that occur during the login process
      console.error(error);
      if (isMounted.current) {
        dispatch(
          loginFailure(error.message || "An error occurred during login.")
        );
      }
    }
  };

  const handleChange = ({ currentTarget: input }) => {
    setUserData({ ...userData, [input.name]: input.value });
  };

  return (
    <Layout>
      <div className={classes.login_nav_container}>
        {/* <Navbar /> */}
        <div className={classes.login_container}>
          <div className={classes.login_form_container}>
            <img src={logo} alt="logo" className={classes.logo_signin} />
            <div>
              <h1
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "600",
                  margin: "0 0 10px 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                Login
              </h1>
              {isFetching && (
                <div className={classes.login_loader}>
                  <BounceLoader size={35} color={"#078C03"} />
                </div>
              )}
              {!loading && (
                <form onSubmit={handleSubmit}>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter email address..."
                    onChange={handleChange}
                    className={classes.input}
                    value={userData.email}
                    required
                  />
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    onChange={handleChange}
                    className={classes.input}
                    value={userData.password}
                    required
                  />
                  {error && (
                    <Typography
                      variant="body2"
                      style={{
                        color: "red",
                        fontSize: "10px",
                        // fontWeight: "bold",
                        fontStyle: "italic",
                      }}
                    >
                      *{error.message || error}
                    </Typography>
                  )}
                  {/* Reset Password! */}
                  <Typography
                    style={{
                      alignSelf: "flex-start",
                      paddingLeft: "10px",
                    }}
                  >
                    <Link
                      href="#"
                      to="/passwordResetReq"
                      style={{
                        textDecoration: "none",
                        color: "#303030dd",
                        fontSize: "11px",
                        fontWeight: "500",
                        fontStyle: "italic",
                      }}
                    >
                      &#8226; Forgot Password?
                    </Link>
                  </Typography>
                  <button type="submit" className={classes.login_button}>
                    Login
                  </button>
                  {/* )} */}
                  <Typography
                    // className={classes.new_user}
                    style={{
                      alignSelf: "flex-start",
                      paddingTop: "10px",
                      fontSize: "11px",
                      fontWeight: "500",
                      fontStyle: "italic",
                    }}
                  >
                    New User?{" "}
                    <Link to="/register" style={{ textDecoration: "none" }}>
                      Register
                    </Link>
                  </Typography>
                  {/* Google login Button */}
                  <div id="googleSignin">
                    {isGoogleApiLoaded ? (
                      <GoogleLogin
                        clientId={clientId}
                        onSuccess={handleGoogleLoginSuccess}
                        onFailure={onFailure}
                        buttonText="Login with Google"
                        cookiePolicy="single_host_origin"
                        // isSignedIn={true}
                        className={classes.google_sign_in_button}
                      />
                    ) : (
                      <div className={classes.google_sign_in_button}>
                        <div className={classes.googleSignInLoadingContainer}>
                          <MoonLoader color={"#FF5C16"} size={17} />
                        </div>
                        <span className={classes.loadingText}>
                          Loading google auth...
                        </span>
                      </div>
                    )}
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
