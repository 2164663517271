import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { userRequest } from "../api/requestMethods";

const initialState = {
  order: "",
  orderCreationStatus: "",
  paymentCreationStatus: "idle",
  error: null,
  // paymentStatus: "",
};

// MTN Mobile Money
export const payMoMoCheckout = createAsyncThunk(
  "cart/payMoMoCheckout",
  async (data, { rejectWithValue }) => {
    // console.log(data, "MOMO Payload");
    const { client_id } = data;
    try {
      const response = await userRequest.post(`/momo/${client_id}`, data);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Airtel Money
export const payAirtelMoneyCheckout = createAsyncThunk(
  "cart/payAitelCheckout",
  async (data, { rejectWithValue }) => {
    // console.log(data, "Airtel  Payload");

    const { client_id } = data;
    try {
      const response = await userRequest.post(
        `/fw/flutterAirtelPayment/${client_id}`,
        data
      );
      return response.data;
    } catch (error) {
      // console.log(error.response.data.message, "Airtel Error>>>>>>");
      toast.error(error.response.data.message, {
        autoClose: false,
      });
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Payment with Credit Card
export const payCardCheckout = createAsyncThunk(
  "cart/payCardCheckout",
  async (data, { rejectWithValue }) => {
    const { client_id } = data;
    try {
      const response = await userRequest.post(
        `/fw/flutterCardPayment/${client_id}`,
        data,
        { withCredentials: true }
      );
      // // Check for error status from server response
      // if (response.data.status === "error") {
      //   toast.error(response.data.message);
      // }
      return response.data;
    } catch (error) {
      // console.log(error.response.data.message, "Card Error>>>>>>");
      //The toast error shouldn't close automatically
      toast.error(error.response.data.message, {
        autoClose: false,
      });
      return rejectWithValue(error.message || "Unknown Error Occured!");
    }
  }
);

// Card payment PIN verification
export const submitCardPin_Otp = createAsyncThunk(
  "cart/submitCardPin_Otp",
  async (data, { rejectWithValue }) => {
    // console.log(data, "PIN DATA>>>>>S");
    const { client_id } = data;
    try {
      const response = await userRequest.post(
        `/fw/flutterSubmitCardPin_Otp/${client_id}`, // Update with the actual endpoint for PIN submission
        data,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      console.log(error.response.data.message, "PIN Submission Error>>>>>>");
      toast.error(error.response.data.message, {
        autoClose: false,
      });
      return rejectWithValue(error.message || "Unknown Error Occured!");
    }
  }
);

export const createOrder = createAsyncThunk(
  "order/createOrder",
  async (data, { rejectWithValue }) => {
    try {
      const res = await userRequest("/mobile", {
        method: "POST",
        data,
      });
      //   const res = await axios.post(`${API_URL}/weborders`, data);
      toast.success("Order created successfully!");
      return res.data;
    } catch (err) {
      toast.error("Failed to create order. Please try again.");
      return rejectWithValue(err);
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    resetOrderCreation: (state) => {
      state.order = "";
      state.orderCreationStatus = "";
      state.paymentCreationStatus = "";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state) => {
        state.orderCreationStatus = "pending";
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.orderCreationStatus = "fulfilled";
        state.order = action.payload;
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.orderCreationStatus = "rejected";
        state.error = action.payload;
      })
      .addCase(payMoMoCheckout.pending, (state) => {
        state.paymentCreationStatus = "pending";
      })
      .addCase(payMoMoCheckout.fulfilled, (state) => {
        state.paymentCreationStatus = "fulfilled";
        // state.order = action.payload;
      })
      .addCase(payMoMoCheckout.rejected, (state, action) => {
        state.paymentCreationStatus = "rejected";
        state.error = action.payload;
      })
      .addCase(payCardCheckout.pending, (state) => {
        state.paymentCreationStatus = "pending";
      })
      .addCase(payCardCheckout.fulfilled, (state, action) => {
        state.paymentCreationStatus = "fulfilled";
        state.order = action.payload;
      })
      .addCase(payCardCheckout.rejected, (state, action) => {
        state.paymentCreationStatus = "rejected";
        state.error = action.payload;
      })
      .addCase(payAirtelMoneyCheckout.pending, (state) => {
        state.paymentCreationStatus = "processing payment";
      })
      .addCase(payAirtelMoneyCheckout.fulfilled, (state, action) => {
        state.paymentCreationStatus = "fulfilled";
        state.order = action.payload;
      })
      .addCase(payAirtelMoneyCheckout.rejected, (state, action) => {
        state.paymentCreationStatus = "rejected";
        state.error = action.payload;
      })
      // Case for PIN submission
      .addCase(submitCardPin_Otp.pending, (state) => {
        state.paymentCreationStatus = "pending";
      })
      .addCase(submitCardPin_Otp.fulfilled, (state, action) => {
        state.paymentCreationStatus = "fulfilled";
        state.order = action.payload;
      })
      .addCase(submitCardPin_Otp.rejected, (state, action) => {
        state.paymentCreationStatus = "rejected";
        state.error = action.payload;
      });
  },
});

export default orderSlice.reducer;
export const { resetOrderCreation } = orderSlice.actions;
