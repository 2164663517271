import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategories,
  // getSelectedCategory,
  selectActiveCategories,
} from "../redux/categoriesRedux";
import { motion } from "framer-motion";
import Filter from "./Filter";
import useStyles from "./styles/categoriesStyle.js";
const CategoriesSlider = () => {
  const classes = useStyles();
  const categories = useSelector(selectActiveCategories);
  const selectedCategory = useSelector((state) => state.categories.selectedCategory);

  

  const dispatch = useDispatch();

  const [width, setWidth] = useState(0);
  const carousel = useRef();
  // console.log(carousel.current, "scrollLeft");

  useEffect(() => {
    // setWidth(carousel.current.offsetWidth);
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
  }, [carousel]);

  useEffect(() => {
    // if (categoryStatus === "idle") {
    dispatch(fetchCategories());
    // }
  }, [dispatch]);


  // Effect to center the selected category
  useEffect(() => {
    if (selectedCategory && carousel.current) {
      const selectedElement = document.getElementById(`category-${selectedCategory}`);
      if (selectedElement) {
        const scrollPosition = selectedElement.offsetLeft 
                              + selectedElement.clientWidth / 2 
                              - carousel.current.clientWidth / 2;
        carousel.current.scrollLeft = scrollPosition;
      }
    }
  }, [selectedCategory]);

  const moveCarouselLeft = () => {
    carousel.current.scrollLeft -= 100;
  };

  const moveCarouselRight = () => {
    carousel.current.scrollLeft += 100;
  };

  const arrowStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: '#6B8E23',
    backgroundColor: '#2998',
    borderRadius: '30%',
    padding: "3px",
    height: '30px',
    width: '30px',
    cursor: 'pointer',
    color: 'white'
  };

  return (
    <div className={classes.outerSlider} style={{ position: 'relative' }}>
      <div className={classes.innerSlider}>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "row",
            backgroundColor: "#4D748C",
            borderRadius: "5px",
            padding: "0.5rem",
            height: "35px",
          }}
        >
          <div
            style={{
              fontSize: "20px",
              fontStyle: "italic",
              color: "white",
              marginRight: "13px"
            }}
          >
            Categories:
          </div>
          <div
          className={classes.arrowLeft}
          onClick={moveCarouselLeft}>
              <div style={arrowStyles}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-chevron-left"
                >
                  <polyline points="15 18 9 12 15 6"></polyline>
                </svg>
              </div>
            </div>
          <motion.div
            ref={carousel}
            style={{
              cursor: "grab",
              overflow: "hidden",
              backgroundColor: "#D9D9D9",
              borderRadius: "10px",
              position: 'relative',
              maxWidth: "90%",
              margin: "0px auto",
              padding: '5px 40px',
              alignSelf: "center",
            }}
          >

            <motion.div
              style={{
                display: "flex",
                backgroundColor: "#D9D9D9",
                // paddingLeft: "38px",
              }}
              drag="x"
              dragConstraints={{
                right: 0,
                left: -width,
              }}
            >
              {categories.map((category) => (
                <Filter category={category} key={category._id} />
              ))}
            </motion.div>
          </motion.div>
          {/* Right Arrow */}
           <div 
            // style={{...arrowContainerStyles, right: "5px" }} 
            className={classes.arrowRight}
            onClick={moveCarouselRight}
            >
            <div style={arrowStyles}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-right"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesSlider;
