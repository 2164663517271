import React, { useEffect } from "react";
import {  useDispatch, useSelector } from "react-redux";
// import { MdSearch } from "react-icons/md";
import Stack from "@mui/material/Stack";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Divider, Grid, Typography } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import { BounceLoader } from "react-spinners";

import Listing from "../components/Listing";
import Paginate from "./Pagination";
// import Navbar from "../components/Navbar";
import CategoriesSlider from "../components/CategoriesSlider";
import LusukuBinoculars from "../assets/img/lusuku_search binoculars.svg";
import { fetchAllListingsNoCategories, fetchListingsBySearch } from "../redux/listingRedux";
import Layout from "../components/Global/Layout";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const PrivacyPolicyLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: teal;
  color: white;
  font-size: 12px;
  font-style: italic;
  text-decoration: none;
  border-radius: 10;
  height: 25px;
  background-color: rgba(0, 128, 128, 0.8);
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  &:hover {
    text-decoration: underline;
  }
`;

const ShopCard = () => {
  const { listings, isFetching, fetched } = useSelector(
    (state) => state.products
  );

  const { selectedCategory } = useSelector((state) => state.categories);

  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();


  const page = query.get("page") || 1;
  const searchQuery = query.get("searchQuery");

  useEffect(() => {
    if (searchQuery) {
      // If there is a search query, fetch listings based on the search query
      dispatch(fetchListingsBySearch({ search: searchQuery }));
    } else {
      // If no search query, fetch all listings or handle accordingly
      dispatch(fetchAllListingsNoCategories({ page: 1 }));
    }
  }, 
  // eslint-disable-next-line
  [searchQuery]);

  const backToListings = () => {
    history.push("/shop");
  };

  return (
    <Layout>
      {isFetching ? (
        <Stack
          sx={{ color: "grey.500" }}
          spacing={2}
          direction="row"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: "10rem",
          }}
        >
          <BounceLoader size={50} color={"#FF5C16"} />
        </Stack>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          {/* <Navbar /> */}
          <div
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: '5px'
            }}
          >
            
            {!isFetching && !searchQuery && <CategoriesSlider />}
          </div>

          <div
            style={{
              margin: "0px 15px auto 15px",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {fetched && !isFetching && listings.length === 0 && searchQuery ? (
              <NoResultsMessage searchQuery={searchQuery} />
            ) : (
              <Grid container alignItems="stretch" spacing={3} pt={1} pb={2}>
                {listings?.map((listing) => (
                  <Grid key={listing?._id} item xs={6} sm={6} md={4} lg={3}>
                    <Listing listing={listing} />
                  </Grid>
                ))}
              </Grid>
            )}
          </div>
        </div>
      )}
      {!searchQuery && (
        <div
          style={{
            margin: "20px",
          }}
        >
          <Paginate
            page={page}
            isFetching={isFetching}
            selectedCategory={selectedCategory}
            listings={listings}
            searchQuery={searchQuery}
          />
        </div>
      )}
      <PrivacyPolicyLink to="/policy">Our Privacy Policy</PrivacyPolicyLink>
    </Layout>
  );

  
  function NoResultsMessage({ searchQuery }) {
    return (
      <Container fluid>
      <Row className="justify-content-center ">
        <Col md={6} className="text-center">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={LusukuBinoculars}
              alt="No results found"
              style={{
                // fontSize: 60,
                width: "120px",
                height: "120px",
                backgroundColor: "#E2E2E2",
                borderRadius: "60px",
                margin: "auto",
              }}
            />
          </div>
          <Typography variant="h6" color="inherit">
            <i className="fas fa-users"></i>&nbsp;&nbsp; No Such
            Product at the moment ~ Please check back later!
          </Typography>
          <Divider style={{ margin: "15px 0" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <p
              style={{
                color: "#00bcd4",
                fontSize: "16px",
                fontStyle: "italic",
              }}
            >
              "{searchQuery}"{" "}
            </p>
          </div>

          {/* What is being searched */}
          <Typography variant="subtitle1" color="inherit">
            - Check your spelling for typing errors
          </Typography>
          <Typography variant="subtitle1" color="inherit">
            - Try searching with short and simple keywords
          </Typography>
          <Typography variant="subtitle1" color="inherit">
            - Try searching more general terms - you can then filter
            the search results
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              color="inherit"
              onClick={backToListings}
              className="mt-2"
              style={{
                size: "small",
                backgroundColor: "#03660C",
                color: "white",
                borderRadius: "10px",
                border: "none",
                textTransform: "none",
              }}
            >
              Back to Shopping
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
    )
  }
};

export default ShopCard;
