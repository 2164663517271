import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentRestaurant: null,
    carts: {},
    restaurant: null,
    shippingAddress: {},
    paymentMethod: null,
    total: 0,
};

const calculateTotal = (carts, restaurantId) => {
    const cart = carts[restaurantId] || {};
    return Object.values(cart).reduce((total, item) => {
        const optionsTotal = item.options.reduce((sum, option) => sum + option.price, 0);
        return total + (item.price + optionsTotal) * item.quantity;
    }, 0);
};

const loadCartFromLocalStorage = () => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : {};
};

const loadTotalFromLocalStorage = () => {
    const savedTotal = localStorage.getItem('total');
    return savedTotal ? JSON.parse(savedTotal) : 0;
};

const saveCartToLocalStorage = (carts) => {
    localStorage.setItem('cart', JSON.stringify(carts));
};

const saveTotalToLocalStorage = (total) => {
    localStorage.setItem('total', JSON.stringify(total));
};

const restaurantCartSlice = createSlice({
    name: 'restaurantCart',
    initialState: {
        ...initialState,
        carts: loadCartFromLocalStorage(),
        total: loadTotalFromLocalStorage(),
    },
    reducers: {
        addToCart: (state, action) => {
            const { restaurantId, product, quantity, options = [] } = action.payload;
            if (!state.carts[restaurantId]) {
                state.carts[restaurantId] = {};
            }

            if (!state.carts[restaurantId][product._id]) {
                state.carts[restaurantId][product._id] = { ...product, quantity: 0, options: [] };
            }

            const productEntry = state.carts[restaurantId][product._id];
            productEntry.quantity += quantity;

            if (quantity > 0) {
                productEntry.options = options;
            }

            state.total = calculateTotal(state.carts, restaurantId);
            saveCartToLocalStorage(state.carts);
            saveTotalToLocalStorage(state.total);
        },
        removeFromCart: (state, action) => {
            const { restaurantId, productId } = action.payload;
            if (state.carts[restaurantId] && state.carts[restaurantId][productId]) {
                delete state.carts[restaurantId][productId];
            }
            state.total = calculateTotal(state.carts, restaurantId);
            saveCartToLocalStorage(state.carts);
            saveTotalToLocalStorage(state.total);
        },
        setCurrentRestaurant: (state, action) => {
            state.currentRestaurant = action.payload;
        },
        switchRestaurant: (state, action) => {
            state.currentRestaurant = action.payload;
        },
        setRestaurantData: (state, action) => {
            const restaurant = action.payload;
            state.restaurant = restaurant;
            state.currentRestaurant = restaurant.owner; // Set currentRestaurant to owner value
        },
        saveShippingAddress: (state, action) => {
            state.shippingAddress = action.payload;
        },
        savePaymentMethod: (state, action) => {
            state.paymentMethod = action.payload;
        },
        clearCart: (state, action) => {
            const restaurantId = action.payload;
            if (state.carts[restaurantId]) {
                delete state.carts[restaurantId];
                state.total = calculateTotal(state.carts, restaurantId);
                saveCartToLocalStorage(state.carts);
                saveTotalToLocalStorage(state.total);
            }
        },
        resetRestaurantCart: (state) => {
            state.currentRestaurant = null;
            state.carts = {};
            state.restaurant = null;
            state.shippingAddress = {};
            state.paymentMethod = null;
            state.total = 0;
            saveCartToLocalStorage({});
            saveTotalToLocalStorage(0);
        },
    },
});

export const {
    addToCart,
    removeFromCart,
    setCurrentRestaurant,
    switchRestaurant,
    setRestaurantData,
    saveShippingAddress,
    savePaymentMethod,
    clearCart,
    resetRestaurantCart,
} = restaurantCartSlice.actions;

export default restaurantCartSlice.reducer;
