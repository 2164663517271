import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage"; // default: localStorage if web, AsyncStorage if react-native
import { persistReducer } from "redux-persist";

const initialState = {
  sseData: null,
  status: "idle",
  error: null,
  lastUpdated: null,
};

const sseSlice = createSlice({
  name: "cardSse",
  initialState,
  reducers: {
    setSSEData: (state, action) => {
      state.sseData = action.payload;
      state.status = "success";
      state.lastUpdated = action.payload.orderDetails?.updatedAt;
    },
    clearSSEData: (state) => {
      Object.assign(state, initialState);
    },
  },
});

const persistConfig = {
  key: "cardSse",
  storage,
};

const persistedReducer = persistReducer(persistConfig, sseSlice.reducer);

export default persistedReducer;

export const { setSSEData, clearSSEData } = sseSlice.actions;
