import React from "react";
import { Badge } from "@material-ui/core";
import { Menu, ShoppingCartOutlined } from "@material-ui/icons";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import SearchIcon from "../assets/img/search_icon.png";
import { useHistory } from "react-router-dom";
import { mobile } from "../responsive";

const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
  background-color: #2d3e50;
  left: 0;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2d3e50;
  position: relative;

  ${mobile({ flexDirection: "column" })}
`;

const Logo = styled.div`
  font-weight: bold;
  ${mobile({ fontSize: "24px", paddingRight: "75px" })}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  ${mobile({ order: 1 })}
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px;
  ${mobile({
  order: 3,
  width: "100%",
  justifyContent: "flex-end",
  alignItems: "center",
  paddingRight: "57px", // Adjusted padding for mobile
})}
`;

const MenuItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  margin-left: 25px;
  ${mobile({ fontSize: "12px", marginLeft: "10px" })}
`;

const Center = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mobile({
  order: 2,
  width: "100%",
  marginBottom: "10px",
})}
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: stretch;
  border: 1px solid lightgray;
  border-radius: 10px;
  flex: 2;
  padding: 0;
  margin-right: 25px;
  width: 100%;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 10px;
  padding-left: 32px;
  font-size: 14px;
  border: none;
  border-radius: 10px 0 0 10px;
  box-sizing: border-box;
  background: white url(${SearchIcon}) no-repeat 10px center;
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 17px;
  margin: 0;
  &:focus {
    outline: none;
  }
  ${mobile({ flexGrow: 1 })}
`;

const SearchButton = styled.button`
  padding: 10px 15px;
  font-size: 12px;
  border: none;
  background-color: #e5933b;
  color: white;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: -6px 2px 10px rgba(0, 0, 0, 0.55);
  &:focus {
    outline: none;
    background-color: #c37d2f;
  }
  &:hover {
    background-color: #c37d2f;
  }
`;

const ClickableMenu = styled(Menu)`
  font-size: 34px;
  color: white;
  margin-left: 20px;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    color: #ff6347; // Tomato color when mouse hovers over it
  }

  &:active {
    transform: scale(0.9);
  }

  &:focus {
    outline: 2px solid #ff6347; // Outline with tomato color when it gets focus
  }
`;

const RetailerWholesalerNavbar = ({ setIsCartOpen }) => {
  const currentVendor = useSelector((state) => state.retailerWholesalerCart.currentVendor);
  const cart = useSelector((state) => state.retailerWholesalerCart.carts[currentVendor] || {});
  const cartCount = Object.values(cart).reduce((total, item) => total + item.quantity, 0);
  const history = useHistory();

  const handleCartClick = () => {
    if (setIsCartOpen) {
      setIsCartOpen(true);
    } else {
      history.push(`/shop/${currentVendor}`);
    }
  };

  const goToProfile = () => {
    history.push("/my_profile"); // Change this to your desired route
  };

  return (
    <Container>
      <Wrapper>
        <Left>
          <Logo>
            <Link to="/">
              <Image
                src={require('../assets/img/signin-logo.png')}
                style={{ width: 120, height: 60, marginBottom: 10, marginRight: 8 }}
              />
            </Link>
          </Logo>
        </Left>
        <Center>
          <SearchContainer>
            <Input
              type="search"
              id="search"
              placeholder="Search products, brands and categories..."
            />
            <SearchButton>
              SEARCH
            </SearchButton>
          </SearchContainer>
        </Center>
        <Right>
          <MenuItem onClick={handleCartClick}>
            <Badge overlap="rectangular" badgeContent={cartCount} color="secondary">
              <ShoppingCartOutlined style={{ color: '#f8f4f4', fontSize: 34, borderRadius: '50%', borderColor: 'red' }} />
            </Badge>
          </MenuItem>
          <ClickableMenu
            style={{
              fontSize: 34,
              marginRight: 20,
            }}
            onClick={goToProfile} />
        </Right>
      </Wrapper>
    </Container>
  );
};

export default RetailerWholesalerNavbar;
