import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import Stack from "@mui/material/Stack";

import Cart from "./pages/Cart";
import Policy from "./pages/Policy";
import PaymentScreen from "./pages/PaymentScreen";
import PlaceOrderScreen from "./pages/PlaceOrderScreen";
import Product from "./pages/Product";
import PromoProductDetailsPage from "./pages/PromoProductDetailsPage";
import ProductList from "./pages/ProductList";
import ShopCard from "./pages/ShopCard";
import Success from "./pages/Success";
import CODOrderScreen from "./pages/CODOrderScreen";
import AirtelPayScreen from "./pages/AirtelPayScreen";
import MomoOrderScreen from "./pages/MomoOrderScreen";
import ProtectedRoute from "./components/ProtectedRoute";
import Register from "./pages/SignUp/Register";
import Login from "./pages/Login/Login";
import WebClientPasswordResetRequest from "./pages/Login/webClientPasswordResetRequest";
import WebClientPasswordResetForm from "./pages/Login/WebClientPasswordResetForm";
import ShippingScreen from "./pages/Shipping/ShippingScreen";
import RestaurantShippingScreen from "./pages/Shipping/RestaurantShippingScreen";
import RetailerWholesalerShippingScreen from "./pages/Shipping/RetailerWholesalerShippingScreen";
import { useLoadScript } from "@react-google-maps/api";
import MyProfile from "./pages/MyProfile";
import OrderDetail from "./components/Order/OrderDetail";
import Promotions from "./components/Promotions/Promotions";
import Receipts from "./components/Receipts/Receipts";
import { ToastContainer } from "react-toastify";
import UserProfile from "./components/UserProfile/UserProfile";
import UserOrders from "./components/UserOrders/UserOrders";
import CardPinForm from "./components/CreditCardForm/Pin/CardPinForm";
import CardPaymentDoneScreen from "./pages/CardPaymentDoneScreen";
import CardOtpForm from "./components/CreditCardForm/Pin/CardOtpForm";
import LandingPage from "./pages/LandingPage";
import PaymentOptions from "./pages/PaymentOptions";
import ShippingMatrix from "./pages/ShippingMatrix";
// import RestaurantMenu from "./components/RestaurantMenu";
import RestaurantPaymentScreen from "./pages/RestaurantPaymentScreen";
import WholesalerRetailerPaymentScreen from "./pages/WholesalerRetailerPaymentScreen";
import RestaurantPlaceOrderScreen from "./pages/RestaurantPlaceOrderScreen";
import RetailerWholesalerPlaceOrderScreen from "./pages/RetailerWholesalerPlaceOrderScreen";
import VendorShopPage from "./pages/VendorShopPage";
import RestaurantShopPage from "./pages/RestaurantShopPage";
import CODRetailerWholesalerOrderScreen from "./pages/CODRetailerWholesalerOrderScreen";

const libraries = ["places"];

const App = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY_WEBAPP,
    libraries,
  });

  if (!isLoaded)
    return (
      <Stack
        sx={{ color: "grey.500" }}
        spacing={2}
        direction="row"
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop: "10rem",
        }}
      >
        <BounceLoader size={50} color={"#00d50ec3"} loading={!isLoaded} />
      </Stack>
    );

  if (loadError) return "Error loading maps";

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        {/* <Route path="/restaurant/menu/:restaurantId" component={RestaurantMenu} /> */}
        <Route path="/shop/:vendorId" component={VendorShopPage} />
        <Route path="/restaurant/shop/:id" component={RestaurantShopPage} />

        <Route exact path="/shop" component={ShopCard} />
        <Route path="/products/:category" component={ProductList} />
        <Route path="/product/:id" component={Product} />
        <Route path="/promoProduct/:id" component={PromoProductDetailsPage} />
        <Route path="/cart" component={Cart} />
        <Route path="/policy" component={Policy} />
        <Route path="/payment_options" component={PaymentOptions} />
        <Route path="/shipping_matrix" component={ShippingMatrix} />
        <Route path="/orderPage" component={CODOrderScreen} />
        <Route path="/rworderPage" component={CODRetailerWholesalerOrderScreen} />
        <Route path="/airtelPay" component={AirtelPayScreen} />
        <Route path="/momoOrderPage" component={MomoOrderScreen} />
        <Route path="/success" component={Success} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/passwordResetReq" component={WebClientPasswordResetRequest} />
        <Route path="/pwdResetForm/:token/:userId" component={WebClientPasswordResetForm} />
        <ProtectedRoute exact path="/shipping" component={ShippingScreen} />
        <ProtectedRoute exact path="/restaurant-shipping" component={RestaurantShippingScreen} />
        <ProtectedRoute exact path="/retailer-wholesaler-shipping" component={RetailerWholesalerShippingScreen} />
        <ProtectedRoute exact path="/retailer-wholesaler-payment" component={WholesalerRetailerPaymentScreen} />

        <ProtectedRoute exact path="/payment" component={PaymentScreen} />
        <ProtectedRoute exact path="/restaurant-payment" component={RestaurantPaymentScreen} />
        <ProtectedRoute exact path="/placeOrder" component={PlaceOrderScreen} />
        <ProtectedRoute exact path="/restaurant-placeorder/:id" component={RestaurantPlaceOrderScreen} />
        <ProtectedRoute exact path="/retailer-wholesaler-placeorder/:id" component={RetailerWholesalerPlaceOrderScreen} />
        <ProtectedRoute path="/my_profile" component={MyProfile} />
        <ProtectedRoute exact path="/profile" component={UserProfile} />
        <ProtectedRoute exact path="/my_orders" component={UserOrders} />
        <ProtectedRoute exact path="/receipts" component={Receipts} />
        <ProtectedRoute exact path="/pay/authorize" component={CardPinForm} />
        <ProtectedRoute exact path="/v3/validate-charge" component={CardOtpForm} />
        <ProtectedRoute exact path="/cardOrder" component={CardPaymentDoneScreen} />
        <Route exact path="/orderDetail/:id" component={OrderDetail} />
        <Route exact path="/promotions" component={Promotions} />
      </Switch>
      <ToastContainer />
    </Router>
  );
};

export default App;
