import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const ImageContainer = styled.div`
  width: 100%;
  max-width: 450px;
  margin: 0 auto;

  .magnify-glass {
    width: 200px !important;
    height: 200px !important;
  }
`;

const MagnifiableImage = ({ src, alt, zoomLevel = 1.2 }) => {
    const imgRef = useRef(null);

    useEffect(() => {
        const loadScript = (src) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
            });
        };

        const loadStylesheet = (href) => {
            return new Promise((resolve, reject) => {
                const link = document.createElement('link');
                link.rel = 'stylesheet';
                link.href = href;
                link.onload = resolve;
                link.onerror = reject;
                document.head.appendChild(link);
            });
        };

        const initializeMagnify = async () => {
            try {
                await loadScript('https://code.jquery.com/jquery-3.6.0.min.js');
                await loadStylesheet('https://cdn.jsdelivr.net/npm/magnify@2.3.3/dist/css/magnify.css');
                await loadScript('https://cdn.jsdelivr.net/npm/magnify@2.3.3/dist/js/jquery.magnify.min.js');

                if (imgRef.current && window.jQuery && window.jQuery(imgRef.current).magnify) {
                    window.jQuery(imgRef.current).magnify({
                        speed: 100,
                        src: src,
                        zoom: zoomLevel,
                        onload: function () {
                            console.log('Magnify initialized with zoom level:', zoomLevel);
                        }
                    });
                } else {
                    console.error('Magnify plugin or jQuery not available');
                }
            } catch (error) {
                console.error('Failed to load magnify:', error);
            }
        };

        initializeMagnify();

        return () => {
            // Clean up the added elements when component unmounts
            const jqueryScript = document.querySelector('script[src$="jquery-3.6.0.min.js"]');
            const magnifyCSS = document.querySelector('link[href$="magnify.css"]');
            const magnifyJS = document.querySelector('script[src$="jquery.magnify.min.js"]');
            if (jqueryScript) jqueryScript.remove();
            if (magnifyCSS) magnifyCSS.remove();
            if (magnifyJS) magnifyJS.remove();
        };
    }, [src, zoomLevel]);

    return (
        <ImageContainer>
            <img ref={imgRef} src={src} alt={alt} data-magnify-src={src} />
        </ImageContainer>
    );
};

export default MagnifiableImage;