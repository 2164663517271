import React, { useState } from "react";
import "./Sidebar.css";
import Logo from "../../assets/img/logo.png";
import {
  UilSignOutAlt,
  UilBars,
  UilStore,
  UilShoppingBag,
} from "@iconscout/react-unicons";
import { motion } from "framer-motion";
import { logoutUser } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import { SidebarData } from "../../Sidebar/SidebarData";
import { toast } from "react-toastify";

const Sidebar = () => {
  let { url } = useRouteMatch();

  const [expanded, setExpanded] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const sidebarVariants = {
    true: {
      left: "0",
    },
    false: {
      left: "-60%",
    },
  };

  const handleLogout = (e) => {
    e.preventDefault();
    const auth2 = window?.gapi && window?.gapi?.auth2?.getAuthInstance();
    if (auth2 != null) {
      auth2.signOut().then(() => {
        toast.success("Signed out successfully!");
        // console.log("User signed out from Google.");
      });
    }
    // Sign out of your app
    dispatch(logoutUser);
    history.push("/");
  };

  const continueShopping = (e) => {
    e.preventDefault();
    history.push("/");
  };

  const goToCart = (e) => {
    e.preventDefault();
    history.push("/cart");
  };

  // Make My Orders the default route whenever i navigate back
  // Get the current path without the leading slash
  const currentPath = location.pathname.substring(1);

  // Find the index of the sidebar item that matches the current path\
  const defaultSelected = SidebarData.findIndex(
    (item) => item.path.substring(1) === currentPath
  );

  // Use the index as the default selected item
  const [selected, setSelected] = useState(defaultSelected);

  return (
    <>
      <div
        className="bars"
        style={
          expanded
            ? {
                left: "30%",
                marginTop: "1rem",
                marginLeft: "1rem",
              }
            : { left: "8%", marginTop: "1rem" }
        }
        onClick={() => setExpanded(!expanded)}
      >
        <UilBars color="#fff" size="30" />
      </div>
      <motion.div
        className="Sidebar"
        variants={sidebarVariants}
        animate={window.innerWidth <= 768 ? `${expanded}` : ""}
      >
        {/* Logo */}
        <div className="logo">
          <img src={Logo} alt="" />
        </div>
        {/* Menu */}
        <div className="menu">
          {SidebarData.map((item, index) => {
            return (
              <div
                key={index}
                className={selected === index ? "menuItem active" : "menuItem"}
                onClick={() => {
                  setSelected(index);
                  history.push(`${url}${item.path}`);
                }}
              >
                {item.icon}
                <span>{item.heading}</span>
              </div>
            );
          })}
          {/* Shop */}
          <div className="menuItem" onClick={continueShopping}>
            <UilStore />
            <span>Shop</span>
          </div>
          {/* Cart */}
          <div className="menuItem" onClick={goToCart}>
            <UilShoppingBag />
            <span>Cart</span>
          </div>
          {/* Logout */}
          <div className="menuItem" onClick={handleLogout}>
            <UilSignOutAlt />
            <span>Logout</span>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Sidebar;
