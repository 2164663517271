import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import "./UserOrders.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderDetails } from "../../redux/myOrdersRedux";
import { useHistory } from "react-router-dom";
import { LocalShippingOutlined } from "@material-ui/icons";

const makeStyle = (delivery_status) => {
  if (delivery_status === "DELIVERED") {
    return {
      background: "rgb(145 254 159 / 47%)",
      color: "green",
    };
  } else if (delivery_status === "REJECTED") {
    return {
      background: "#ffadad8f",
      color: "red",
    };
  } else if (delivery_status === "ASSIGNED") {
    return {
      background: "#036574df",
      color: "#fff",
    };
  } else {
    return {
      background: "#59bfff",
      color: "white",
    };
  }
};

const UserOrders = () => {
  const { myOrders, isFetching } = useSelector((state) => state.userOrders);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className="TableContainer">
      <h3
        style={{
          padding: "5px",
          background: "#0313045e",
          borderRadius: "5px",
          color: "white",
        }}
      >
        Order History
      </h3>
      {isFetching ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "47vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : myOrders.length > 0 ? (
        <div className="Table" style={{ maxHeight: "400px", overflow: "auto" }}>
          <TableContainer
            component={Paper}
            style={{ boxShadow: "0px 13px 20px 0px #80808029" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className="TableHead">
                <TableRow>
                  <TableCell align="left">ORDER ID</TableCell>
                  <TableCell>CART TOTAL</TableCell>
                  <TableCell align="left">ORDER DATE</TableCell>
                  <TableCell align="left">DETAILS</TableCell>
                  <TableCell align="left">STATUS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ color: "white" }} className="tableX">
                {myOrders.map((row) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{row._id}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.totalPrice}
                    </TableCell>
                    <TableCell align="left">
                      {row.createdAt.match(/(\d{4}-\d{2}-\d{2})/)[0]}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="Details"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(fetchOrderDetails(row._id));
                        history.push(`/orderDetail/${row._id}`);
                      }}
                    >
                      Details..
                    </TableCell>
                    <TableCell align="left">
                      <span
                        className="status"
                        style={makeStyle(row.delivery_status)}
                      >
                        {row.delivery_status
                          .charAt(0)
                          .toUpperCase() +
                          row.delivery_status
                            .slice(1)
                            .toLowerCase()
                            .replace("_", " ")}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        !isFetching && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "47vh",
              fontSize: "1.4em",
              fontStyle: "italic",
              color: "#888",
            }}
          >
            <LocalShippingOutlined style={{ fontSize: "5em", color: "#ccc" }} />
            <p>You have not made any orders yet!</p>
          </div>
        )
      )}
    </div>
  );
};

export default UserOrders;
