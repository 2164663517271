import { Badge } from "@material-ui/core";
import {
  // AccountCircleOutlined,
  // ExitToAppOutlined,
  Menu,
  ShoppingCartOutlined,
} from "@material-ui/icons";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
// import { logoutUser } from "../redux/apiCalls";
import { Image } from "react-bootstrap";

import { mobile } from "../responsive";
import SearchIcon from "../assets/img/search_icon.png"
import { fetchListingsBySearch } from "../redux/listingRedux";
import { useState } from "react";
const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #324d54;
`;

const Wrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #324d54;
  position: relative;

  ${mobile({ 
    flexDirection: "column",
  })}
`;

const Logo = styled.div`
  font-weight: bold;
  ${mobile({ fontSize: "24px", paddingRight: "75px" })}
`;



const Left = styled.div`
  flex: 1; // Takes up 1/3 of the space
  display: flex;
  align-items: center;
  ${mobile({ 
    order: 1,
  })}
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${mobile({ 
    position: "absolute",
    top: "10px", 
    right: "10px", 
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: "5px"
  })}
`;

const MenuItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  margin-left: 25px;
  ${mobile({ fontSize: "12px" })}
`;
const CartIconItem = styled.div`
  padding-right: 25px;
  ${mobile({ fontSize: "12px", marginBottom: "10px" })}
`;

const StyledButton = styled(Link)`
  padding: 5px;
  margin: 2px;
  text-decoration: none;
  text-transform: upperCase;
  text-align: center;
  background-color: transparent;
  border: 3px solid #dff4;
  border-radius: 22px;s
  transition: ease-in-out 0.3s;
  color: white;
  font-size: 12px;
  border: 1px solid white;
  width: 80px;
  &:hover {
    background-color: #dff4;
    color: white;
    cursor: pointer;
  }
  ${mobile({ fontSize: "12px", padding: "5px" })}
`;

const AuthButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  ${mobile({ flexDirection: "column", zIndex: "1", marginBottom: "10px" })}
`;

const ClickableMenu = styled(Menu)`
  font-size: 34px;
  color: white;
  margin-left: 20px;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    color: #ff6347; // Tomato color when mouse hovers over it
  }

  &:active {
    transform: scale(0.9);
  }

  &:focus {
    outline: 2px solid #ff6347; // Outline with tomato color when it gets focus
  }
`;


const Center = styled.div`
  flex: 1; // Takes up 2/3 of the space
  display: flex;
  align-items: center;
  justify-content: center;
  ${mobile({ 
    order: 3,
    width: "100%"
  })}
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: stretch;
  border: 1px solid lightgray;
  border-radius: 10px;
  flex: 2;
  padding: 0;
  margin-right: 25px;
  width: 100%; // Takes up all available space
`;

const Input = styled.input`
flex-grow: 1; // Allows the input to grow and fill the space
padding: 10px;
padding-left: 32px; // Increased padding to account for the search icon
font-size: 14px;
border: none;
border-radius: 10px 0 0 10px;
box-sizing: border-box;
background: white url(${SearchIcon}) no-repeat 10px center;
background-repeat: no-repeat;
background-position: 10px center; // Position it 10px from the left, centered vertically
background-size: 17px; // Size of the search icon
margin: 0;
  &:focus {
    outline: none;
  }
  ${mobile({ 
    // width: "auto", 
    flexGrow: 1 
  })}
`;

const SearchButton = styled.button`
  padding: 10px 15px;
  font-size: 12px;
  border: none;
  background-color: #83B524;
  color: white;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: -6px 2px 10px rgba(0, 0, 0, 0.55);
  &:focus {
    outline: none;
    background-color: #699B07;
  }
  &:hover {
    background-color: #699B07;
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Navbar = () => {
  // const quantity = useSelector((state) => state.cart.quantity);
  const user = useSelector((state) => state.user.currentUser);
  const query = useQuery();
  // Get Cart Count
  const products = useSelector((state) => state.cart.products);
  const cartCount = products.length

  const dispatch = useDispatch();
  // const page = query.get("page") || 1;
  // const searchQuery = query.get("searchQuery");
  const history = useHistory();
  const [search, setSearch] = useState(query.get("searchQuery") || "");


  const goToDashBoard = (e) => {
    e.preventDefault();
    history.push("/my_profile");
  };

  const searchListings = () => {
    if (search.trim()) {
      dispatch(fetchListingsBySearch({ search }));
      history.push(`/shop?searchQuery=${search}`);
    } else {
      dispatch(fetchListingsBySearch({ search: "" })); // Dispatch with empty string to fetch all listings
    history.push("/shop"); // Clear the search query in the URL
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchListings();
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearch(value); // This will ensure the input value is controlled by the React state
    if (value === "") {
      dispatch(fetchListingsBySearch({ search: "" }));
      history.push("/shop");
    }
  };


  return (
    <Container>
      <Wrapper>
        <Left>
        <Logo>
            <Link to="/">
              <Image
                src={require("../assets/img/signin-logo.png")}
                style={{
                  width: 120,
                  height: 60,
                  marginBottom: 10,
                  marginRight: 8,
                }}
              />
            </Link>
          </Logo>
          </Left>
        {/* Search Container */}
            <Center>
              <SearchContainer>
                <Input 
                  type="search"
                  id="search"
                  placeholder="Search products, brands and categories..." 
                  value={search}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyPress}
                  // onInput={handleInputClear}
                />
                <SearchButton
                  onClick={searchListings}
                >
                  SEARCH
                </SearchButton>
              </SearchContainer>
              </Center>
              {!user ? (
              <Right>
                <AuthButtonGroup>
                  <StyledButton to="/register">Register</StyledButton>
                  <StyledButton to="/login">Sign In</StyledButton>
                </AuthButtonGroup>

                  <Link to="/cart">
                    <CartIconItem>
                      <MenuItem>
                        <Badge                    
                          badgeContent={cartCount}
                          color="secondary"
                          overlap="rectangular"
                        >
                          <ShoppingCartOutlined
                            style={{
                              color: "#f8f4f4",
                              fontSize: 34,
                              borderRadius: "50%",
                              borderColor: "red",
                            }}
                          />
                        </Badge>
                      </MenuItem>
                    </CartIconItem>
                  </Link>
                </Right>
              ) : (
                <Right>
                  <Link to="/cart">
                    <MenuItem>
                      <Badge
                        overlap="rectangular"
                        badgeContent={cartCount}
                        color="secondary"
                      >
                        <ShoppingCartOutlined
                          style={{
                            color: "#f8f4f4",
                            fontSize: 34,
                            borderRadius: "50%",
                            borderColor: "red",
                          }}
                        />
                      </Badge>
                    </MenuItem>
                  </Link>
                  <ClickableMenu
                    style={{
                      fontSize: 34,
                      marginRight: 10,
                    }}
                    onClick={goToDashBoard}
                  />
                </Right>
              )}
            </Wrapper>        
          </Container>
   
     );
};

export default Navbar;
