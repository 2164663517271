import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  itemActive: {
    width: "70%",
    height: "100%",
    borderRadius: "10px",
    backgroundColor: "#BF1151",
    boxShadow: "0px 0px 3px rgba(0,0,0,0.9)",
    // opacity: "0.8",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#F2F2F2",
  },
  itemInactive: {
    width: "70%",
    height: "100%",
    borderRadius: "10px",
    backgroundColor: "#556273",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: "0.8",
    color: "#B0A8BF",
  },
  arrowLeft: {
    "@media (max-width: 850px)": {
      display: "none",
    },
  },
  arrowRight: {
    "@media (max-width: 850px)": {
      display: "none",
    },
  },
  outerSlider: {
    margin: "0",
    paddingTop: "15px",
    width: "100%",
    // "@media (max-width: 768px)": {
    //   paddingTop: "0",
    // },
    // "@media (max-width: 480px)": {
    //   paddingTop: "0",
    // },
  },
    innerSlider: {
      justifyContent: "start",
      alignItems: "center",
      backgroundColor: "red",
      borderRadius: "20px",
    },
 
}));
  
