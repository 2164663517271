import React from "react";
import styled from "styled-components";

import Navbar from "../components/Navbar";

const StyledParagraph = styled.div`
  margin-bottom: 15px;
`;

const PolicySection = ({ header, subHeader, title, children }) => (
  <div>
    <h1 style={{ marginBottom: "5px" }}>{header}</h1>
    <h3 style={{ marginBottom: "5px" }}>{subHeader}</h3>
    <h2 style={{ marginBottom: "5px" }}>{title}</h2>
    <p style={{ marginBottom: "25px" }}>{children}</p>
  </div>
);

const PolicyPage = () => {
  return (
    <>
      <div style={{ marginBottom: "15px" }}>
        <Navbar />
      </div>
      <div style={{ paddingTop: "20px", margin: "80px" }}>
        {/* <h1>Lusuku Privacy Policy</h1> */}
        <PolicySection title="Lusuku Privacy Policy">
          - Last updated: January 17, 2023"
        </PolicySection>
        <a
          //   href="https://drive.google.com/file/d/13VMoHW4v4SMHMIuyuUSHZ9Lhd3rdWGIc/export?format=pdf"
          href="https://drive.google.com/file/d/13VMoHW4v4SMHMIuyuUSHZ9Lhd3rdWGIc/view?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            style={{
              padding: "10px 20px",
              borderRadius: "5px",
              fontSize: "16px",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            Download Terms & Contract
          </button>
        </a>

        <PolicySection>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when you use the
          Service and tells You about our privacy rights and how the law
          protects You.
          <br />
          <br />
          We use Your Personal data to provide and improve the Service. By using
          the Service, you agree to the collection and use of information in
          accordance with this Privacy Policy.
        </PolicySection>

        <PolicySection title="Interpretation and Definitions"></PolicySection>
        <PolicySection subHeader="Interpretation">
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </PolicySection>
        <PolicySection title="Definitions">
          For the purposes of this Privacy Policy:
          <div style={{ margin: "30px" }}>
            <ul>
              <li>
                <StyledParagraph>
                  Account means a unique account created for You to access our
                  Service or parts of our Service.
                </StyledParagraph>
              </li>
              <li>
                <StyledParagraph>
                  Affiliate means an entity that controls, is controlled by or
                  is under common control with a party, where "control" means
                  ownership of 50% or more of the shares, equity interest or
                  other securities entitled to vote for election of directors or
                  other managing authority.
                </StyledParagraph>
              </li>
              <li>
                <StyledParagraph>
                  Application refers to Lusuku mobile and web application and
                  any other software program provided by the Company.
                </StyledParagraph>
              </li>
              <li>
                <StyledParagraph>
                  Company (referred to as either "the Company", "We", "Us" or
                  "Our" in this Agreement) refers to Lusuku Fresh Services, SN
                  Plaza, Ntinda Kigoowa. Tuund Road.
                </StyledParagraph>
              </li>
              <li>
                <StyledParagraph>Country refers to: Uganda</StyledParagraph>
              </li>
              <li>
                <StyledParagraph>
                  Device means any device that can access the Service such as a
                  computer, a cellphone or a digital tablet.
                </StyledParagraph>
              </li>
              <li>
                <StyledParagraph>
                  Personal Data is any information that relates to an identified
                  or identifiable individual.
                </StyledParagraph>
              </li>
              <li>
                <StyledParagraph>
                  Service refers to the Application.
                </StyledParagraph>
              </li>
              <li>
                <StyledParagraph>
                  Service Provider means any natural or legal person who
                  processes the data on behalf of the Company. It refers to
                  third-party companies or individuals employed by the Company
                  to facilitate the Service, to provide the Service on behalf of
                  the Company, to perform services related to the Service or to
                  assist the Company in analyzing how the Service is used.
                </StyledParagraph>
              </li>
              <li>
                <StyledParagraph>
                  Usage Data refers to data collected automatically, either
                  generated by the use of the Service or from the Service
                  infrastructure itself (for example, the duration of a page
                  visit).
                </StyledParagraph>
              </li>
              <li>
                <StyledParagraph>
                  You means the individual accessing or using the Service, or
                  the company, or other legal entity on behalf of which such
                  individual is accessing or using the Service, as applicable.
                </StyledParagraph>
              </li>
            </ul>
          </div>
        </PolicySection>

        <PolicySection header="Collecting and Using Your Personal Data"></PolicySection>
        <PolicySection title="Types of Data Collected">
          <PolicySection subHeader="Personal Data">
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </PolicySection>
          <div style={{ margin: "15px" }}>
            <ul>
              <li>
                <StyledParagraph>Phone number.</StyledParagraph>
              </li>
            </ul>
            <ul>
              <li>
                <StyledParagraph>Address, City, Delivery zone.</StyledParagraph>
              </li>
            </ul>
            <ul>
              <li>
                <StyledParagraph>Usage Data.</StyledParagraph>
              </li>
            </ul>
          </div>

          <PolicySection subHeader="Usage Data">
            Usage Data is collected automatically when using the Service.
            <br />
            <br />
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
            <br />
            <br />
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
            <br />
            <br />
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </PolicySection>
        </PolicySection>
        <PolicySection title="Information Collected while Using the Application">
          While using Our Application, in order to provide features of Our
          Application, We may collect, with Your prior permission: Information
          regarding your location We use this information to provide features of
          Our Service, to improve and customize Our Service.
          <br />
          <br />
          The information may be uploaded to the Company's servers and/or a
          Service Provider's server or it may be simply stored on Your device.
          You can enable or disable access to this information at any time,
          through Your Device settings.
        </PolicySection>
        <PolicySection title="Contact Us">
          <div style={{ margin: "20px" }}>
            <ul>
              <li>
                <StyledParagraph>By email: support@lusuku.ug</StyledParagraph>
              </li>
            </ul>
            <ul>
              <li>
                <StyledParagraph>By phone number: 0323300500</StyledParagraph>
              </li>
            </ul>
            <ul>
              <li>
                <StyledParagraph>WhatsApp ~ 0700771301.</StyledParagraph>
              </li>
            </ul>
          </div>
        </PolicySection>
      </div>
    </>
  );
};

export default PolicyPage;
