import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Paper, Typography, Divider, List, ListItem, ListItemText, IconButton, Button } from '@mui/material';
import { ReactComponent as EmptyCartSVG } from '../assets/img/Emptycart.svg';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';  // Import CloseIcon
import { addToCart, removeFromCart, switchRestaurant } from '../redux/restaurantCartSlice';

const CartContainer = styled(Paper)`
  padding: 20px;
  padding-right: 10px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: #fff;
  margin-top: 20px;
  height: 490px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative; /* Add relative positioning to contain the absolute positioned close button */
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const SavingsContainer = styled.div`
  font-size: 12px;
  color: #888;
  background: #f7f7f7;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
`;

const InfoIcon = styled.span`
  display: inline-block;
  margin-right: 5px;
  font-size: 14px;
  vertical-align: middle;
`;

const EmptyCartImage = styled.div`
  margin: 20px 0;
  svg {
    width: 80%;
    height: auto;
    opacity: 0.3;
  }
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-left: 20px; /* Add some margin to separate from ItemDetails */
`;

const CheckoutButton = styled(Button)`
  background-color: #042940;
  color: #fff;
  &:hover {
    background-color: #03506f;
  }
  width: 100%;
`;

const TotalAmount = styled(Typography)`
  margin-bottom: 10px;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
`;

const ItemImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 5px;
`;

const ItemDetails = styled.div`
  display: flex;
  align-items: center;
  flex: 1; /* Ensure it takes up available space */
`;

const ItemNameContainer = styled.div`
  flex: 1; /* Allow it to take up available space */
  min-width: 0; /* Prevent it from overflowing */
`;

const ItemPriceContainer = styled.div`
  width: 100px; /* Fixed width to prevent pushing */
`;

const RestaurantCart = ({ onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentRestaurant = useSelector((state) => state.restaurantCart.currentRestaurant);
  const cart = useSelector((state) => state.restaurantCart.carts[currentRestaurant] || {});
  const totalAmount = useSelector((state) => state.restaurantCart.total);

  const cartItems = Object.values(cart);

  const handleIncrease = (product) => {
    dispatch(addToCart({ restaurantId: currentRestaurant, product, quantity: 1, options: product.options }));
  };

  const handleDecrease = (product) => {
    dispatch(addToCart({ restaurantId: currentRestaurant, product, quantity: -1, options: product.options }));
    if (product.quantity === 1) {
      dispatch(removeFromCart({ restaurantId: currentRestaurant, productId: product._id }));
    }
  };

  const checkoutHandler = () => {
    dispatch(switchRestaurant(currentRestaurant));
    history.push("/restaurant-shipping");
  };

  return (
    <CartContainer elevation={6}>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <div>
        <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
          Your order
        </Typography>
        <Divider style={{ marginBottom: '20px' }} />
        {cartItems.length === 0 ? (
          <>
            <EmptyCartImage>
              <EmptyCartSVG />
            </EmptyCartImage>
            <Typography variant="body2" paragraph>
              You've not added any products yet. When you do, you'll see them here!
            </Typography>
          </>
        ) : (
          <>
            <List>
              {cartItems.map((item) => {
                const optionsTotal = item.options.reduce((sum, option) => sum + option.price, 0);
                return (
                  <ListItem key={item._id}>
                    <ItemDetails>
                      <ItemImage src={item.image} alt={item.name} />
                      <ItemNameContainer>
                        <ListItemText
                          primary={item.name}
                          secondary={`Total: UGX ${new Intl.NumberFormat('en-UG', {
                            style: 'currency',
                            currency: 'UGX',
                          }).format((item.price + optionsTotal) * item.quantity)}`}
                        />
                      </ItemNameContainer>
                    </ItemDetails>
                    <ItemPriceContainer>
                      <QuantityContainer>
                        <IconButton
                          onClick={() => handleDecrease(item)}
                          style={{
                            backgroundColor: '#F23030',
                            color: '#fff',
                          }}
                        >
                          <RemoveIcon fontSize="small" />
                        </IconButton>
                        <Typography>{item.quantity}</Typography>
                        <IconButton
                          onClick={() => handleIncrease(item)}
                          style={{ backgroundColor: '#267365', color: '#fff' }}
                        >
                          <AddIcon fontSize="small" />
                        </IconButton>
                      </QuantityContainer>
                    </ItemPriceContainer>
                  </ListItem>
                );
              })}
            </List>
            <Divider style={{ margin: '20px 0' }} />
            <TotalAmount variant="h6" gutterBottom>
              Total: {new Intl.NumberFormat('en-UG', {
                style: 'currency',
                currency: 'UGX',
              }).format(totalAmount)}
            </TotalAmount>
          </>
        )}
      </div>
      <BottomContainer>
        <CheckoutButton
          variant="contained"
          onClick={checkoutHandler}
          disabled={cartItems.length === 0}
        >
          Checkout
        </CheckoutButton>
        <SavingsContainer>
          <Typography variant="body2">
            <InfoIcon>ℹ️</InfoIcon> Reach USh.15,000 to save USh.2,000 in fees!
          </Typography>
        </SavingsContainer>
      </BottomContainer>
    </CartContainer>
  );
};

export default RestaurantCart;
