import Card from "@mui/material/Card";
import { ButtonBase } from "@material-ui/core";

import Typography from "@mui/material/Typography";
import { CardContent, CardActions } from "@mui/material";
import { useHistory } from "react-router-dom";
import ShoppingCart from "@material-ui/icons/ShoppingCart.js";
import { useDispatch, useSelector } from "react-redux";

import useStyles from "./styles/listingStyle.js";
import { addToCart } from "../redux/cartRedux.js";
import styled from "styled-components";
import Chip from "@mui/material/Chip";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import PropTypes from "prop-types";
// import { useMediaQuery } from "@mui/material";

const Button = styled.button`
  padding: 15px;
  border-color: #00bcd4;
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const Listing = ({ listing }) => {
  Listing.propTypes = {
    listing: PropTypes.object,
  };
  const cart = useSelector((state) => state.cart.products);

  // const [quantity, setQuantity] = useState(1);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  // const isMobile = useMediaQuery("(max-width:600px)");

  const addToCartHandler = (item) => {
    dispatch(addToCart({ item }));
  };

  const openPostDetail = () => {
    history.push("/product/" + listing._id);
  };

  // Check if Item is already added to Cart
  const isInCart = (prod) => {
    if (cart?.filter((item) => item?._id === prod?._id)?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Card
      // className={classes.root}
      style={{
        display: "block",
        transitionDuration: "0.3s",
        height: "95%",
        paddingBottom: "12px",
      }}
      elevation={6}
    >
      <CardActionArea>
        <ButtonBase
          component="span"
          name="test"
          className={classes.cardAction}
          onClick={openPostDetail}
          disabled={listing.countInStock <= 0}
        >
          {listing?.countInStock <= 0 ? (
            <CardMedia
              className={classes.media}
              image={listing?.product_image}
              title={listing?.title}
            >
              <Typography
                component="h2"
                variant="h5"
                color="textSecondary"
                className={classes.pOuttaStock}
              >
                Sold Out!
              </Typography>
            </CardMedia>
          ) : (
            <CardMedia
              className={classes.media}
              image={listing?.product_image}
              title={listing?.title}
            />
          )}
        </ButtonBase>

        <CardContent className={classes.cardContent}>
          <Typography
            gutterBottom
            variant="body2"
            component="h2"
            // className={classes.title}
            // color="#00bcd4"
            color="#732509"
          >
            {listing.title}
          </Typography>
          <Typography
            gutterBottom
            variant="body2"
            // component="h4"
            // component="p"
            color="textSecondary"
            // className={classes.unitCount}
          >
            {listing?.measurements}{" "}
            {listing?.unit_id?.unit ? listing?.unit_id?.unit : ""}
          </Typography>
          <Typography
            // gutterBottoms
            variant="body2"
            component="h2"
            color="#00bcd4"
            // color="#732509"
            // className={classes.price}
          >
            Shs{" "}
            {listing?.price?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
            {""}{" "}
          </Typography>
          {/* <Typography variant="body2" color="textSecondary" component="p">
            {listing.description}
          </Typography> */}
        </CardContent>
      </CardActionArea>
      <CardActions
        style={{
          justifyContent: "flex-end",
          marginRight: "10px",
        }}
      >
        {isInCart(listing) ? (
          <Chip
            label="In Cart"
            color="success"
            // className={classes.inCartChip}
            size="small"
            style={{ marginRight: "10px" }}
            // onClick={() => removeFromCartHandler(listing._id)}
          />
        ) : (
          <div>
            {!listing?.countInStock <= 0 && (
              <Button
                disabled={listing.countInStock <= 0}
                onClick={() => addToCartHandler(listing)}
              >
                <ShoppingCart fontSize="small" />
              </Button>
            )}
          </div>
        )}
      </CardActions>
    </Card>
  );
};

export default Listing;
