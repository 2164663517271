import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userRequest } from "../api/requestMethods";
// import client from "../../app/api/client";

const initialState = {
  categories: [],
  selectedCategory: "",
  status: "idle",
  error: null,
  isFetching: false,
};

export const fetchCategories = createAsyncThunk(
  "/categories/fetchCategories",
  async () => {
    // const response = await client.get("/category");
    const response = await userRequest("/category/categories");
    // console.log(response.data, "Fetched Categories response");
    return response.data;
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    filterCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = "loading";
        state.isFetching = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched categories to the array
        // state.categories = state.categories.concat(action.payload);
        state.categories = action.payload;
        state.isFetching = false;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.isFetching = false;
      });
  },
});

// export const getSelectedCategory = (state) => state.categories.selectedCategory; // Review

export const { filterCategory } = categoriesSlice.actions;

export const selectActiveCategories = (state) => state.categories.categories;

export default categoriesSlice.reducer;
