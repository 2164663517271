import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Avatar from "react-avatar";
import { BounceLoader } from "react-spinners";

import "./UserProfile.css";

const UserProfile = () => {
  const user = useSelector((state) => state.user.currentUser);
  const history = useHistory();
  const passwordHandler = () => {
    history.push("/passwordResetReq");
  };

  if (!user) {
    return <BounceLoader size={50} color={"#FF5C16"} />;
  }

  return (
    <div className="user-profile">
      <Avatar name={user?.result?.name} size="100" round={true} />
      <h2>{user?.result?.name}</h2>
      <p className="email">Email: {user?.result?.email}</p>
      <p className="createdAt">
        Created: {new Date(user?.result?.createdAt).toLocaleString()}
      </p>
      <p className="type">Of type: {user?.result?.user_role}</p>
      <button
        className="passwordChange"
        onClick={passwordHandler}
        disabled={!user?.result?.googleId}
        style={user?.result?.googleId ? { cursor: "not-allowed" } : {}} //For users logged in with google, they can't change their password
      >
        Change Password
      </button>

      {/* Display more user info here */}
    </div>
  );
};

export default UserProfile;
