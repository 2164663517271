import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage"; // default: localStorage if web, AsyncStorage if react-native
import { persistReducer } from "redux-persist";
import { userRequest } from "../api/requestMethods";

const initialState = {
  airtelSseData: null,
  status: "idle",
  error: null,
  trackOrderAirtelSse: null,
  verificationData: null,
  verificationStatus: "idle",
  verificationError: null,
};

const verifyAirtelPayment = createAsyncThunk(
  "airtelSse/verifyAirtelPayment",
  async (params, { rejectWithValue }) => {
    try {
      const resp = await userRequest.post(
        `/fw/verify_airtel_payment-status/${params.userId}`,
        { respObj: params.respObj }
      );

      return resp.data;
    } catch (error) {
      // toast.error(error.response.data.message);
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const airtelSseSlice = createSlice({
  name: "airtelSse",
  initialState,
  reducers: {
    setAirtelSSEData: (state, action) => {
      state.airtelSseData = action.payload;
    },
    setAirtelTrackOrderSSEData: (state, action) => {
      state.trackOrderAirtelSse = action.payload;
    },
    clearAirtelSSEData: (state) => {
      state.airtelSseData = null;
      state.trackOrderAirtelSse = null;
      state.verificationData = null;
      state.verificationError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyAirtelPayment.pending, (state, action) => {
        state.verificationStatus = "loading";
        state.verificationData = action.meta.arg.respObj;
      })
      .addCase(verifyAirtelPayment.fulfilled, (state, action) => {
        state.verificationStatus = "succeeded";
        state.verificationData = action.payload;
      })
      .addCase(verifyAirtelPayment.rejected, (state, action) => {
        state.verificationStatus = "failed";
        state.verificationError = action.payload;
      });
  },
});

const persistConfig = {
  key: "airtelSse",
  storage,
};
const persistedReducer = persistReducer(persistConfig, airtelSseSlice.reducer);

export default persistedReducer;

export const {
  setAirtelSSEData,
  setAirtelTrackOrderSSEData,
  clearAirtelSSEData,
} = airtelSseSlice.actions;

export { verifyAirtelPayment };
