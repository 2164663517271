// RestaurantCardSkeleton.js
import React from 'react';
import { Skeleton } from '@material-ui/lab';
import styled from 'styled-components';

const RestaurantCardSkeleton = () => {
  return (
    <SkeletonCard>
      <Skeleton variant="rect" width="100%" height={150} className="skeleton-image" />
      <Skeleton variant="text" width="80%" height={30} className="skeleton-text" />
      <Skeleton variant="circle" width={50} height={50} className="skeleton-badge" />
      <Skeleton variant="text" width="60%" height={20} className="skeleton-address" />
    </SkeletonCard>
  );
};

export default RestaurantCardSkeleton;

const SkeletonCard = styled.div`
  flex: 0 0 auto;
  width: 300px;
  margin-right: 20px;
  background-color: #ffffff;
  color: #333;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  scroll-snap-align: start;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .skeleton-image {
    margin-bottom: 10px;
    border-radius: 8px;
  }

  .skeleton-text {
    margin-bottom: 10px;
  }

  .skeleton-badge {
    margin-bottom: 10px;
  }

  .skeleton-address {
    margin-top: 10px;
  }
`;
