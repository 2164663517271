import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

let addressId = 0;
const initialState = {
  products: [],
  qty: 0,
  total: 0,
  shippingAddress: {},
  zones: [],
  isLoading: false,
  paymentMethod: {},
};
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      const { item } = action.payload;
      const { _id, price } = item;
      const findProductIdx = state.products.findIndex(
        (prod) => prod._id === _id
      );
      if (findProductIdx !== -1) {
        state.products[findProductIdx].qty += 1;
      } else {
        state.products.push({
          _id: item._id,
          name: item.title,
          price: item.price,
          imageUrl: item.product_image,
          countInStock: item.countInStock,
          qty: 1,
          vendorId: item.creator,
          unit: item.unit_id._id,
        });
      }
      state.total += price;
    },
    incrementCartItem(state, action) {
      const { item } = action.payload;
      const { _id, price } = item;
      const findProductIdx = state.products.findIndex(
        (prod) => prod._id === _id
      );
      if (findProductIdx !== -1) {
        state.products[findProductIdx].qty += 1;
      } else {
        state.products.push({
          _id: item._id,
          name: item.title,
          price: item.price,
          imageUrl: item.product_image,
          countInStock: item.countInStock,
          qty: 1,
          vendorId: item.creator,
          unit: item.unit_id._id,
        });
      }
      state.total += price;
    },
    decrementCartItem(state, action) {
      const { _id } = action.payload;
      const findProductIdx = state.products.findIndex(
        (prod) => prod._id === _id
      );
      if (findProductIdx !== -1) {
        const product = state.products[findProductIdx];
        if (product.qty > 1) {
          state.products[findProductIdx].qty -= 1;
        } else {
          state.products = state.products.filter((prod) => prod._id !== _id);
        }
        state.total -= product.price;
      }
    },
    removeItemFromCart(state, action) {
      const { _id } = action.payload;
      const findProductIdx = state.products.findIndex(
        (prod) => prod._id === _id
      );
      const product = state.products[findProductIdx];
      if (findProductIdx !== -1) {
        state.products = state.products.filter((prod) => prod._id !== _id);
      }
      state.total -= product.price * product.qty;
    },
    saveShippingAddress(state, action) {
      state.shippingAddress = action.payload;
    },
    prepare(shippingAddress) {
      return {
        payload: { shippingAddress, id: addressId++ },
      };
    },

    fetchDeliveryAddressLoading: (state) => {
      state.isLoading = true;
    },
    fetchDeliveryAddressSuccess: (state, action) => {
      state.zone = action.payload;
      // state.shippingAddress = action.payload;
      state.isLoading = false;
    },
    fetchDeliveryAddressError: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    savePaymentMethod(state, action) {
      state.paymentMethod = action.payload;
    },
    resetCart: (state) => {
      state.products = [];
      state.qty = 0;
      state.total = 0;
      state.shippingAddress = {};
      state.paymentMethod = {};
    },
  },
});

export const {
  addToCart,
  removeItemFromCart,
  incrementCartItem,
  decrementCartItem,
  saveShippingAddress,
  fetchDeliveryAddressLoading,
  fetchDeliveryAddressSuccess,
  fetchDeliveryAddressError,
  savePaymentMethod,
  resetCart,
} = cartSlice.actions;

const persistConfig = {
  key: "cart",
  storage: storage,
  blacklist: ["isLoading"],
};

export default persistReducer(persistConfig, cartSlice.reducer);
