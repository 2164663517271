import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    isFetching: false,
    error: null,
    mailSignUpError: null,
  },
  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
      state.error = null;
      state.mailSignUpError = null;
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
      state.error = null;
      state.mailSignUpError = null;
    },
    signUpSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
      state.error = null;
      state.mailSignUpError = null;
    },
    loginFailure: (state, action) => {
      state.error = action.payload;
      state.mailSignUpError = null;
      state.isFetching = false;
    },
    signUpFailure: (state, action) => {
      state.mailSignUpError = action.payload;
      state.isFetching = false;
    },
    signUpStart: (state) => {
      state.isFetching = true;
      state.error = null;
      state.mailSignUpError = null;
    },
    logout: (state) => {
      state.currentUser = null;
      state.error = null;
      state.mailSignUpError = null;
    },
    resetIsFetching: (state) => {
      state.isFetching = false;
    },
    resetError: (state) => {
      state.error = null;
      state.mailSignUpError = null;
      state.isFetching = false;
    },
  },
});

export const {
  loginStart,
  signUpStart,
  loginSuccess,
  signUpSuccess,
  loginFailure,
  signUpFailure,
  logout,
  resetIsFetching,
  resetError,
} = userSlice.actions;

const persistConfig = {
  key: "user",
  storage: storage,
  blacklist: ["isFetching"], // you want to blacklist isFetching field from persisting
};

export default persistReducer(persistConfig, userSlice.reducer);
