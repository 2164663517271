import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
  height: 30px;
  background-color: #cf0;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-style: italic;
  border-radius: 5px;
  ${mobile({
    height: "25px",
    marginTop: "35px",
  })}
`;

const Announcement = () => {
  return <Container>Super Deal! Free Shipping on Orders Over 500k</Container>;
};

export default Announcement;
