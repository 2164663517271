import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { userRequest } from '../api/requestMethods';

const loadOrderFromLocalStorage = () => {
    const savedOrder = localStorage.getItem('restaurantOrder');
    return savedOrder ? JSON.parse(savedOrder) : '';
};

const saveOrderToLocalStorage = (order) => {
    localStorage.setItem('restaurantOrder', JSON.stringify(order));
};

const initialState = {
    order: loadOrderFromLocalStorage(),
    orderCreationStatus: '',
    paymentCreationStatus: 'idle',
    error: null,
};

// MTN Mobile Money
export const payMoMoCheckout = createAsyncThunk(
    'restaurantOrder/payMoMoCheckout',
    async (data, { rejectWithValue }) => {
        const { client_id } = data;
        try {
            const response = await userRequest.post(`/momo/${client_id}`, data);
            return response.data;
        } catch (error) {
            console.log(error.response.data.msg);
            return rejectWithValue(error);
        }
    }
);

// Airtel Money
export const payAirtelMoneyCheckout = createAsyncThunk(
    'restaurantOrder/payAirtelCheckout',
    async (data, { rejectWithValue }) => {
        const { client_id } = data;
        try {
            const response = await userRequest.post(
                `/fw/flutterAirtelPayment/${client_id}`,
                data
            );
            return response.data;
        } catch (error) {
            toast.error(error.response.data.msg, {
                autoClose: false,
            });
            return rejectWithValue(error.response.data.msg);
        }
    }
);

// Payment with Credit Card
export const payCardCheckout = createAsyncThunk(
    'restaurantOrder/payCardCheckout',
    async (data, { rejectWithValue }) => {
        const { client_id } = data;
        try {
            const response = await userRequest.post(
                `/fw/flutterCardPayment/${client_id}`,
                data,
                { withCredentials: true }
            );
            return response.data;
        } catch (error) {
            toast.error(error.response.data.message, {
                autoClose: false,
            });
            return rejectWithValue(error.message || 'Unknown Error Occured!');
        }
    }
);

// Card payment PIN verification
export const submitCardPin_Otp = createAsyncThunk(
    'restaurantOrder/submitCardPin_Otp',
    async (data, { rejectWithValue }) => {
        const { client_id } = data;
        try {
            const response = await userRequest.post(
                `/fw/flutterSubmitCardPin_Otp/${client_id}`,
                data,
                { withCredentials: true }
            );
            return response.data;
        } catch (error) {
            toast.error(error.response.data.message, {
                autoClose: false,
            });
            return rejectWithValue(error.message || 'Unknown Error Occured!');
        }
    }
);

export const createOrder = createAsyncThunk(
    'restaurantOrder/createOrder',
    async (data, { rejectWithValue }) => {
        try {
            const res = await userRequest('/mobile', {
                method: 'POST',
                data,
            });
            toast.success('Order created successfully!');
            return res.data;
        } catch (err) {
            console.log(err, "What error>>>>")
            // toast.error('Failed to create order. Please try again.');
            toast.error(err.response.data.message, {
                autoClose: false,
            }
            );
            return rejectWithValue(err);
        }
    }
);

const restaurantOrderSlice = createSlice({
    name: 'restaurantOrder',
    initialState,
    reducers: {
        resetOrderCreation: (state) => {
            state.order = '';
            state.orderCreationStatus = '';
            state.paymentCreationStatus = '';
            state.error = null;
            localStorage.removeItem('restaurantOrder');
        },
        resetRestaurantOrder: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createOrder.pending, (state) => {
                state.orderCreationStatus = 'pending';
            })
            .addCase(createOrder.fulfilled, (state, action) => {
                state.orderCreationStatus = 'fulfilled';
                state.order = action.payload;
                saveOrderToLocalStorage(action.payload);
            })
            .addCase(createOrder.rejected, (state, action) => {
                state.orderCreationStatus = 'rejected';
                state.error = action.payload;
            })
            .addCase(payMoMoCheckout.pending, (state) => {
                state.paymentCreationStatus = 'pending';
            })
            .addCase(payMoMoCheckout.fulfilled, (state) => {
                state.paymentCreationStatus = 'fulfilled';
            })
            .addCase(payMoMoCheckout.rejected, (state, action) => {
                state.paymentCreationStatus = 'rejected';
                state.error = action.payload;
            })
            .addCase(payCardCheckout.pending, (state) => {
                state.paymentCreationStatus = 'pending';
            })
            .addCase(payCardCheckout.fulfilled, (state, action) => {
                state.paymentCreationStatus = 'fulfilled';
                state.order = action.payload;
                saveOrderToLocalStorage(action.payload);
            })
            .addCase(payCardCheckout.rejected, (state, action) => {
                state.paymentCreationStatus = 'rejected';
                state.error = action.payload;
            })
            .addCase(payAirtelMoneyCheckout.pending, (state) => {
                state.paymentCreationStatus = 'processing payment';
            })
            .addCase(payAirtelMoneyCheckout.fulfilled, (state, action) => {
                state.paymentCreationStatus = 'fulfilled';
                state.order = action.payload;
                saveOrderToLocalStorage(action.payload);
            })
            .addCase(payAirtelMoneyCheckout.rejected, (state, action) => {
                state.paymentCreationStatus = 'rejected';
                state.error = action.payload;
            })
            .addCase(submitCardPin_Otp.pending, (state) => {
                state.paymentCreationStatus = 'pending';
            })
            .addCase(submitCardPin_Otp.fulfilled, (state, action) => {
                state.paymentCreationStatus = 'fulfilled';
                state.order = action.payload;
                saveOrderToLocalStorage(action.payload);
            })
            .addCase(submitCardPin_Otp.rejected, (state, action) => {
                state.paymentCreationStatus = 'rejected';
                state.error = action.payload;
            });
    },
});

export const { resetOrderCreation, resetRestaurantOrder } = restaurantOrderSlice.actions;
export default restaurantOrderSlice.reducer;
