import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userRequest } from "../api/requestMethods";

const initialState = {
  shippingCost: [],
  status: "idle",
  error: null,
};

export const fetchShippingCost = createAsyncThunk(
  "shippingCost/fetchShippingCost",
  async () => {
    const response = await userRequest.get("/shippingCost");
    return response.data;
  }
);

const shippingCostSlice = createSlice({
  name: "shippingCost",
  initialState,
  reducers: {
    resetShippingCost: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchShippingCost.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchShippingCost.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.shippingCost = action.payload;
      })
      .addCase(fetchShippingCost.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default shippingCostSlice.reducer;

export const { resetShippingCost } = shippingCostSlice.actions;
