import React from "react";
import "./CreditCardForm.css";
import SvgCardFrontPlaceHolder from "./SvgCardFrontPlaceHolder.js";
import SvgCardBackPlaceHolder from "./SvgCardBackPlaceHolder";
import PropTypes from "prop-types";
import VisaSvg from "../../assets/img/visaPng.png";
import MasterPng from "../../assets/img/mastercardPng.png";
import AmexSvg from "../../assets/img/amexsingle.png";
import DiscoverPng from "../../assets/img/discoverPng.png";
import MaestroPng from "../../assets/img/maestroPng.png";
import { Image } from "react-bootstrap";

const cardTypeForPng = {
  Visa: VisaSvg,
  "American Express": AmexSvg,
  MasterCard: MasterPng,
  Discover: DiscoverPng,
  Maestro: MaestroPng,
};

const CardPlaceHolder = ({
  cardholderName,
  cardNumber,
  expirationDate,
  isCardFlipped,
  cardType,
  cardColor,
  cvv,
}) => {
  const CardPng = cardTypeForPng[cardType];
  //   console.log("Received cardType:", cardType);

  return (
    <div className={`container preload ${isCardFlipped ? "flipped" : ""}`}>
      <div className="creditcard">
        <div className="front">
          <div id="ccsingle">
            {CardPng && (
              <Image
                src={CardPng}
                alt="logo"
                style={{
                  width: "125px",
                  height: "120px",
                  objectFit: "contain",
                }}
              />
            )}
          </div>
          <SvgCardFrontPlaceHolder
            name={cardholderName}
            card_number={cardNumber.masked}
            expirationDate={expirationDate}
            isCardFlipped={isCardFlipped}
            cardType={cardType}
            cardColor={cardColor}
            style={{ height: "200px", width: "316px" }}
          />
        </div>
        <div className="back">
          <SvgCardBackPlaceHolder
            isCardFlipped={isCardFlipped}
            name={cardholderName}
            cvv={cvv}
            cardType={cardType}
            cardColor={cardColor}
            style={{ height: "200px", width: "316px" }}
          />
        </div>
      </div>
    </div>
  );
};

CardPlaceHolder.propTypes = {
  cardholderName: PropTypes.string.isRequired,
  cardNumber: PropTypes.shape({
    masked: PropTypes.string,
  }).isRequired,
  expirationDate: PropTypes.string.isRequired,
  cvv: PropTypes.string.isRequired,
  cardType: PropTypes.string,
};

export default CardPlaceHolder;
