import React from "react";
import { useHistory } from "react-router";
import "./Promotions.css";

const Promotions = () => {
  const history = useHistory();
  return (
    <div className="Promotions">
      <div className="PromotionsContainer">
        <div className="PromotionsLeft">
          <h1>Promoted Products!</h1>
          <header className="header">
            <h1 className="voucherText">Vouchers</h1>
          </header>
          <div className="voucherRow">
            <div className="col16">
              <div className="tab">
                <p className="tabLinks">Current Promo</p>
              </div>
            </div>
            <div className="col16">
              <img
                className="voucherImage"
                src={require("../../assets/img/voucher.png")}
                height="100"
                width="100"
                alt=""
              />
              <p className="noVoucher">
                There are no vouchers available at the moment!
              </p>
              {/* Continue Shopping Button */}
              <div className="continueShopping">
                <button
                  className="continueShoppingButton"
                  onClick={() => history.push("/")}
                >
                  Continue Shopping
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promotions;
