import React from 'react';
import './ShippingMatrix.css'; // Make sure this path is correct
import Layout from '../components/Global/Layout';

const shippingData = [
  { method: 'Standard Shipping', price: '5,000 - 10,000 ', deliveryTime: '1-3 Business Days' },
  { method: 'Expedited Shipping', price: '10,000', deliveryTime: '1-2 Business Days' },
  { method: 'Same day Shipping', price: '15,000', deliveryTime: 'withing 1 hour' },
  { method: 'Lusuku Prime', price: '30,000', deliveryTime: 'Monthly subscription with unlimited and zero charge on same-day delivery!' },
];

const ShippingMatrix = () => {
  return (
    <Layout>      
      <div className="shipping-matrix-container">
        <h1 className="matrix-header">Shipping Matrix</h1>
        <table className="shipping-matrix-table">
          <thead>
            <tr>
              <th>Shipping Plan</th>
              <th>Fee (Shs)</th>
              <th>Estimated Delivery Time</th>
            </tr>
          </thead>
          <tbody>
            {shippingData.map((row, index) => (
              <tr key={index}>
                <td>{row.method}</td>
                <td>{row.price}</td>
                <td>{row.deliveryTime}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default ShippingMatrix;
