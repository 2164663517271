import React, { useEffect } from "react";
import "./OrderDetail.css";
import {
  Divider,
  Typography,
  Paper,
  FormControlLabel,
  Switch,
  Button,
} from "@material-ui/core";
import moment from "moment";
import { Card, Col, Container, Image, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../Navbar";
import { useParams } from "react-router-dom";
import { fetchOrderDetails } from "../../redux/myOrdersRedux";
import styled from "styled-components";
import { mobile } from "../../responsive.js";

const StyledContainer = styled(Container)`
  max-width: 100%;
  margin-top: 20px;
  ${mobile({
  padding: "0 10px",
})}
`;

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  ${mobile({
  padding: "10px",
})}
`;

const StyledPaper = styled(Paper)`
  padding: 20px;
  border-radius: 15px;
  background-color: #094f651a;
  ${mobile({
  padding: "10px",
})}
`;

const Section = styled.div`
  width: 100%;
`;

const ReceiptButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const OrderDetail = () => {
  const { orderDetail } = useSelector((state) => state.userOrders);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrderDetails(id));
  }, [dispatch, id]);

  const handleGenerateReceipt = () => {
    const xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      `${process.env.HEROKU_URL}/weborders/get_receipt?customerName=${orderDetail.customerName
      }&customerNumber=${orderDetail.shippingAddress.customerNumber
      }&shippingAddress=${orderDetail.shippingAddress.address
      }&shippingCost=${orderDetail.shippingCost_id.cost
      }&orderNumber=${orderDetail._id
      }&totalAmount=${orderDetail.totalPrice
      }&taxAmount=${orderDetail.taxPrice
      }&itemsInCart=${orderDetail.orderItems.length
      }&orderedItems=${JSON.stringify(
        orderDetail.orderItems
      )}&Qty="${orderDetail.orderItems.map(
        (item) => item.qty
      )}&itemPx=${orderDetail.orderItems.map((item) => item.price)}&cartPx=${orderDetail.itemsPrice
      }&updatedAt=${moment(orderDetail.updatedAt)
        .utcOffset("+03:00")
        .format("lll")}&paymentStatus=${orderDetail.isPaid ? "Paid" : "Not Paid"
      }&paidDate=${moment(orderDetail.paidAt)
        .utcOffset("+03:00")
        .format("lll")}`,
      true
    );
    xhr.responseType = "arraybuffer";
    xhr.onload = function (e) {
      if (this.status === 200) {
        const blob = new Blob([xhr.response], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Lusuku Receipt_${orderDetail._id}.pdf`;
        link.click();
      }
    };
    xhr.send();
  };

  return (
    <>
      <Navbar />
      <StyledContainer>
        <Wrapper>
          <div className="main-panel">
            <div className="content">
              <Container fluid>
                <Section>
                  <StyledPaper elevation={6}>
                    <Section>
                      <Typography variant="h5" elevation={4}>
                        <strong style={{ color: "#195400" }}>
                          {orderDetail?.client_id?.name}
                        </strong>
                      </Typography>
                      <Divider style={{ margin: "20px 0" }} />
                      <Typography variant="body1" className="orderNumber">
                        <strong>Order No.:</strong>{" "}
                        {orderDetail?.order_number
                          ? orderDetail?.order_number
                          : orderDetail?._id}
                      </Typography>

                      <Typography
                        variant="body2"
                        style={{
                          padding: "10px 0",
                        }}
                      >
                        <strong>Cart Total: </strong>

                        {orderDetail?.itemsPrice
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </Typography>

                      <Typography variant="body2">
                        <strong>Items in Cart:</strong>
                        <span className="circle">
                          {""} {orderDetail?.orderItems?.length}
                        </span>
                      </Typography>
                      <Divider
                        style={{
                          margin: "20px 0",
                          backgroundColor: "#400",
                          height: "2px",
                          width: "100%",
                        }}
                      />

                      <Typography variant="body2">
                        <strong>Agent Name:</strong>{" "}
                        {orderDetail?.agent_id?.name || orderDetail?.agent_id}
                      </Typography>

                      <Typography variant="body2">
                        <strong>Customer Number:</strong>{" "}
                        {orderDetail?.shippingAddress?.customerNumber
                          ? orderDetail?.shippingAddress?.customerNumber
                          : "Not Provided"}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Payment Method:</strong>{" "}
                        {orderDetail?.paymentMethod}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Total Cart Cost:</strong> ~ {""}
                        <span style={{ color: "#D29" }}>
                          {orderDetail?.totalPrice
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>
                      </Typography>
                      <Divider
                        style={{
                          margin: "20px 0",
                          backgroundColor: "#400",
                          height: "1.5px",
                          width: "70%",
                        }}
                      />
                      <Divider style={{ margin: "10px 0" }} />
                      {orderDetail?.shippingAddress?.selectedVillage
                        ?.village ? (
                        <Typography variant="body2">
                          <strong>Delivery Zone:</strong>{" "}
                          {
                            orderDetail?.shippingAddress?.selectedVillage
                              ?.village
                          }
                        </Typography>
                      ) : (
                        <Typography variant="body2">
                          <strong>Delivery Zone:</strong>{" "}
                          {orderDetail?.shippingAddress?.locationName?.vicinity}
                          {orderDetail?.shippingAddress?.description}
                        </Typography>
                      )}

                      {orderDetail?.shippingAddress?.address ? (
                        <Typography variant="body2">
                          <strong>Address:</strong> <br />
                          {orderDetail?.shippingAddress?.address}
                          {orderDetail?.shippingAddress?.address?.address}
                        </Typography>
                      ) : (
                        <Typography variant="body2">
                          <strong>Address:</strong>{" "}
                          {orderDetail?.shippingAddress?.locationName?.name}
                        </Typography>
                      )}

                      <Typography variant="body2">
                        <strong>Shipping Fees:</strong>{" "}
                        {orderDetail?.shippingAddress?.selectedVillage
                          ?.shippingCost
                          ? orderDetail?.shippingAddress?.selectedVillage
                            ?.shippingCost
                          : orderDetail?.shippingCost_id?.cost
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </Typography>

                      <Typography variant="body2">
                        <strong>Delivery Schedule:</strong> ~ {""}
                        <span style={{ color: "#9FC131" }}>
                          {orderDetail?.deliverySchedule
                            ? orderDetail?.deliverySchedule
                            : "Call in"}
                        </span>
                      </Typography>
                      <Typography variant="body2">
                        <strong>Delivery Address:</strong> ~ {""}
                        <span style={{ color: "#004152" }}>
                          <br />
                          {orderDetail?.shippingAddress?.locationName?.name}
                        </span>
                      </Typography>

                      <Divider
                        style={{
                          margin: "20px 0",
                          backgroundColor: "#400",
                          height: "2px",
                          width: "50%",
                        }}
                      />
                      <Typography variant="body2">
                        <strong>Time placed:</strong>{" "}
                        <span style={{ color: "#195400" }}>
                          {moment(orderDetail?.createdAt).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        </span>
                      </Typography>
                      {!orderDetail?.deliveredAt ? (
                        <Typography
                          variant="body2"
                          style={{
                            color:
                              orderDetail?.delivery_status === "PROCESSING"
                                ? "#e3b200"
                                : orderDetail?.delivery_status === "ASSIGNED"
                                  ? "#005cad"
                                  : orderDetail?.delivery_status === "DISPATCHED"
                                    ? "#ad0062"
                                    : orderDetail?.delivery_status === "DELIVERED"
                                      ? "#195400"
                                      : orderDetail?.delivery_status === "REJECTED"
                                        ? "#ad0000"
                                        : "#04020f",
                          }}
                        >
                          <strong>Status:</strong>{" "}
                          {orderDetail?.delivery_status}
                        </Typography>
                      ) : (
                        <Typography variant="body2">
                          <strong>Delivered:</strong>
                          {""} {moment(orderDetail?.deliveredAt).fromNow()}
                        </Typography>
                      )}
                      {orderDetail?.riderId && (
                        <Typography variant="body2">
                          <strong>DA:</strong>{" "}
                          {orderDetail?.riderId?.firstName +
                            " " +
                            orderDetail?.riderId?.lastName}
                        </Typography>
                      )}

                      <Divider style={{ margin: "20px 0" }} />

                      <FormControlLabel
                        control={
                          <Switch
                            checked={orderDetail?.isPaid ?? false}
                            color="primary"
                            disabled={
                              orderDetail?.isPaid || !orderDetail?.isPaid
                            }
                          />
                        }
                        label={!orderDetail?.isPaid ? "Not Paid" : "Paid"}
                        disabled={
                          orderDetail?.isPaid ||
                          orderDetail?.delivery_status === "REJECTED"
                        }
                      />
                      {orderDetail?.isPaid && (
                        <Typography
                          variant="caption"
                          display="block"
                          style={{ color: "green" }}
                        >
                          <strong>Pay date:</strong> {""}{" "}
                          {moment(orderDetail?.paidAt)
                            .utcOffset("+03:00")
                            .format("lll")}
                        </Typography>
                      )}
                    </Section>
                    <ReceiptButton>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleGenerateReceipt}
                        disabled={!orderDetail?.isPaid}
                      >
                        Generate Receipt
                      </Button>
                    </ReceiptButton>

                    <Card className="cardSection" elevation={8}>
                      <Container fluid>
                        <Row>
                          <Col md="12">
                            <Card.Body className="table-full-width">
                              <Table responsive>
                                <thead>
                                  <tr className="info">
                                    <th
                                      style={{
                                        color: "white",
                                        fontStyle: "italic",
                                      }}
                                      className="text-center"
                                    >
                                      <strong>#</strong>
                                    </th>
                                    <th
                                      style={{
                                        color: "white",
                                        fontStyle: "italic",
                                      }}
                                      className="text-center"
                                    >
                                      <strong>Item Name</strong>
                                    </th>
                                    <th
                                      style={{
                                        color: "white",
                                        fontStyle: "italic",
                                      }}
                                      className="text-center"
                                    >
                                      <strong>Qty</strong>
                                    </th>
                                    <th
                                      style={{
                                        color: "white",
                                        fontStyle: "italic",
                                      }}
                                      className="text-center"
                                    >
                                      <strong>Unit Price</strong>
                                    </th>
                                    <th
                                      style={{
                                        color: "white",
                                        fontStyle: "italic",
                                      }}
                                      className="text-center"
                                    >
                                      <strong>Image</strong>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {orderDetail?.orderItems?.map(
                                    (item, index) => (
                                      <tr key={index}>
                                        <td
                                          className="text-center"
                                          style={{ color: "#066a80" }}
                                        >
                                          {index + 1}.{" "}
                                        </td>
                                        <td
                                          className="text-center"
                                          style={{
                                            color: "#5096d1",
                                            fontStyle: "italic",
                                            textAlign: "left",
                                          }}
                                        >
                                          {item.name}
                                        </td>
                                        <td
                                          className="text-center"
                                          style={{ color: "white" }}
                                        >
                                          {item.qty}
                                        </td>
                                        <td
                                          className="text-center"
                                          style={{ color: "#066a80" }}
                                        >
                                          Shs{" "}
                                          {item?.price
                                            ?.toString()
                                            ?.replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )}
                                        </td>
                                        <td className="text-center">
                                          <Image
                                            src={item.imageUrl || item.image}
                                            resizemode="contain"
                                            style={{
                                              width: 60,
                                              height: 55,
                                              borderRadius: "15%",
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </Card.Body>
                          </Col>
                        </Row>
                      </Container>
                    </Card>
                  </StyledPaper>
                </Section>
              </Container>
            </div>
          </div>
        </Wrapper>
      </StyledContainer>
    </>
  );
};

export default OrderDetail;
