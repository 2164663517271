import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { addToCart, removeFromCart } from '../redux/retailerWholesalerCartSlice';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const RetailerWholesalerProductModal = ({ open, onClose, product }) => {
    const dispatch = useDispatch();
    const currentVendor = useSelector((state) => state.retailerWholesalerCart.currentVendor);
    const cart = useSelector((state) => state.retailerWholesalerCart.carts[currentVendor] || {});
    const cartItem = cart[product._id] || {};

    const handleAddToCart = () => {
        dispatch(addToCart({ vendorId: currentVendor, product, quantity: 1 }));
    };

    const handleIncrease = () => {
        dispatch(addToCart({ vendorId: currentVendor, product, quantity: 1 }));
    };

    const handleDecrease = () => {
        dispatch(addToCart({ vendorId: currentVendor, product, quantity: -1 }));
        if (cartItem.quantity === 1) {
            dispatch(removeFromCart({ vendorId: currentVendor, productId: product._id }));
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                {product.name}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    style={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <ProductImage src={product.image} alt={product.name} />
                <Typography variant="body1">{product.description}</Typography>
                <Typography variant="h6">
                    {new Intl.NumberFormat('en-UG', {
                        style: 'currency',
                        currency: 'UGX',
                    }).format(product.price)}
                </Typography>
                <QuantityContainer>
                    <IconButton onClick={handleDecrease} disabled={!cartItem.quantity}>
                        <RemoveIcon />
                    </IconButton>
                    <Typography>{cartItem.quantity || 0}</Typography>
                    <IconButton onClick={handleIncrease}>
                        <AddIcon style={{
                            fill: 'green'
                        }} />
                    </IconButton>
                </QuantityContainer>
                <ButtonContainer>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddToCart}
                        disabled={cartItem.quantity > 0}
                    >
                        {cartItem.quantity > 0 ? 'Item in Cart' : 'Add to Cart'}
                    </Button>
                </ButtonContainer>
            </DialogContent>
        </Dialog>
    );
};

export default RetailerWholesalerProductModal;

const ProductImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 0;
  gap: 10px; /* Add gap between buttons and text */
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; /* Center the button horizontally */
  margin-top: 2px;
`;
