import { useEffect, useState } from "react";
import { Divider, Typography } from "@material-ui/core";
import { Button, Container, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetOrderCreation, resetRestaurantOrder } from "../redux/restaurantOrderActions";
import { resetPaymentStatus } from "../redux/paymentRedux";
import { resetRestaurantCart } from "../redux/restaurantCartSlice";
import { io } from "socket.io-client";
import "./Modals/momoModal.css";
import { resetRetailerWholesalerCart } from "../redux/retailerWholesalerCartSlice";

const MomoOrderScreen = () => {
  const user = useSelector((state) => state.user.currentUser);
  const { order } = useSelector((state) => state.orders);
  const restaurantOrder = useSelector((state) => state.restaurantOrder.order);
  const retailWholesalerOrder = useSelector((state) => state.retailerWholesalerOrder.order);
  const [paymentStatus, setPaymentStatus] = useState(null);

  const client_id = user?.result?._id;
  const dispatch = useDispatch();
  const history = useHistory();

  const handleContinueShopping = () => {
    dispatch(resetOrderCreation());
    dispatch(resetPaymentStatus());
    dispatch(resetRestaurantCart());
    dispatch(resetRetailerWholesalerCart());
    dispatch(resetRestaurantOrder());
    history.push("/");
  };

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL);

    socket.on("connect", () => {
      console.log("Socket connected...");
      socket.emit("subscribeToPaymentStatus", client_id);
    });

    socket.on("paymentStatusUpdate", (data) => {
      setPaymentStatus(data.msg);

      if (data.msg === "Transaction Successful" || data.msg === "Transaction Failed") {
        socket.disconnect();
      }
    });

    socket.on("disconnect", () => {
      console.log("WebSocket disconnected");
    });

    return () => {
      socket.disconnect();
      dispatch(resetOrderCreation());
      dispatch(resetPaymentStatus());
      dispatch(resetRestaurantCart());
      dispatch(resetRetailerWholesalerCart());
      dispatch(resetRestaurantOrder());
    };
  }, [client_id, dispatch]);

  const orderItems = restaurantOrder?.orderItems || retailWholesalerOrder?.orderItems || order?.orderItems || [];

  return (
    <Container className="order-summary-container">
      <div>
        <h1 style={{ fontWeight: "200" }}>Momo Order</h1>
      </div>
      <div>
        <Typography
          component={"span"}
          style={{
            fontWeight: "200",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          Thank you for Shopping On Lusuku. Your order Number{" "}
          <span
            style={{
              color: "#f50057",
            }}
          >
            {restaurantOrder?._id || retailWholesalerOrder?._id || order?._id}
          </span>{" "}
          is being processed!
        </Typography>
      </div>
      <Divider
        style={{
          margin: "20px 0px",
          backgroundColor: "lightgray",
        }}
      />
      <div>
        <Typography component="h2" variant="h6" color="primary" gutterBottom style={{ fontWeight: "200" }}>
          Ordered Items
        </Typography>
        <div style={{ width: "100%", overflowX: "auto", margin: "1rem 0" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>PRODUCT IMAGE</b>
                </TableCell>
                <TableCell>
                  <b>NAME</b>
                </TableCell>
                <TableCell>
                  <b>QUANTITY</b>
                </TableCell>
                <TableCell>
                  <b>PRICE</b>
                </TableCell>
                <TableCell>
                  <b>SUM</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderItems.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <img
                      src={item.image}
                      alt={item.image}
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "10%",
                      }}
                    />
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.qty}</TableCell>
                  <TableCell>{item.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</TableCell>
                  <TableCell>{(item.qty * item.price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div>
            {orderItems.map((item, index) => (
              <div key={index}>
                <Typography variant="body2" color="textSecondary" style={{ fontWeight: "200", paddingTop: "10px" }}>
                  Momo Transaction ID: <strong>{item.momo_id}</strong>
                </Typography>
                <Typography variant="body2" color="textSecondary" style={{ fontWeight: "200", paddingTop: "10px" }}>
                  Transaction Status: <strong>{paymentStatus}</strong>
                </Typography>
              </div>
            ))}
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              style={{
                width: "100%",
                backgroundColor: "black",
                color: "white",
                fontWeight: "600",
                margin: "10px 0px 15px 0px",
              }}
              onClick={handleContinueShopping}
            >
              Continue Shopping
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MomoOrderScreen;
