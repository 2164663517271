import {
  // loginEnd,
  loginFailure,
  loginStart,
  loginSuccess,
  logout,
  signUpFailure,
  signUpStart,
  // signUpStart,
  signUpSuccess,
  // resetError,
} from "./userRedux";
import { publicRequest } from "../api/requestMethods";

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  // dispatch(resetError());
  try {
    const res = await publicRequest.post("/user/signin", user);
    dispatch(loginSuccess(res.data));
  } catch (err) {
    console.log(err.message, "login failure!");
    const errorMessage =
      err.response && err.response.data && err.response.data.message
        ? { message: err.response.data.message }
        : { message: err.message };
    dispatch(loginFailure(errorMessage));
  }
};

export const signup = async (dispatch, user) => {
  dispatch(signUpStart());
  try {
    const res = await publicRequest.post("mobileUser/signup", user);
    dispatch(signUpSuccess(res.data));
    // return res;
  } catch (error) {
    const errorMessageSignUp = error.response.data.message;
    dispatch(signUpFailure(errorMessageSignUp));
    // throw error;
  }
};
export const signUpWithGoogle = async (dispatch, googleData) => {
  // dispatch(signUpStart());
  try {
    const res = await publicRequest.post("mobileUser/signup", googleData);
    dispatch(signUpSuccess(res.data));
    // return res;
  } catch (error) {
    const errorMessageSignUpGoogle = error.response.data.message;
    dispatch(signUpFailure(errorMessageSignUpGoogle));
  }
};

export const loginWithGoogle = async (dispatch, user) => {
  try {
    const res = await publicRequest.post("/user/googleSignin", {
      email: user.email,
      googleId: user.googleId,
      name: user.name,
    });
    dispatch(loginSuccess(res.data));
  } catch (err) {
    const errorMessageGoogle = err.response?.data?.message || err.message;
    dispatch(loginFailure(errorMessageGoogle));
  }
};

export const logoutUser = (dispatch) => {
  dispatch(logout());
};
