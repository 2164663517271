import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Paper, Typography, Divider, List, ListItem, ListItemText, IconButton, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import { addToCart, removeFromCart } from '../redux/retailerWholesalerCartSlice';
import { ReactComponent as EmptyCartSVG } from '../assets/img/Emptycart.svg';

const RetailerWholesalerCart = ({ onClose }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const currentVendor = useSelector((state) => state.retailerWholesalerCart.currentVendor);
    const cart = useSelector((state) => state.retailerWholesalerCart.carts[currentVendor] || {});
    const totalAmount = useSelector((state) => state.retailerWholesalerCart.total);

    const cartItems = Object.values(cart);

    const handleIncrease = (product) => {
        dispatch(addToCart({ vendorId: currentVendor, product, quantity: 1 }));
    };

    const handleDecrease = (product) => {
        dispatch(addToCart({ vendorId: currentVendor, product, quantity: -1 }));
        if (product.quantity === 1) {
            dispatch(removeFromCart({ vendorId: currentVendor, productId: product._id }));
        }
    };

    const checkoutHandler = () => {
        history.push("/retailer-wholesaler-shipping");
    };

    return (
        <OverlayContainer>
            <CartContainer elevation={6}>
                <CloseButton onClick={onClose}>
                    <CloseIcon />
                </CloseButton>
                <div>
                    <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
                        Your order
                    </Typography>
                    <Divider style={{ marginBottom: '20px' }} />
                    {cartItems.length === 0 ? (
                        <>
                            <EmptyCartImage>
                                <EmptyCartSVG />
                            </EmptyCartImage>
                            <Typography variant="body2" paragraph>
                                You've not added any products yet. When you do, you'll see them here!
                            </Typography>
                        </>
                    ) : (
                        <>
                            <List>
                                {cartItems.map((item) => (
                                    <ListItem key={item._id}>
                                        <ItemDetails>
                                            <ItemImage src={item.image} alt={item.name} />
                                            <ListItemText
                                                primary={item.name}
                                                secondary={`Total: UGX ${new Intl.NumberFormat('en-UG', {
                                                    style: 'currency',
                                                    currency: 'UGX',
                                                }).format(item.price * item.quantity)}`}
                                            />
                                        </ItemDetails>
                                        <QuantityContainer>
                                            <IconButton
                                                onClick={() => handleDecrease(item)}
                                                style={{
                                                    backgroundColor: '#F23030',
                                                    color: '#fff',
                                                }}
                                            >
                                                <RemoveIcon fontSize="small" />
                                            </IconButton>
                                            <Typography>{item.quantity}</Typography>
                                            <IconButton
                                                onClick={() => handleIncrease(item)}
                                                style={{ backgroundColor: '#267365', color: '#fff' }}
                                            >
                                                <AddIcon fontSize="small" />
                                            </IconButton>
                                        </QuantityContainer>
                                    </ListItem>
                                ))}
                            </List>
                            <Divider style={{ margin: '20px 0' }} />
                            <TotalAmount variant="h6" gutterBottom>
                                Total: {new Intl.NumberFormat('en-UG', {
                                    style: 'currency',
                                    currency: 'UGX',
                                }).format(totalAmount)}
                            </TotalAmount>
                        </>
                    )}
                </div>
                <BottomContainer>
                    <CheckoutButton variant="contained" onClick={checkoutHandler} disabled={cartItems.length === 0}>
                        Checkout
                    </CheckoutButton>
                </BottomContainer>
            </CartContainer>
        </OverlayContainer>
    );
};

export default RetailerWholesalerCart;

const EmptyCartImage = styled.div`
  margin: 20px 0;
  svg {
    width: 80%;
    height: auto;
    opacity: 0.3;
  }
`;

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const CartContainer = styled(Paper)`
  padding: 20px;
  padding-right: 10px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: #fff;
  width: 80%;
  max-width: 600px;
  height: 590px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-left: 20px;
`;

const CheckoutButton = styled(Button)`
  background-color: #042940;
  color: #fff;
  &:hover {
    background-color: #03506f;
  }
  width: 100%;
`;

const TotalAmount = styled(Typography)`
  margin-top: 20px;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
`;

const ItemImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 5px;
`;

const ItemDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; 
  width: 100%;
`;
