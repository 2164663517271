// src/redux/wholesaleRetailVendorSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userRequest } from '../api/requestMethods';

// Fetch vendor details
export const fetchVendorDetails = createAsyncThunk(
    'wholesaleRetailVendor/fetchVendorDetails',
    async (vendorId, thunkAPI) => {
        try {
            const response = await userRequest.get(`/rw/rw-vendors/${vendorId}`);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const wholesaleRetailVendorSlice = createSlice({
    name: 'wholesaleRetailVendor',
    initialState: {
        vendor: { sections: [] }, // Initialize sections to an empty array
        isFetching: false,
        error: null,
    },
    reducers: {
        clearVendorDetails: (state) => {
            state.vendor = { sections: [] }; // Reset sections to an empty array
            state.isFetching = false;
            state.error = null;
        },
        setSelectedVendor: (state, action) => {
            state.vendor = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchVendorDetails.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchVendorDetails.fulfilled, (state, action) => {
                state.isFetching = false;
                state.vendor = action.payload;
            })
            .addCase(fetchVendorDetails.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.payload;
            });
    },
});

export const { clearVendorDetails, setSelectedVendor } = wholesaleRetailVendorSlice.actions;

export default wholesaleRetailVendorSlice.reducer;
