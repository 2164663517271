// src/redux/promotedVendorsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userRequest } from "../api/requestMethods";
export const fetchPromotedVendors = createAsyncThunk(
    "promotedVendors/fetchPromotedVendors",
    async (_, { rejectWithValue }) => {
        try {
            const response = await userRequest.get("/rw/promoted-vendors");
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const promotedVendorsSlice = createSlice({
    name: "promotedVendors",
    initialState: {
        promotedVendors: [],
        isFetching: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPromotedVendors.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchPromotedVendors.fulfilled, (state, action) => {
                state.promotedVendors = action.payload.promotedVendors;
                state.isFetching = false;
                state.error = null;
            })
            .addCase(fetchPromotedVendors.rejected, (state, action) => {
                state.error = action.payload;
                state.isFetching = false;
            });
    },
});

export default promotedVendorsSlice.reducer;
