import React from "react";
// import { LinkContainer } from "react-router-bootstrap";
import "./PinOtpSteps.css";

const PinOtpSteps = ({ step1Completed, step2 }) => {
  return (
    <div className="step-indicator">
      <div className={`step ${step1Completed ? "completed" : "active"}`}>
        1. PIN Entry
      </div>
      <div className={`step ${step2 ? "active" : ""}`}>2. OTP Entry</div>
    </div>
  );
};

export default PinOtpSteps;
