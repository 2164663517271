import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userRequest } from "../api/requestMethods";

const initialState = {
  myOrders: [],
  isFetching: false,
  error: null,
  orderDetail: {},
};

// Get user's orders
export const fetchUserOrders = createAsyncThunk(
  "orders/fetchUserOrders",
  async (userId) => {
    const res = await userRequest.get(`/mobile/${userId}/myorders`);
    // console.log(res.data, "res from fetchUserOrders");
    return res.data;
  }
);

// Get Order Details
export const fetchOrderDetails = createAsyncThunk(
  "orders/fetchOrderDetails",
  async (orderId) => {
    const res = await userRequest.get(`/weborders/${orderId}`);
    return res.data;
  }
);

const ordersSlice = createSlice({
  name: "myOrders",
  initialState,
  reducers: {
    ordersFetched: (state, action) => {
      state.myOrders = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserOrders.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchUserOrders.fulfilled, (state, action) => {
        state.myOrders = action.payload;
        state.isFetching = false;
        state.error = false;
      })
      .addCase(fetchUserOrders.rejected, (state) => {
        state.error = true;
        state.isFetching = false;
      })
      .addCase(fetchOrderDetails.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchOrderDetails.fulfilled, (state, action) => {
        state.orderDetail = action.payload;
        state.isFetching = false;
        state.error = false;
      })
      .addCase(fetchOrderDetails.rejected, (state) => {
        state.error = true;
        state.isFetching = false;
      });
  },
});
export const { ordersFetched } = ordersSlice.actions;
export default ordersSlice.reducer;
