import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Divider } from "@material-ui/core";

import { savePaymentMethod } from "../redux/cartRedux";
import momoCod from "../assets/img/momoCod.png";
import Navbar from "../components/Navbar";
import styled from "styled-components";

const PaymentOptionButton = styled.button`
  border: none;
  padding: 15px 20px;
  background-color: teal;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 4px;
  &:disabled {
    background-color: #ccc;
    color: #aaa;
    font-weight: 300;
    font-size: 14px;
    cursor: not-allowed;
  }
`;
const PaymentScreen = () => {
  const { products } = useSelector((state) => state.cart);
  const [selectedOption, setSelectedOption] = useState(-1);

  const dispatch = useDispatch();
  const history = useHistory();

  // if there are no products, redirect to cart page
  useEffect(() => {
    if (products.length === 0) {
      history.push("/cart");
    }
  }, [history, products]);

  const submitHandler = (e) => {
    e.preventDefault();
    const options = ["MOMO", "Airtel", "Visa", "COD"];
    dispatch(savePaymentMethod(options[selectedOption]));
    history.push("/placeorder");
  };

  const handleOptionChange = (index) => {
    setSelectedOption(index);
  };

  const paymentOptions = [
    {
      label: "MTN Mobile Money",
      imageUrl:
        "https://res.cloudinary.com/lusuku/image/upload/v1693301528/Lusuku_Public_Resources/Mtn_Momo_twnyoa.svg",
    },
    {
      label: "Airtel Money",
      imageUrl:
        // "https://res.cloudinary.com/lusuku/image/upload/v1693300204/Lusuku_Public_Resources/Bharti_Airtel-Logo.lusuku_zrol8n.svg",
        "https://res.cloudinary.com/lusuku/image/upload/v1693304179/Lusuku_Public_Resources/airtel_money_lusuku_htjiy7.png",
    },
    {
      label: "Credit Card",
      imageUrl:
        "https://res.cloudinary.com/lusuku/image/upload/v1693302551/Lusuku_Public_Resources/Visa_Inc.-Logo.lusuku_xrhhuw.svg",
    },
    {
      label: "Cash On Delivery",
      imageUrl:
        "https://res.cloudinary.com/lusuku/image/upload/v1693307048/Lusuku_Public_Resources/cod_lusuku_mygqcm.png",
    },
  ];

  const optionStyle = (isSelected) => ({
    display: "flex",
    alignItems: "center",
    gap: "16px",
    padding: "12px 16px",
    borderRadius: "4px",
    cursor: "pointer",
    backgroundColor: isSelected ? "#DBF3FA" : "#EDF2F7",
    boxShadow: isSelected ? "0px 2px 5px rgba(0, 0, 0, 0.1)" : "none",
    transition: "all 0.3s",
    textAlign: "center",
    fontWeight: isSelected ? "bold" : "normal",
    marginBottom: "16px",
    height: "60px",
  });

  return (
    <>
      <Navbar />
      <div
        style={{
          width: "100vw",
          height: "100vh",
          background: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(${momoCod}) center`,
          backgroundSize: "cover",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            // width: "50%",
            maxWidth: "500px",
            width: "80%",
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.9)",
          }}
        >
          <h1
            style={{ fontSize: "24px", fontWeight: "300", textAlign: "center" }}
          >
            PAYMENT OPTIONS
          </h1>
          <Divider />
          <form>
            <label
              style={{
                display: "block",
                marginBottom: "10px",
                marginTop: "20px",
                fontSize: "16px",
                fontStyle: "italic",
                color: "#4A4A4A",
              }}
            >
              Select Payment Method
            </label>
            {paymentOptions.map(({ label, imageUrl }, index) => (
              <div
                key={index}
                style={optionStyle(selectedOption === index)}
                onClick={() => handleOptionChange(index)}
              >
                <img
                  src={imageUrl}
                  alt={label}
                  style={{ width: "50px", height: "44px", marginRight: "5px" }}
                />
                <div
                  style={{
                    width: "16px",
                    height: "16px",
                    borderRadius: "50%",
                    border:
                      selectedOption === index
                        ? "3px solid #3f83f8"
                        : "1px solid #D1D5DB",
                    backgroundColor:
                      selectedOption === index ? "#3f83f8" : "transparent",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "8px",
                  }}
                >
                  {selectedOption === index && (
                    <div
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor: "white",
                      }}
                    ></div>
                  )}
                </div>
                {label}
              </div>
            ))}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                // marginTop: "15px",
              }}
            >
              <PaymentOptionButton
                variant="contained"
                color="primary"
                // style={{ marginTop: "10px" }}
                disabled={selectedOption === -1}
                style={{
                  width: "150px",
                  marginTop: "10px",
                }}
                onClick={submitHandler}
              >
                Continue
              </PaymentOptionButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PaymentScreen;
