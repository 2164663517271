import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Navbar from "../components/Navbar";
import {
  CircularProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";

import { resetCart } from "../redux/cartRedux";
import { resetOrderCreation } from "../redux/createOrderAction";
import { resetShippingCost } from "../redux/shippingCostRedux";

import {
  clearAirtelSSEData,
  setAirtelSSEData,
  setAirtelTrackOrderSSEData,
  verifyAirtelPayment,
} from "../redux/airtelSseRedux.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AirtelPayScreen = () => {
  const user = useSelector((state) => state.user.currentUser);
  const { airtelSseData, trackOrderAirtelSse, verificationStatus } =
    useSelector((state) => state.airtelSse);

  const userId = user?.result?._id;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!user) {
      history.push("/login");
    }
  }, [user, history]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const respObj = params.get("resp");

    // Send respObj to the server for verification
    if (respObj) {
      dispatch(verifyAirtelPayment({ userId, respObj }))
        .unwrap()
        .then((response) => {
          if (response?.data?.status === "success") {
            dispatch(resetCart());
            toast.success(response?.data?.message);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  }, [location.search, userId, dispatch]);

  const apiUrl = `${process.env.REACT_APP_SERVER_URL}/fw/events?userId=${userId}`;

  useEffect(() => {
    // const eventSource = new EventSource("http://localhost:8001/fw/events");
    const eventSource = new EventSource(apiUrl);

    eventSource.onmessage = function (event) {
      const data = JSON.parse(event.data);
      dispatch(setAirtelSSEData(data));
    };

    return () => {
      eventSource.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId]);

  const handleContinueShopping = () => {
    dispatch(resetCart());
    dispatch(resetOrderCreation());
    dispatch(resetShippingCost());
    dispatch(clearAirtelSSEData());

    history.push("/");
  };

  // Steps
  const steps = [
    "Order Placed",
    "Order Confirmed",
    "Order Dispatched",
    "Order Delivered",
    "Order Cancelled",
  ];

  // Active Step
  const [activeStep, setActiveStep] = useState();

  // getStepContent
  const getStepContent = (step) => {
    // console.log(step, "Steeppp");
    switch (step) {
      case 0:
        return "Your order has been placed successfully!";
      case 1:
        return "Your order has been confirmed!";
      case 2:
        return "Your order has been dispatched!";
      case 3:
        return "Your order has been delivered 🚚🥳!";
      default:
        return "Order Cancelled 🚫";
    }
  };

  // Track Order Status with EventSource
  useEffect(() => {
    if (trackOrderAirtelSse?.delivery_status === undefined) {
      const es = new EventSource(
        `${process.env.REACT_APP_SERVER_URL}/mobile/${userId}/stream` //Prod & DevNgrok
        // `https://lusuku.shop/mobile/${userId}/stream` //Prod
      );

      es.addEventListener("open", () => {
        console.log("Verifying connection");
      });

      es.addEventListener("message", (event) => {
        const parsedData = JSON.parse(event.data);
        dispatch(setAirtelTrackOrderSSEData(parsedData));
        // setActiveStep(data);
      });

      return () => {
        // es.removeAllEventListeners();
        es.close();
      };
    } else {
      const es = new EventSource(
        `${process.env.REACT_APP_SERVER_URL}/mobile/${userId}/stream` //ProdEnv & DevNgrok
      );
      es.addEventListener("open", () => {
        console.log("Opened - SSE connection.");
      });

      es.addEventListener("message", (event) => {
        const parsedData = JSON.parse(event.data);

        dispatch(setAirtelTrackOrderSSEData(parsedData));
      });
      return () => {
        es.close();
      };
    }
  }, [trackOrderAirtelSse, user, userId, dispatch]);

  // Handle Steps Change with useEffect
  useEffect(() => {
    if (trackOrderAirtelSse) {
      const { delivery_status } = trackOrderAirtelSse;
      if (delivery_status === "PROCESSING") {
        setActiveStep(0);
        // setActiveStep();
      } else if (delivery_status === "ASSIGNED") {
        setActiveStep(1);
      } else if (delivery_status === "DISPATCHED") {
        setActiveStep(2);
      } else if (delivery_status === "DELIVERED") {
        setActiveStep(3);
      } else if (delivery_status === "REJECTED") {
        setActiveStep(4);
      } else {
        setActiveStep(4);
      }
    }
  }, [trackOrderAirtelSse]);

  return (
    <>
      <Navbar />
      <Container className="order-summary-container-airtel">
        <div>
          <h2 style={{ fontWeight: "200", marginBottom: "14px" }}>
            Your Order Summary!
          </h2>
        </div>

        <div>
          <Typography
            component={"span"}
            style={{
              fontWeight: "200",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            Thank you for Shopping On Lusuku. Your order Number{" "}
            <span
              style={{
                color: "#f50057",
              }}
            >
              {/* {airtelSseData?.orderDetails?._id}. */}
              {trackOrderAirtelSse?._id}.
            </span>
          </Typography>
        </div>

        <Divider
          style={{
            margin: "20px 0px",
            backgroundColor: "lightgray",
          }}
        />

        <div>
          <Typography
            component="h2"
            variant="h6"
            color="primary"
            gutterBottom
            style={{ fontWeight: "200" }}
          >
            Ordered Items
          </Typography>
          <div
            style={{
              width: "100%",
              overflowX: "auto",
              margin: "1rem 0",
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "lightgray",
                  }}
                >
                  <TableCell
                    style={{
                      width: "130px",
                    }}
                  >
                    <b>PRODUCT IMAGE</b>
                  </TableCell>
                  <TableCell>
                    <b>NAME</b>
                  </TableCell>
                  <TableCell>
                    <b>QUANTITY</b>
                  </TableCell>
                  <TableCell>
                    <b>PRICE</b>
                  </TableCell>
                  <TableCell>
                    <b>SUM</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {eventData?.order?.orderItems?.map((item) => ( */}
                {trackOrderAirtelSse?.orderItems?.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell>
                      <img
                        src={item.imageUrl || item.image}
                        alt={item.imageUrl || item.image}
                        style={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "10%",
                        }}
                      />
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.qty}</TableCell>
                    <TableCell>
                      {item.price
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </TableCell>
                    <TableCell>{item.qty * item.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div>
              <div>
                {/*MoMo Transaction ID */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="div"
                    variant="body2"
                    color="textSecondary"
                    style={{ fontWeight: "200", paddingTop: "10px" }}
                  >
                    Payment Method:{" "}
                    <span
                      style={{
                        fontSize: "11px",
                        fontStyle: "italic",
                        color: "#003e64",
                      }}
                    >
                      {trackOrderAirtelSse === null ? (
                        <CircularProgress
                          size={20}
                          style={{
                            color: "orange",
                            marginLeft: "5px",
                          }}
                        />
                      ) : (
                        trackOrderAirtelSse?.paymentMethod
                      )}
                    </span>
                  </Typography>
                  {verificationStatus === "loading" ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        component="div"
                        variant="body2"
                        color="textSecondary"
                        style={{ fontWeight: "200", paddingTop: "10px" }}
                      >
                        Verifying Payment...
                        {/* {parsedVerificationData?.data?.chargeResponseMessage} */}
                      </Typography>
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: "10px" }}
                      />
                    </div>
                  ) : verificationStatus === "failed" ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        component="div"
                        variant="body2"
                        color="textSecondary"
                        style={{ fontWeight: "200", paddingTop: "10px" }}
                      >
                        Airtel Payment failed, can't be verified!
                      </Typography>
                      <CancelIcon
                        style={{
                          color: "red",
                          marginLeft: "2px",
                          marginRight: "10px",
                        }}
                      />
                    </div>
                  ) : (
                    verificationStatus === "succeeded" &&
                    airtelSseData?.data?.processor_response ===
                    "Transaction Successful" && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          component="div"
                          variant="body2"
                          color="textSecondary"
                          style={{ fontWeight: "200", paddingTop: "10px" }}
                        >
                          {airtelSseData?.data?.processor_response}
                          <span
                            style={{
                              fontSize: "11px",
                              fontStyle: "italic",
                              color: "#003e64",
                            }}
                          >
                            {trackOrderAirtelSse?.transactionId}
                          </span>
                        </Typography>
                        <CheckCircleOutlineIcon
                          style={{ color: "green", marginLeft: "8px" }}
                        />
                      </div>
                    )
                  )}
                </div>
                {/* Delivery Status */}
                <Typography
                  component="div"
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: "200", paddingTop: "10px" }}
                >
                  Delivery Status:{" "}
                  <span
                    style={{
                      fontSize: "11px",
                      fontStyle: "italic",
                      color: "#003e64",
                    }}
                  >
                    {trackOrderAirtelSse === null ? (
                      <CircularProgress
                        size={20}
                        style={{
                          color: "orange",
                          marginLeft: "5px",
                        }}
                      />
                    ) : (
                      trackOrderAirtelSse?.delivery_status
                    )}
                  </span>
                </Typography>
                {/* Delivery Address */}
                <Typography
                  component="div"
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: "200", paddingTop: "10px" }}
                >
                  Delivery Address:{" "}
                  <span
                    style={{
                      fontSize: "11px",
                      fontStyle: "italic",
                      color: "#003e64",
                    }}
                  >
                    {trackOrderAirtelSse === null ? (
                      <CircularProgress
                        size={20}
                        style={{
                          color: "orange",
                          marginLeft: "5px",
                        }}
                      />
                    ) : (
                      trackOrderAirtelSse?.shippingAddress?.locationName?.name
                    )}
                    ,{" "}
                    {
                      trackOrderAirtelSse?.shippingAddress?.locationName
                        ?.vicinity
                    }
                  </span>
                </Typography>
                {/* Delivery Contact Mobile Number */}
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: "200", paddingTop: "10px" }}
                >
                  Contact Mobile:{" "}
                  <span
                    style={{
                      fontSize: "11px",
                      fontStyle: "italic",
                      color: "#003e64",
                    }}
                  >
                    {
                      airtelSseData?.orderDetails?.shippingAddress
                        ?.customerNumber
                    }{" "}
                  </span>
                </Typography>
                {/* Order Total Cost */}
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: "200", paddingTop: "10px" }}
                >
                  Your order cost is:{" "}
                  <span
                    style={{
                      fontSize: "12px",
                      fontStyle: "italic",
                      color: "#0074a6",
                    }}
                  >
                    Shs {""}
                    {airtelSseData?.orderDetails?.totalPrice
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    /=
                  </span>
                </Typography>
                {/* TRACKING ORDER HEADER */}
                <Typography
                  variant="h6"
                  color="textSecondary"
                  style={{
                    fontWeight: "200",
                    paddingTop: "10px",
                    margin: "1rem",
                  }}
                >
                  <b>Tracking Order</b>
                  {/* Divider */}
                  <Divider
                    style={{
                      width: "100%",
                      backgroundColor: "lightgray",
                    }}
                  />
                </Typography>

                {/* STEPS WIDGET */}
                <div
                  style={{
                    width: "100%",
                    margin: "1rem 0",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    style={{
                      width: "100%",
                      backgroundColor: "transparent",
                    }}
                  >
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel
                          style={{
                            color: "black",
                            fontWeight: "200",
                          }}
                        >
                          {index + 1}.{label}
                        </StepLabel>
                        <StepContent>
                          <Typography
                            style={{
                              color: "black",
                              fontWeight: "200",
                            }}
                          >
                            {getStepContent(index)}
                          </Typography>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </div>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{
                  width: "100%",
                  // padding: "10px",
                  backgroundColor: "black",
                  color: "white",
                  fontWeight: "600",
                  margin: "10px 0px 15px 0px",
                }}
                onClick={handleContinueShopping}
              >
                Continue Shopping
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default AirtelPayScreen;
