// MainContent.js
import styled from 'styled-components';
import { mobile } from '../../responsive';

const MainContent = styled.div`
  padding-top: 80px; // Manages global styling for the NavBar
  // margin: 20px;
    ${mobile({
      marginTop: "40px"
    })}
`;

export default MainContent;
