import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { AppBar, Toolbar } from "@material-ui/core";
// import logo from "../../assets/img/signin-logo.png";
import logo from "../../assets/img/logo.png";

const CustomAppBar = () => {
  return (
    <AppBar position="static" color="inherit">
      <Toolbar>
        <Link to="/">
          <Image
            className="d-inline-block align-top pb-2 mt-2"
            src={logo}
            alt="logo"
            style={{
              width: "125px",
              height: "120px",
              objectFit: "contain",
            }}
          />
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
