import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Button,
  Container,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  CircularProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from '@material-ui/icons/Cancel';

import { resetCart } from "../redux/cartRedux";
import { resetOrderCreation } from "../redux/createOrderAction";
import {
  fetchShippingCost,
  resetShippingCost,
} from "../redux/shippingCostRedux";
import { clearSSEData, setSSEData } from "../redux/cardSseRedux";
import { toast } from "react-toastify";

const CardPaymentDoneScreen = () => {
  const user = useSelector((state) => state.user.currentUser);
  const { shippingCost } = useSelector((state) => state.shippingCosts);

  //Added a reconnect delay in case if the EventSource is in the CLOSED state
  const [reconnectInterval, setReconnectInterval] = useState(60000);

  const userId = user?.result?._id;

  const { sseData, status, lastUpdated } = useSelector(
    (state) => state.cardSse
  );
    const orderNumber = status === "loading" || !sseData?.orderDetails ? '' : sseData.orderDetails._id;


  const dispatch = useDispatch();
  const history = useHistory();

  // Active Step
  const [activeStep, setActiveStep] = useState();
  const [trackOrder, setTrackOrder] = useState();

  // Steps
  const steps = [
    "Order Placed",
    "Order Confirmed",
    "Order Dispatched",
    "Order Delivered",
    "Order Cancelled",
  ];

  // Eventsource for card order payment status
  useEffect(() => {
    let eventSource;
    const initEventSource = () => {
    eventSource = new EventSource(
      `${process.env.REACT_APP_SERVER_URL}/fw/events?userId=${userId}`, //ProdEnv & Dev
    );

    eventSource.onopen = () => {
      console.log("Connection to server opened!.");
      setReconnectInterval(60000);
    };

    eventSource.onmessage = (event) => {
      const parsedData = JSON.parse(event.data);
      // Only update the state if the incoming data has a newer timestamp
      if (
        !lastUpdated ||
        new Date(parsedData.orderDetails?.updatedAt) > new Date(lastUpdated)
      ) {
        dispatch(setSSEData(parsedData));
      }
    };

    eventSource.onerror = (error) => {
      if (eventSource.readyState === EventSource.CLOSED) {
        console.error("EventSource failed, reconnecting in", reconnectInterval, "ms...");
        setTimeout(() => {
          const newInterval = Math.min(reconnectInterval * 2, 60000); // capping it at 60 seconds
                    setReconnectInterval(newInterval);
          initEventSource();
        },reconnectInterval )
      } else {
        console.error("SSE Error:", error);
      }
    };
  }
  initEventSource();
    return () => {
      eventSource.close();
    };
  }, [dispatch, userId, lastUpdated, reconnectInterval]);

  // Eventsource for card order tracking
  useEffect(() => {
    const es = new EventSource(
      `${process.env.REACT_APP_SERVER_URL}/mobile/${userId}/stream` //ProdEnv & Dev
    );

    es.addEventListener("message", (event) => {
      const parsedData = JSON.parse(event.data);
      // console.log(parsedData, "Track Parsed Data!!!");
      setTrackOrder(parsedData);
    });

    if (trackOrder?.delivery_status === undefined) {
      es.addEventListener("open", () => {
        console.log("Waiting for connection to server!");
      });
    } else {
      es.addEventListener("open", () => {
        console.log("Connection to server opened.");
      });

      es.addEventListener("error", (error) => {
        if (es.readyState === EventSource.CLOSED) {
          console.error("EventSource failed, reconnecting...");
        } else {
          console.error("SSE Error:", error);
        }
      });
    }

    return () => {
      es.close();
    };
  }, [userId, trackOrder]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return "Your order has been placed successfully!";
      case 1:
        return "Your order has been confirmed!";
      case 2:
        return "Your order has been dispatched!";
      case 3:
        return "Your order has been delivered 🚚🥳!";
      default:
        return "Order Cancelled 🚫";
    }
  };

  // Get shipping Fees from the server
  useEffect(() => {
    dispatch(fetchShippingCost());
  }, [dispatch]);

  // Handle Steps Change with useEffect
  useEffect(() => {
    if (trackOrder) {
      const { delivery_status } = trackOrder;
      if (delivery_status === "PROCESSING") {
        setActiveStep(0);
        // setActiveStep();
      } else if (delivery_status === "ASSIGNED") {
        setActiveStep(1);
      } else if (delivery_status === "DISPATCHED") {
        setActiveStep(2);
      } else if (delivery_status === "DELIVERED") {
        setActiveStep(3);
      } else if (delivery_status === "REJECTED") {
        setActiveStep(4);
      } else {
        setActiveStep(4);
      }
    }
  }, [trackOrder]);

  const serverShippingFees = shippingCost[0]?.cost;
  const orderTotal_ShippingFees =
    Number(sseData?.orderDetails?.itemsPrice) + Number(serverShippingFees);

  const handleContinueShopping = () => {
    dispatch(resetCart());
    dispatch(resetOrderCreation());
    dispatch(resetShippingCost());
    setTrackOrder({});
    // localStorage.removeItem("eventData"); // remove the data from local storage
    dispatch(clearSSEData()); // Reset sseCard data

    history.push("/");
  };

  // If sseData?.data?.status === "successful, reset the cart and order creation
  useEffect(() => {
    if (sseData?.data?.status === "successful") {
      dispatch(resetCart());
      dispatch(resetOrderCreation());
    }
  }, [sseData?.data?.status, dispatch]);

const handleCopyClick = () => {
  navigator.clipboard.writeText(orderNumber).then(
    function () {
       toast.success("Order number Copied!");
    }, 
    function (err) {
      toast.error('Could not copy text: ', err);
    }
  );
};


  // Effect that clears SSE data when orderNumber is present
  useEffect(() => {
    if (orderNumber) {
      dispatch(resetCart());
    }
  }, [dispatch, orderNumber ]);
  return (
    <>
      <Navbar />
      <Container className="order-summary-container-airtel">
        <div style={{ paddingTop: "30px" }}>
          <h2 style={{ fontWeight: "200", marginBottom: "14px" }}>
            Your Order Summary!
          </h2>
        </div>

          <Typography
            component="div"
            style={{
              fontWeight: "200",
              padding: '1rem 0.5rem'
            }}
          >
            Thank you for Shopping On Lusuku. 
             <div style={{ 
              display: "flex",  
              alignItems: "center",
              flexWrap: "wrap",
            }}>
              Your order Number is&nbsp;
            <span
              style={{
                background: "#002333",
                color: "#ffff",
                fontStyle: "italic",
                padding: "0.2em",
                borderRadius: "4px",
                fontWeight: "lighter",
                fontSize: window.innerWidth <= 768 ? '0.9rem' : 'initial', // Decrease font size for smaller screens
                margin: '0.5rem 0'
              }}
            >
              {status === "loading" || !sseData?.orderDetails ? (
                  <CircularProgress
                    size={18}
                    style={{ color: "green", marginLeft: "4px" }}
                  />
              ) : (
                // sseData?.orderDetails?._id
                orderNumber
              )}
              .{" "}
            </span>
              {orderNumber && (
                <>
              <Button
                onClick={handleCopyClick}
                size="small"
                variant="contained"
                style={{ margin: '0.5rem 0' }} 
              >
                Copy
              </Button>
              </>
            )}
            </div>
          </Typography>

        <Divider
          style={{
            margin: "20px 0px",
            backgroundColor: "lightgray",
          }}
        />

          <Typography
            // component="h2"
            component="div"
            variant="h6"
            color="primary"
            gutterBottom
            style={{ fontWeight: "200" }}
          >
            Ordered Items
          </Typography>
          <div
            style={{
              width: "100%",
              overflowX: "auto",
              margin: "1rem 0",
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "lightgray",
                  }}
                >
                  <TableCell
                    style={{
                      width: "130px",
                    }}
                  >
                    <b>PRODUCT IMAGE</b>
                  </TableCell>
                  <TableCell>
                    <b>NAME</b>
                  </TableCell>
                  <TableCell>
                    <b>QUANTITY</b>
                  </TableCell>
                  <TableCell>
                    <b>PRICE</b>
                  </TableCell>
                  <TableCell>
                    <b>SUM</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sseData?.orderDetails?.orderItems?.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell>
                      <img
                        src={item.imageUrl}
                        alt={item.imageUrl}
                        style={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "10%",
                        }}
                      />
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.qty}</TableCell>
                    <TableCell>
                      {item.price
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </TableCell>
                    <TableCell>{item.qty * item.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
                {/*Card Transaction Status */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="div"
                    variant="body2"
                    color="textSecondary"
                    style={{ fontWeight: "200", paddingTop: "10px" }}
                  >
                    Payment Method:{" "}
                    <span
                      style={{
                        fontSize: "12px",
                        fontStyle: "italic",
                        color: "#003e64",
                      }}
                    >
                      {
                        // if sseData?.orderDetails?.paymentMethod is undefined show loading indicator otherwise show the payment method from sseData?.orderDetails?.paymentMethod
                        status === "loading" || !sseData?.orderDetails ? (
                            <CircularProgress
                              size={20}
                              style={{ color: "green", marginLeft: "8px" }}
                            />
                        ) : (
                          sseData?.orderDetails?.paymentMethod
                        )
                      }
                    </span>
                  </Typography>
                  {sseData?.data?.status === "successful" ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                        component="div"
                        variant="body2"
                        color="textSecondary"
                        style={{ fontWeight: "200" }}
                      >
                        Card payment successful!
                      </Typography>
                    <CheckCircleOutlineIcon
                      style={{ color: "green", marginLeft: "8px" }}
                    />
                    </div>
                  ) :
                    sseData?.data?.status === "failed" ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                        component="div"
                        variant="body2"
                        color="error"
                        style={{ fontWeight: "200" }}
                      >
                        Card payment deduction failed!
                      </Typography>
                       <CancelIcon
                      style={{ color: "red", marginLeft: "4px" }}
                    />
                    </div>
                    ): null }
                  
                </div>
                {/* Delivery Status */}
                <Typography
                 component="div"
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: "200", paddingTop: "10px" }}
                >
                  Delivery Status:{" "}
                  <span
                    style={{
                      fontSize: "11px",
                      fontStyle: "italic",
                      color: "#003e64",
                    }}
                  >
                    {/* {eventData?.order?.delivery_status} */}
                    {trackOrder?.delivery_status || "PROCESSING"}
                  </span>
                </Typography>
                {/* Delivery Address */}
                <Typography
                 component="div"
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: "200", paddingTop: "10px" }}
                >
                  Delivery Address:{" "}
                  <span
                    style={{
                      fontSize: "11px",
                      fontStyle: "italic",
                      color: "#003e64",
                    }}
                  >
                    {sseData?.orderDetails?.shippingAddress?.locationName?.name}
                    ,{" "}
                    {
                      sseData?.orderDetails?.shippingAddress?.locationName
                        ?.vicinity
                    }
                  </span>
                </Typography>
                {/* Delivery Contact Mobile Number */}
                <Typography
                 component="div"
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: "200", paddingTop: "10px" }}
                >
                  Contact Mobile:{" "}
                  <span
                    style={{
                      fontSize: "11px",
                      fontStyle: "italic",
                      color: "#003e64",
                    }}
                  >
                    {status === "loading" || !sseData?.orderDetails ? (
                        <CircularProgress
                          size={20}
                          style={{ color: "green", marginLeft: "8px" }}
                        />
                    ) : (
                      sseData?.orderDetails?.shippingAddress?.customerNumber
                    )}
                  </span>
                </Typography>
                {/* Order Total Cost */}
                <Typography
                 component="div"
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: "200", paddingTop: "10px" }}
                >
                  Your order cost is:{" "}
                  <span
                    style={{
                      fontSize: "12px",
                      fontStyle: "italic",
                      color: "#0074a6",
                    }}
                  >
                    Shs {""}
                    {
                      // if is not a show text loading indicator otherwise show the order total cost
                      orderTotal_ShippingFees === undefined ? (
                        <div style={{ fontStyle: "italic" }}>computing...</div>
                      ) : (
                        orderTotal_ShippingFees
                          .toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      )
                    }{" "}
                    /=
                  </span>
                </Typography>
                {/* TRACKING ORDER HEADER */}
                <Typography
                 component="div"
                  variant="h6"
                  color="textSecondary"
                  style={{
                    fontWeight: "200",
                    paddingTop: "10px",
                    margin: "1rem",
                    // textDecoration: "underline",
                  }}
                >
                  <b>Tracking Order</b>
                  {/* Divider */}
                  <Divider
                    style={{
                      width: "100%",
                      backgroundColor: "lightgray",
                    }}
                  />
                </Typography>
                {/* STEPS WIDGET */}

                <div
                  style={{
                    width: "100%",
                    margin: "1rem 0",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    style={{
                      width: "100%",
                      backgroundColor: "transparent",
                    }}
                  >
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel
                          style={{
                            color: "black",
                            fontWeight: "200",
                          }}
                        >
                          {index + 1}.{label}
                        </StepLabel>
                        <StepContent>
                          <Typography
                           component="div"
                            style={{
                              color: "black",
                              fontWeight: "200",
                            }}
                          >
                            {getStepContent(index)}
                          </Typography>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{
                  width: "100%",
                  // padding: "10px",
                  backgroundColor: "black",
                  color: "white",
                  fontWeight: "600",
                  margin: "10px 0px 15px 0px",
                }}
                onClick={handleContinueShopping}
              >
                Continue Shopping
              </Button>
            </div>
          </div>
      </Container>
    </>
  );
};

export default CardPaymentDoneScreen;
