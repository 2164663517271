import { useCallback, useEffect, useRef, useState } from "react";
import usePlacesAutocomplete, {
  getDetails,
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import styled from "styled-components";
import Navbar from "../../components/Navbar";
import { mobile } from "../../responsive";
import { saveShippingAddress } from "../../redux/cartRedux";
import { useDispatch } from "react-redux";
import { getDeliveryZones } from "../../redux/addressAction";
import { useHistory } from "react-router-dom";
import locationBg from "../../assets/img/locationBg.png";
import {
  GoogleMap,
  // useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

import mapStyles from "./mapStyles";

const Button = styled.button`
  border: none;
  padding: 15px 20px;
  background-color: teal;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 4px;
  &:disabled {
    background-color: #ccc;
    color: #aaa;
    font-weight: 300;
    font-size: 14px;
    cursor: not-allowed;
  }
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url(${locationBg});
      center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const Wrapper = styled.div`
  width: 50%;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
  ${mobile({ width: "75%" })}
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`;
const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;
const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0px;
  padding: 10px;
  border: 1px solid red;
  border-radius: 8px;
  outline: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
  background-color: #e2e2e2c4;
`;

// const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "400px",
};
const options = {
  disableDefaultUI: true,
  zoomControl: true,
  styles: mapStyles,
};

const ShippingScreen = () => {
  // Google maps
  const {
    // ready,
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "ug" },
      cache: 10 * 60 * 60 * 24,
      debounce: 100,
      location: { lat: () => 0.347596, lng: () => 32.58252 },
      radius: 200 * 1000,
      origin: { lat: 0.3148136, lng: 32.5788721 },
      strictBounds: true,
      types: ["establishment"],
    },
  });

  const [customerNumber, setCustomerNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [locationId, setLocationId] = useState("");
  const [locationName, setLocationName] = useState([]);
  const [latLng, setLatLng] = useState({ lat: 0.347596, lng: 32.58252 });
  const [fetchingSuggestions, setFetchingSuggestions] = useState(false);
  const timeoutRef = useRef(null);

  const [noResultsMessage, setNoResultsMessage] = useState("");
  const typingTimer = useRef();

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getDeliveryZones());
  }, [dispatch]);

  useEffect(() => {
    if (locationId) {
      getDetails({ placeId: locationId })
        .then((res) => {
          setLocationName({
            name: res.name,
            vicinity: res.vicinity,
          });
        })
        .catch((err) => {
          console.log(err, " location details error");
        });
    }
    return () => {
      setLocationName("");
    };
  }, [locationId]);

  // Shows searching if typing and Address not found if none is found and there's a delay of 3 seconds
  useEffect(() => {
    if (fetchingSuggestions) {
      clearTimeout(typingTimer.current);
      setNoResultsMessage("Searching...");
    } else {
      clearTimeout(typingTimer.current);
      typingTimer.current = setTimeout(() => {
        if (data.length === 0 && value.length > 0 && !locationName.name) {
          setNoResultsMessage("Address not found!");
        } else {
          setNoResultsMessage("");
        }
      }, 2000); // 3 seconds delay
    }
  }, [data, fetchingSuggestions, value, locationName]);

  // const selectedVillage = selectVillage?.value;
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      saveShippingAddress({
        customerNumber,
        // selectedVillage,
        locationName, // locationName: {name: res.name, vicinity: res.vicinity}
        latLng, // latLng
        locationId, // Delivery address locationId
      })
    );
    history.push("/payment");
  };

  const boxOptionRef = useRef();

  const panTo = useCallback(({ lat, lng }) => {
    // console.log(lat, lng, " lat lng");
  }, []);

  const handleSelect = async (value) => {
    setValue(value, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address: value });
      const { lat, lng } = await getLatLng(results[0]);
      if (lat && lng) {
        //  panTo({ lat, lng })
        setLocationId(results[0].place_id);
        // console.log(boxOptionRef.current, "boxOptionRef");
        panTo({ lat, lng });
        setLatLng({ lat, lng });
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleInput = (e) => {
    setValue(e.target.value);
    setFetchingSuggestions(true);

    // Clear previous timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout
    timeoutRef.current = setTimeout(() => {
      setFetchingSuggestions(false);
    }, 100);
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      return (
        <ComboboxOption
          key={place_id}
          value={main_text + " " + secondary_text}
        />
      );
    });

  // Get details about location ID

  // Clear suggestions
  const clearRenderedLocationDetails = () => {
    clearSuggestions();
    setLatLng({ lat: 0.347596, lng: 32.58252 });
    setValue("");
    setLocationId("");
    setLocationName("");
  };

  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    setCustomerNumber(phone);

    if (phone.length < 10) {
      setPhoneError(
        "*Phone number must be atleast 10 digits long [e.g 0700123123]"
      );
    } else {
      setPhoneError("");
    }
  };

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Title>SHIPPING ADDRESS</Title>
          <Form>
            {/* Number Input */}
            <Input
              value={customerNumber}
              // onChange={(e) => setCustomerNumber(e.target.value)}
              onChange={handlePhoneChange}
              placeholder="Enter Contact Phone number..."
              type="number"
              required
            />
            {phoneError && (
              <p
                style={{
                  color: "red",
                  fontSize: "10px",
                  margin: "0",
                  padding: "0",
                  textAlign: "left",
                  fontStyle: "italic",
                }}
              >
                {phoneError}
              </p>
            )}
            {/* Address Input With Google Map */}
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={12}
              // center={center}
              center={latLng}
              options={options}
            >
              {/* Combobox */}
              <div
                style={{
                  display: "flex",
                  alignSelf: "center",
                  flexDirection: "row",
                  marginTop: "5px",
                }}
              >
                <div
                  style={{
                    width: "80%",
                    zIndex: "3",
                    margin: "0",
                  }}
                >
                  <Combobox ref={boxOptionRef} onSelect={handleSelect}>
                    <div style={{ position: "relative" }}>
                      <ComboboxInput
                        value={value}
                        onChange={handleInput}
                        // disable={!ready}
                        placeholder="Enter Delivery Address..."
                        // className={styles.comboboxInput}
                        style={{
                          width: "100%",
                          padding: "10px",
                          border: "1px solid #f58c60",
                          borderRadius: "5px",
                          zIndex: "3",
                          boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.5)",
                          backgroundColor: "#e2e2e2c4",
                          outline: "none",
                        }}
                      />
                      {fetchingSuggestions ||
                      (data.length === 0 && value.length > 0) ? (
                        <>
                          <p
                            style={{
                              color: "red",
                              fontSize: "11px",
                              fontStyle: "italic",
                              border: "1px solid #ccc",
                              backgroundColor: "#f8f8f8",
                              borderRadius: "4px",
                              padding: "5px",
                              width: "100%",
                            }}
                          >
                            {noResultsMessage}
                          </p>
                        </>
                      ) : null}
                    </div>
                    <ComboboxPopover
                      style={{
                        backgroundColor: "white",
                        margin: "0",
                        padding: "0",
                        fontSize: "12px",
                        border: "1px solid #F58C60",
                        fontStyle: "italic",
                        boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.8)",
                        borderBottomRightRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      }}
                    >
                      <ComboboxList
                        style={{
                          marginLeft: "10px",
                          padding: "0",
                          // listStyle: "none",
                          overflow: "auto",
                          maxHeight: "200px",
                          lineHeight: "2",
                        }}
                      >
                        {renderSuggestions()}
                      </ComboboxList>
                    </ComboboxPopover>
                  </Combobox>
                </div>
              </div>

              <Marker
                // position={center}
                position={latLng}
                icon={{
                  url: "/logo512.png",
                  scaledSize: new window.google.maps.Size(30, 30),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(15, 5),
                }}
              >
                <InfoWindow
                  // position={selected ? selected.location : center}
                  position={latLng}
                  onCloseClick={() => clearRenderedLocationDetails()}
                >
                  <div
                    style={{
                      paddingBottom: "5px",
                    }}
                  >
                    <h3
                      style={{
                        color: "orange",
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      <span role="img" aria-label="bear">
                        {/* 🐻 */}
                        {/* 📍 */}
                        📌
                      </span>{" "}
                      Set Delivery Address
                    </h3>
                    <p
                      style={{
                        textAlign: "center",
                        fontStyle: "italic",
                        fontSize: "10px",
                      }}
                    >
                      <span>
                        {locationName.name} ~ {locationName.vicinity}
                      </span>
                    </p>
                  </div>
                </InfoWindow>
              </Marker>
            </GoogleMap>

            <Button
              disabled={
                // !address ||
                customerNumber.length < 10 || !locationName ? true : false
                // !selectVillage?.value?.length > 0
              }
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </Form>
        </Wrapper>
      </Container>
    </>
  );
};

export default ShippingScreen;
