// QuantityControls.js
import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { decrementCartItem, incrementCartItem, removeItemFromCart } from '../../redux/cartRedux';

const QuantityControlsContainer = styled.div`
  display: flex;
//   justify-content: space-between;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  width: 100%; 
  margin: 8px 0; // Adds some space around the container
`;

const Button = styled.button`
// background: #FFA500; // Orange background for the button, change as needed
  background: #BF1F2C; // Orange background for the button, change as needed
  border: none;
  color: white;
  width: 30px; // Width of the button
  height: 30px; // Height of the button
// border-radius: 50%; // Circular button
  border-radius: 10%; // Circular button
  font-size: 16px; // Size of the plus/minus text
  cursor: pointer;
  &:hover {
    background: #D94141; // Darker orange on hover, adjust as needed
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.35);
  }
  &:active {
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    transform: scale(0.9); // Slight shrink effect on click
  }
  // Remove the default button styling
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

const Quantity = styled.span`
  padding: 0 20px; // Space between buttons and quantity
  font-size: 16px; // Size of the quantity number
`;

const QuantityControls = ({ product, quantity }) => {
    const dispatch = useDispatch();

    const increment = () => dispatch(incrementCartItem({ item: product }));

    const decrement = () => {
        if (quantity > 1) {
          dispatch(decrementCartItem({ _id: product._id }));
        } else {
          dispatch(removeItemFromCart({ _id: product._id }));
        }
      };
  return (
    <QuantityControlsContainer>
      <Button onClick={() => decrement(product)}>-</Button>
      <Quantity>{quantity}</Quantity>
      <Button onClick={() => increment(product)}>+</Button>
    </QuantityControlsContainer>
  );
};

export default QuantityControls;
