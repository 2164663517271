import { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";

// import Navbar from "../../components/Navbar";
import styles from "./registerstyles.module.css";
import logo from "../../assets/img/logo.png";
import { signUpFailure } from "../../redux/userRedux";
import { toast } from "react-toastify";
import { signUpWithGoogle, signup } from "../../redux/apiCalls";
import Layout from "../../components/Global/Layout";

const Register = () => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID_AUTH_WEBAPP;
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
  });

  // const [error, setError] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  // const isLoading = useSelector((state) => state.registerLoader.register);
  const { isFetching, mailSignUpError } = useSelector((state) => state.user);
  // console.log(isFetching, "isFetching??");
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }
    gapi.load("client: auth2", start);
  });

  // Handle Google Login Success
  const handleGoogleSuccess = async (response) => {
    const { accessToken, profileObj } = response;
    const { email } = profileObj;

    // Preparing the data to be dispatched
    const googleData = {
      name: profileObj.name,
      email: email,
      password: "", // Set a default password or leave it empty
      googleId: profileObj.googleId,
      accessToken: accessToken,
    };
    try {
      await signUpWithGoogle(dispatch, googleData);
      const { from } = location.state || { from: { pathname: "/" } };
      history.replace(from);
    } catch (error) {
      console.log(error, "Error from Google Sign Up...");
    }
  };

  const onFailure = (res) => {
    // console.log("LOGIN FAILED! res :", res);
    toast.error(res);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signup(dispatch, data);
      const { from } = location.state || { from: { pathname: "/" } };
      history.replace(from);
    } catch (err) {
      console.log(err, "Error from Sign up...");
      dispatch(signUpFailure(err.message));
    }
  };

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  return (
    <Layout>
      <div className={styles.sign_nav_container}>
        {/* <Navbar /> */}
        <div className={styles.sign_container}>
          <div className={styles.sign_form_container}>
            <img src={logo} alt="logo" className={styles.logo_signin} />
            <div>
              <h1
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "600",
                  margin: "0 0 10px 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Sign Up
              </h1>
              {isFetching && (
                <div
                  style={{
                    marginTop: "1rem",
                    fontStyle: "italic",
                    color: "#042940",
                  }}
                >
                  A minute, we are verifying your profile...
                </div>
              )}
              {!isFetching && (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={data.name}
                    onChange={handleChange}
                    className={styles.input}
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={data.email}
                    onChange={handleChange}
                    className={styles.input}
                    required
                  />
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={data.password}
                    onChange={handleChange}
                    className={styles.input}
                    required
                  />
                  {mailSignUpError && (
                    <div className={styles.error_msg}>{mailSignUpError}*</div>
                  )}
                  <button type="submit" className={styles.sign_button}>
                    Sign Up
                  </button>
                  <Typography
                    // className={styles.new_user}
                    style={{
                      alignSelf: "flex-start",
                      paddingTop: "10px",
                      fontSize: "11px",
                      fontWeight: "500",
                      fontStyle: "italic",
                    }}
                  >
                    Already have an account?{" "}
                    <Link
                      to="/login"
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      Sign In
                    </Link>
                  </Typography>
                  {/* Google login Button */}
                  <div id="googleSignin">
                    <GoogleLogin
                      clientId={clientId}
                      buttonText="Register with Google"
                      onSuccess={handleGoogleSuccess}
                      onFailure={onFailure}
                      cookiePolicy="single_host_origin"
                      isSignedIn={true}
                      className={styles.google_sign_button}
                    />
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
