import { createSlice } from '@reduxjs/toolkit';

// Load cart from local storage
const loadCartFromLocalStorage = () => {
    const savedCart = localStorage.getItem('retailerWholesalerCart');
    return savedCart ? JSON.parse(savedCart) : {};
};

// Load total from local storage
const loadTotalFromLocalStorage = () => {
    const savedTotal = localStorage.getItem('retailerWholesalerTotal');
    return savedTotal ? JSON.parse(savedTotal) : 0;
};

// Save cart to local storage
const saveCartToLocalStorage = (carts) => {
    localStorage.setItem('retailerWholesalerCart', JSON.stringify(carts));
};

// Save total to local storage
const saveTotalToLocalStorage = (total) => {
    localStorage.setItem('retailerWholesalerTotal', JSON.stringify(total));
};

const calculateTotal = (carts) => {
    return Object.values(carts).reduce((total, vendorCart) => {
        return total + Object.values(vendorCart).reduce((vendorTotal, product) => {
            return vendorTotal + product.price * product.quantity;
        }, 0);
    }, 0);
};

const retailerWholesalerCartSlice = createSlice({
    name: 'retailerWholesalerCart',
    initialState: {
        carts: loadCartFromLocalStorage(),
        currentVendor: null,
        total: loadTotalFromLocalStorage(),
        shippingAddress: {},
        paymentMethod: null,
    },
    reducers: {
        addToCart: (state, action) => {
            const { vendorId, product, quantity } = action.payload;
            if (!state.carts[vendorId]) {
                state.carts[vendorId] = {};
            }
            if (!state.carts[vendorId][product._id]) {
                state.carts[vendorId][product._id] = { ...product, quantity: 0 };
            }
            state.carts[vendorId][product._id].quantity += quantity;
            state.total = calculateTotal(state.carts);
            saveCartToLocalStorage(state.carts); // Save to local storage
            saveTotalToLocalStorage(state.total); // Save total to local storage
        },
        removeFromCart: (state, action) => {
            const { vendorId, productId } = action.payload;
            // const product = state.carts[vendorId][productId];
            delete state.carts[vendorId][productId];
            state.total = calculateTotal(state.carts);
            saveCartToLocalStorage(state.carts); // Save to local storage
            saveTotalToLocalStorage(state.total); // Save total to local storage
        },
        setCurrentVendor: (state, action) => {
            state.currentVendor = action.payload;
        },
        saveShippingAddress: (state, action) => {
            state.shippingAddress = action.payload;
        },
        savePaymentMethod: (state, action) => {
            state.paymentMethod = action.payload;
        },
        clearCart: (state, action) => {
            const vendorId = action.payload;
            state.carts[vendorId] = {};
            state.total = calculateTotal(state.carts);
            saveCartToLocalStorage(state.carts); // Save to local storage
            saveTotalToLocalStorage(state.total); // Save total to local storage
        },
        resetRetailerWholesalerCart: (state) => {
            state.carts = {};
            state.currentVendor = null;
            state.total = 0;
            state.shippingAddress = {};
            state.paymentMethod = null;
            saveCartToLocalStorage(state.carts); // Save to local storage
            saveTotalToLocalStorage(state.total); // Save total to local storage
        },
    },
});

export const { addToCart, removeFromCart, setCurrentVendor, saveShippingAddress, savePaymentMethod, clearCart, resetRetailerWholesalerCart } = retailerWholesalerCartSlice.actions;
export default retailerWholesalerCartSlice.reducer;
