import React, { useCallback, useEffect, useState } from "react";
import "./CreditCardForm.css";
import { ReactComponent as Visa } from "../../assets/img/visa.svg";
import { ReactComponent as MasterCard } from "../../assets/img/mastercard.svg";
import { ReactComponent as Maestro } from "../../assets/img/maestro.svg";
import { ReactComponent as Discover } from "../../assets/img/discover.svg";
import { ReactComponent as Amex } from "../../assets/img/amex.svg";
import { ReactComponent as Diners } from "../../assets/img/diners.svg";
import CardPlaceHolder from "./CardPlaceHolder";
import { IMask, IMaskInput } from "react-imask";

const CreditCardForm = ({ onFormChange }) => {
  const [touchedFields, setTouchedFields] = useState({});

  const [validationErrors, setValidationErrors] = useState({});
  const [fullName, setFullName] = useState("");

  const [cardNumber, setCardNumber] = useState({ masked: "", unmasked: "" });

  const [expirationDate, setExpirationDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardColor, setCardColor] = useState("grey");
  const [CardLogo, setCardLogo] = useState(null);

  // console.log(cardType, "cardType 1>>>>");
  const [isCardFlipped, setCardFlipped] = useState(false);

  const notifyParentWithFormData = useCallback(() => {
    onFormChange({
      fullName,
      cardNumber: cardNumber.unmasked,
      expirationDate,
      cvv,
      cardType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullName, cardNumber, expirationDate, cvv, cardType]);

  const handleNameChange = (event) => {
    setFullName(event.target.value);
    setCardFlipped(false);
  };

  const handleCardNumberChange = (value, maskedValueObject) => {
    setCardNumber({
      masked: maskedValueObject.value,
      unmasked: value,
    });
    detectCardType(value);
    setCardFlipped(false);
  };

  useEffect(() => {
    notifyParentWithFormData();
  }, [
    fullName,
    cardNumber,
    expirationDate,
    cvv,
    cardType,
    notifyParentWithFormData,
  ]);

  const handleFieldBlur = (field) => {
    setTouchedFields({ ...touchedFields, [field]: true });
    validateFormFields();
  };

  const handleFieldChange = (field, value) => {
    if (touchedFields[field]) {
      validateFormFields();
    }
  };

  const masks = [
    {
      mask: "0000 000000 00000",
      regex: "^3[47]\\d{0,13}",
      cardtype: "american express",
      color: "green",
    },
    {
      mask: "0000 000000 0000",
      regex: "^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}",
      cardtype: "diners",
      color: "orange",
    },
    {
      mask: "0000 0000 0000 0000",
      regex: "^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}",
      cardtype: "mastercard",
      color: "red",
    },
    {
      mask: "0000 000000 00000",
      regex: "^(?:2131|1800)\\d{0,11}",
      cardtype: "jcb15",
      color: "green",
    },
    {
      mask: "0000 0000 0000 0000",
      regex: "^(?:35\\d{0,2})\\d{0,12}",
      cardtype: "jcb",
      color: "lightblue",
    },
    {
      mask: "0000 0000 0000 0000",
      regex: "^(?:5[0678]\\d{0,2}|6304|67\\d{0,2})\\d{0,12}",
      cardtype: "maestro",
      color: "yellow",
    },
    {
      mask: "0000 0000 0000 0000",
      regex: "^(?:6011|65\\d{0,2}|64[4-9]\\d?)\\d{0,12}",
      cardtype: "discover",
      color: "#4D4D4D",
    },
    {
      mask: "0000 0000 0000 0000",
      regex: "^4\\d{0,15}",
      cardtype: "visa",
      color: "lime",
    },
    {
      mask: "0000 0000 0000 0000",
      regex: "^62\\d{0,14}",
      cardtype: "unionpay",
      color: "cyan",
    },
    {
      mask: "0000 0000 0000 0000",
      regex: "^(?:5[0678]\\d\\d|6304|6390|67\\d\\d)\\d{0,8}",
      cardtype: "maestro",
      color: "yellow",
    },
    {
      mask: "0000 0000 0000 0000",
      cardtype: "Unknown",
      color: "grey",
    },
  ];
  const dispatch = function (appended, dynamicMasked) {
    const number = (dynamicMasked.value + appended).replace(/\D/g, "");

    for (let i = 0; i < dynamicMasked.compiledMasks.length; i++) {
      const re = new RegExp(dynamicMasked.compiledMasks[i].regex);
      if (number.match(re) != null) {
        return dynamicMasked.compiledMasks[i];
      }
    }
  };

  const detectCardType = (number) => {
    const amex = /^3[47]\d{0,13}$/;
    const visa = /^4\d{0,15}$/;
    const mastercard = /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{0,12}$/;
    const diners = /^3(?:0([0-5]|9)|[689]\d?)\d{0,11}$/;
    const maestro = /^(5[0678]\d{0,2}|6304|6390|67\d{0,2})\d{0,12}$/;
    const discover = /^(6011|65\d{0,2}|64[4-9]\d?)\d{0,12}$/;
    // You can add more card types here...

    if (number === "") {
      setCardType("");
      setCardLogo(null);
      setCardColor("grey");
    } else if (amex.test(number)) {
      setCardType("American Express");
      setCardLogo(Amex);
      setCardColor("#1B1E51");
    } else if (visa.test(number)) {
      setCardType("Visa");
      setCardLogo(Visa);
      setCardColor("#afb436");
    } else if (mastercard.test(number)) {
      setCardType("MasterCard");
      setCardLogo(MasterCard);
      setCardColor("#B2B2B2");
    } else if (maestro.test(number)) {
      setCardType("Maestro");
      setCardLogo(Maestro);
      setCardColor("#000000");
    } else if (discover.test(number)) {
      setCardType("Discover");
      setCardLogo(Discover);
      setCardColor("#4D4D4D");
    } else if (diners.test(number)) {
      setCardType("Diners");
      setCardLogo(Diners);
      setCardColor("orange");
    } else {
      setCardType("Unknown");
      setCardLogo(null);
      setCardColor("grey");
    }
  };

  // useEffect(() => {
  //   // Here you can handle the changes when the cardType is detected.
  //   console.log(cardType);
  // }, [cardType]);

  const validateFormFields = () => {
    let errors = {};

    if (!fullName) {
      errors.fullName = "Name is required.";
    }

    if (cardNumber.unmasked.trim() === "" || cardType === "Unknown") {
      errors.cardNumber = "Card number is required or invalid.";
    }

    if (expirationDate.trim() === "") {
      errors.expirationDate = "Expiration date is required.";
    }

    if (cvv.trim() === "") {
      errors.cvv = "CVV is required.";
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  return (
    <div>
      {/* Card Placeholder */}
      <CardPlaceHolder
        cardholderName={fullName}
        cardNumber={cardNumber}
        expirationDate={expirationDate}
        isCardFlipped={isCardFlipped}
        cvv={cvv}
        cardColor={cardColor}
        cardType={cardType}
      />
      <div className="payment-title">
        <h3>Enter Your Card Details</h3>
      </div>
      {/* Remainder of HTML goes here */}
      <div className="form-container">
        <div className="field-container">
          <label htmlFor="fullName">Name</label>
          <input
            id="fullName"
            maxLength="25"
            name="fullName"
            type="text"
            value={fullName}
            // onChange={handleNameChange}
            onChange={(e) => {
              handleNameChange(e);
              handleFieldChange("fullName", e.target.value);
            }}
            onBlur={() => handleFieldBlur("fullName")}
          />
          {touchedFields.fullName && validationErrors.fullName && (
            <p className="error-text">*{validationErrors.fullName}</p>
          )}
        </div>
        <div className="field-container">
          <label htmlFor="card_number">Card Number</label>
          <IMaskInput
            mask={masks}
            radix="."
            value={cardNumber.unmasked}
            unmask={true}
            dispatch={dispatch}
            onAccept={(value, maskedValue) => {
              handleCardNumberChange(value, maskedValue);
              handleFieldChange("cardNumber", value);
            }}
            onBlur={() => handleFieldBlur("cardNumber")}
            id="card_number"
            name="card_number"
            pattern="[0-9]*"
            inputMode="numeric"
            style={{ paddingRight: "50px", margin: "0px" }}
          />
          {/* SVG inside the card number input field goes here */}

          {CardLogo && (
            <CardLogo
              className="card-logo"
              style={{ position: "absolute", right: "10px", top: "10px" }}
            />
          )}
          {/* {validationErrors.cardNumber && (
            <p className="error-text">{validationErrors.cardNumber}</p>
          )} */}
          {touchedFields.cardNumber && validationErrors.cardNumber && (
            <p className="error-text">*{validationErrors.cardNumber}</p>
          )}
        </div>
        {/* Expiry Date */}
        <div className="field-container">
          <label htmlFor="expirationdate">Expiration (mm/yy)</label>
          <IMaskInput
            id="expirationdate"
            mask="MM/YY"
            blocks={{
              MM: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12,
              },
              YY: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 99,
              },
            }}
            value={expirationDate}
            onAccept={(value) => {
              setExpirationDate(value);
              notifyParentWithFormData();
              handleFieldChange("expirationDate", value);
            }}
            onBlur={() => handleFieldBlur("expirationDate")}
            inputMode="numeric"
          />
          {touchedFields.expirationDate && validationErrors.expirationDate && (
            <p className="error-text">*{validationErrors.expirationDate}</p>
          )}
        </div>

        <div className="field-container">
          <label htmlFor="cvv">CVV</label>

          <IMaskInput
            mask={"0000"}
            id="cvv"
            value={cvv}
            unmask={true} // true|false|'typed'
            onAccept={(value) => {
              setCvv(value);
              notifyParentWithFormData();
              handleFieldChange("cvv", value);
            }}
            onFocus={() => setCardFlipped(true)}
            onBlur={() => {
              setCardFlipped(false);
              handleFieldBlur("cvv");
            }}
          />
          {touchedFields.cvv && validationErrors.cvv && (
            <p className="error-text">*{validationErrors.cvv}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreditCardForm;
