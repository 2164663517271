import { Redirect, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// import PropTypes from "prop-types";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  // ProtectedRoute.propTypes = {
  //   component: PropTypes.object.isRequired,
  // };

  const location = useLocation();
  const user = useSelector((state) => state.user.currentUser);

  return (
    <Route {...rest}>
      {user ? (
        <Component />
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: location } }} />
      )}
    </Route>
  );
};

export default ProtectedRoute;
