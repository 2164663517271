import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userRequest } from "../api/requestMethods";

const initialState = {
  paymentStatus: "",
  status: "idle",
  error: null,
};

export const fetchPayment = createAsyncThunk(
  "/payment/fetchPayment",
  async (client_id) => {
    const response = await userRequest.get(`/momo/${client_id}`);
    return response.data;
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    resetPaymentStatus: (state) => {
      state.paymentStatus = "";
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPayment.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add any fetched listings to the array
        state.paymentStatus = action.payload;
      })
      .addCase(fetchPayment.rejected, (state, action) => {
        state.status = action.payload;
        state.error = action.error.message;
      });
  },
});

export default paymentSlice.reducer;
export const { resetPaymentStatus } = paymentSlice.actions;
