// import React from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  filterCategory,
  // getSelectedCategory
} from "../redux/categoriesRedux";
import useStyles from "../components/styles/categoriesStyle";

const Filter = ({ category }) => {
  const classes = useStyles();

  const { selectedCategory } = useSelector((state) => state.categories);

  const dispatch = useDispatch();
  return (
    <motion.div
      id={`category-${category.category}`}
      style={{
        minWidth: "10rem",
        minHeight: "2rem",
        marginLeft: "-40px",
      }}
    >
      {/* {category.category === "All" ? null : ( // <h3>All</h3> */}
      <div
        className={
          selectedCategory === category.category
            ? classes.itemActive
            : classes.itemInactive
        }
        onClick={() => {
          dispatch(filterCategory(category.category));
        }}
      >
        {category.category}
      </div>
      {/* )} */}
    </motion.div>
  );
};

export default Filter;
