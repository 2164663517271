import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./CardOtpForm.css";
import { submitCardPin_Otp } from "../../../redux/createOrderAction";
import PinOtpSteps from "./PinOtpSteps";

const CardOtpForm = () => {
  const user = useSelector((state) => state.user.currentUser);
  const { paymentCreationStatus, order } = useSelector((state) => state.orders);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  // If user is null or not authenticated, redirect to login
  useEffect(() => {
    if (!user) {
      history.push("/login");
    }
  }, [user, history]);

  useEffect(() => {
    if (paymentCreationStatus === "fulfilled" && order.redirectUrl) {
      history.push(order.redirectUrl);
    }
  }, [paymentCreationStatus, order, history]);

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(submitCardPin_Otp({ cardOtp: otp, client_id: user?.result?._id }));
  };

  // Conditionally show status inside the submit button
  let buttonContent;
  let isButtonDisabled = false;

  switch (paymentCreationStatus) {
    case "pending":
      buttonContent = "Processing...";
      isButtonDisabled = true;
      break;
    case "rejected":
      buttonContent = "Payment failed. Retry!";
      isButtonDisabled = false;
      break;
    default:
      buttonContent = "Submit";
      isButtonDisabled = otp.length !== 5;
      break;
  }

  return (
    <>
      <div className="otp-card-container">
        <div
          style={{
            width: "300px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PinOtpSteps step2={true} />
          <progress
            value={100}
            max="100"
            style={{
              width: "100%",
            }}
          ></progress>
        </div>
        <form onSubmit={handleSubmit} className="card-otp">
          <label>
            ENTER OTP:
            <input
              type="number"
              value={otp}
              onChange={handleOtpChange}
              placeholder={order?.status}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              // min="1000"
              // max="9999" //find out the allowed length from flutter
            />
          </label>
          <input
            type="submit"
            value={buttonContent}
            disabled={isButtonDisabled}
          />
        </form>
      </div>
    </>
  );
};

export default CardOtpForm;
