import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Container, Typography } from "@mui/material";
import Footer from "../components/Footer";
// import Navbar from "../components/Navbar";
import "../AppPanel.css";
import Sidebar from "../components/Sidebar/Sidebar";
// import MainDash from "../components/MainDash/MainDash";
import { useEffect } from "react";
import { fetchUserOrders } from "../redux/myOrdersRedux";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { SidebarData } from "../Sidebar/SidebarData";
import Layout from "../components/Global/Layout";

const MyProfile = () => {
  const user = useSelector((state) => state.user.currentUser);
  // console.log(user, "Wahhhhh>>>>")
  const client_id = user?.result?._id;

  let { path } = useRouteMatch();

  const dispatch = useDispatch();

  // Get User's Orders
  useEffect(() => {
    dispatch(fetchUserOrders(client_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client_id]);

  return (
    <>
      <Layout>
        {/* <Navbar /> */}
        <Container maxWidth="lg" style={{ marginTop: "10px" }}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography
                variant="h6"
                component="div"
                style={{
                  backgroundColor: "#031304b0",
                  borderRadius: "10px",
                  padding: "10px",
                  color: "white",
                }}
              >
                {user?.result?.name || user?.result?.businessName}'s Profile
              </Typography>
            </CardContent>
            {/* DASHBOARD*/}
            <div className="AppPanel">
              <div className="AppGlass">
                <Sidebar />
                <div className="mainContent">
                  <Switch>
                    <Route exact path={path}>
                      <Redirect to={`${path}/my_orders`} />
                    </Route>
                    {SidebarData.map((item, index) => (
                      <Route
                        key={index}
                        path={`${path}${item.path}`}
                        exact
                        component={item.component}
                      />
                    ))}
                  </Switch>
                </div>
                {/* <MainDash /> */}
              </div>
            </div>
          </Card>
        </Container>
      </Layout>
      <Footer />
    </>
  );
};

export default MyProfile;
