import React from "react";
// import NavBar from "../components/Navbar";
import { Divider } from '@material-ui/core';
import Layout from "../components/Global/Layout";
import airtelMoneyImage from "../assets/img/airtel_money.png";
import MtnMoMoImage from "../assets/img/momo.jpg";
import visaImage from "../assets/img/visaPng.png";
import masterCardImage from "../assets/img/mastercardPng.png";
import aramexImage from "../assets/img/amex.png";
import codImage from "../assets/img/cod.png";
import './paymentOptions.css'; // Assuming you have a CSS file for styling

const PaymentOptions = () => {
  const paymentOptions = [
    {
      name: "MTN Mobile Money",
      image: MtnMoMoImage,
      description: "Experience the convenience of MTN Mobile Money for seamless transactions."
    },
    {
      name: "Airtel Money",
      image: airtelMoneyImage,
      description: "Enjoy hassle-free payments with Airtel Money's quick transfer service."
    },
    {
      name: "Visa",
      image: visaImage,
      description: "Shop with confidence using Visa's secure and reliable payment network."
    },
    {
      name: "Master Card",
      image: masterCardImage,
      description: "MasterCard offers flexible and secure payment solutions for all your needs."
    },
    {
      name: "Aramex",
      image: aramexImage,
      description: "Aramex provides a fast, reliable, and innovative payment experience."
    },
    {
      name: "Cash On Delivery",
      image: codImage, 
      description: "Opt for Cash on Delivery for a straightforward, pay-at-your-doorstep service."
    },
  ];

  return (
    <Layout>
      <div className="payment-options-container">
        <h2 className="payment-options-title">Payment Options</h2>
        <p style={{ 
            fontSize: "14px",
            fontStyle: "italic", 
            textAlign: "center", 
            marginBottom: "30px" 
          }}>
            We accept payment through the following methods:
          </p>
          <Divider />

        <div className="payment-options-row">
          {paymentOptions.map((option) => (
            <div key={option.name} className="payment-option-circle">
              <img 
                src={option.image} 
                alt={option.name} 
                className="payment-option-image" 
              />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default PaymentOptions;
