import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Button, Tabs, Tab } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveIcon from '@mui/icons-material/Remove';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { addToCart } from '../redux/restaurantCartSlice';
import MagnifiableImage from './MagnifiableImage';

const PreferenceButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 20px;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  text-transform: none;
  font-weight: normal;
  align-items: center;
  &:hover {
    background: #f0f0f0;
  }

  @media (max-width: 480px) {
    padding: 10px 5px;
    width: 100%;
  }
`;

const PreferenceContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

// const ProductImage = styled.img`
//   width: 100%;
//   height: auto;
//   border-radius: 8px;
//   margin-bottom: 20px;

//   @media (max-width: 480px) {
//     margin-bottom: 10px;
//   }
// `;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
`;

const QuantityButton = styled(IconButton)`
  border: 1px solid #e0e0e0;
  margin: 0 10px;

  @media (max-width: 480px) {
    margin: 0 5px;
  }
`;

const AddToCartButton = styled(Button)`
  width: 100%;
  text-transform: none;
  background-color: #042940;
  color: #fff;
  &:disabled {
    background-color: #d3d3d3;
    color: #9e9e9e;
  }
  &:hover:enabled {
    background-color: #035e40;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const DialogTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow-y: auto;
  height: calc(100% - 120px);
  padding: 10px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
    height: calc(100% - 48px);
    padding: 0;
  }
`;

const LeftPane = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 20px;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 768px) {
    display: none;
  }
`;

const RightPane = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 200px;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobilePane = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
`;

const RequiredIndicator = styled.span`
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 10px;
  margin-left: 3px;
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  overflow: hidden;
`;

const PreferencesContainer = styled.div`
  width: 100%;
  text-align: left;
  overflow-y: auto;

  @media (max-width: 480px) {
    width: 90%;
  }
`;

const PriceText = styled(Typography)`
  color: green;
  text-align: left;
  width: 100%;
`;

const DescriptionContainer = styled.div`
  .ql-editor {
    padding: 0;
  }
  .ql-editor p {
    margin-bottom: 0.5em;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  padding: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px; // Adjust this value as needed
`;

const TabPanel = styled.div`
  padding: 16px;
  height: calc(100% - 48px);
  overflow-y: auto;
`;

const StyledTabs = styled(Tabs)`
  background-color: #f5f5f5;
`;

const RestaurantProductDialog = ({ open, onClose, product }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedOptionsCount, setSelectedOptionsCount] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const restaurantId = useSelector((state) => state.restaurantCart.currentRestaurant);

  const handleIncrease = () => setQuantity(quantity + 1);
  const handleDecrease = () => setQuantity(quantity > 1 ? quantity - 1 : 1);

  const handleSelectOption = (groupId, option) => {
    setSelectedOptions(prev => ({
      ...prev,
      [groupId]: [option]
    }));
    setSelectedOptionsCount(prev => ({
      ...prev,
      [groupId]: 1
    }));
  };

  const handleDeselectOption = (groupId, option) => {
    setSelectedOptions(prev => ({
      ...prev,
      [groupId]: []
    }));
    setSelectedOptionsCount(prev => ({
      ...prev,
      [groupId]: 0
    }));
  };

  const handleOptionClick = (groupId, option) => {
    const isSelected = selectedOptions[groupId]?.some(opt => opt._id === option._id);
    if (isSelected) {
      handleDeselectOption(groupId, option);
    } else {
      handleSelectOption(groupId, option);
    }
  };

  const handleAddToCart = () => {
    const selectedOptionsArray = Object.values(selectedOptions).flat();
    dispatch(addToCart({
      restaurantId,
      product: {
        ...product,
        options: selectedOptionsArray
      },
      quantity,
      options: selectedOptionsArray
    }));
    onClose();
  };

  const calculateTotalPrice = () => {
    const optionsTotal = Object.values(selectedOptions)
      .flat()
      .filter(Boolean)
      .reduce((acc, option) => acc + option.price, 0);
    return product.price * quantity + optionsTotal * quantity;
  };

  const allRequiredOptionsSelected = () => {
    return product.optionGroups.every(group => group.minOptions === 0 || (selectedOptionsCount[group._id] || 0) >= group.minOptions);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (!product) {
    return null;
  }

  const renderPreferences = () => (
    <PreferencesContainer>
      {product.optionGroups && product.optionGroups.length > 0 ? (
        <>
          <p style={{ fontSize: "15px", color: "gray", marginBottom: "4px" }}>Choose your Preference</p>
          {product.optionGroups.map(group => (
            <div key={group._id}>
              <Typography variant="subtitle2" gutterBottom>
                {group.name}
                {group.minOptions > 0 && group.options.length > 0 && (
                  <RequiredIndicator
                    style={{
                      backgroundColor: selectedOptionsCount[group._id] >= group.minOptions ? '#00b761' : '#f00',
                      color: '#fff',
                      opacity: '45%'
                    }}
                  >
                    {selectedOptionsCount[group._id] >= group.minOptions ? 'Selected✔' : 'Required*'}
                  </RequiredIndicator>
                )}
              </Typography>
              {group.options && group.options.length > 0 ? (
                group.options.map(option => (
                  option && (
                    <PreferenceButton
                      key={option._id}
                      variant="outlined"
                      endIcon={selectedOptions[group._id]?.some(opt => opt._id === option._id) ? (
                        <CheckCircleIcon style={{ color: '#00b761' }} />
                      ) : (
                        <AddIcon />
                      )}
                      onClick={() => handleOptionClick(group._id, option)}
                      disabled={group.maxOptions > 0 && selectedOptionsCount[group._id] >= group.maxOptions && !selectedOptions[group._id]?.some(opt => opt._id === option._id)}
                    >
                      <PreferenceContent>
                        <span>{option.name}</span>
                        <span style={{ color: "#00b761", marginLeft: "5px" }}>+USh.{option.price.toLocaleString()}</span>
                      </PreferenceContent>
                    </PreferenceButton>
                  )
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">No options available for this group.</Typography>
              )}
            </div>
          ))}
        </>
      ) : (
        <Typography variant="body2" color="textSecondary">No options available for this product.</Typography>
      )}
    </PreferencesContainer>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <DialogTitleWrapper>
          <Typography variant="h6">
            {product.name || 'Unnamed Product'}
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitleWrapper>
      </DialogTitle>
      <StyledDialogContent>
        <ContentWrapper>
          <MobilePane>
            <StyledTabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
              <Tab label="Description" />
              <Tab label="Options" />
            </StyledTabs>
            <TabPanel hidden={activeTab !== 0}>
              {product.image && <MagnifiableImage src={product.image} alt={product.name || 'Product Image'} zoomLevel={1.2} />}
              <PriceText variant="subtitle1" gutterBottom>
                USh.{product.price.toLocaleString()}
              </PriceText>
              <DescriptionContainer>
                <ReactQuill
                  value={product.description || 'No description available.'}
                  readOnly={true}
                  theme="bubble"
                />
              </DescriptionContainer>
            </TabPanel>
            <TabPanel hidden={activeTab !== 1}>
              {renderPreferences()}
            </TabPanel>
          </MobilePane>

          <LeftPane>
            {renderPreferences()}
          </LeftPane>

          <RightPane>
            {/* {product.image && <ProductImage src={product.image} alt={product.name || 'Product Image'} />} */}
            {product.image && <MagnifiableImage src={product.image} alt={product.name || 'Product Image'} zoomLevel={1.2} />}
            <Typography variant="subtitle1" gutterBottom>
              USh.{product.price.toLocaleString()}
            </Typography>
            <DescriptionContainer>
              <ReactQuill
                value={product.description || 'No description available.'}
                readOnly={true}
                theme="bubble"
              />
            </DescriptionContainer>
          </RightPane>
        </ContentWrapper>
        <Footer>
          <FooterContent>
            <QuantityContainer>
              <QuantityButton onClick={handleDecrease}>
                <RemoveIcon color={quantity === 1 ? 'disabled' : 'green'} />
              </QuantityButton>
              <Typography variant="body2">{quantity}</Typography>
              <QuantityButton onClick={handleIncrease}>
                <AddIcon style={{ fill: "green" }} />
              </QuantityButton>
            </QuantityContainer>
            <AddToCartButton
              onClick={handleAddToCart}
              disabled={!allRequiredOptionsSelected()}
            >
              Add {quantity} for USh. {calculateTotalPrice().toLocaleString()}
            </AddToCartButton>
          </FooterContent>
        </Footer>
      </StyledDialogContent>
    </Dialog>
  );
};

export default RestaurantProductDialog;