import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";
import { userRequest } from "../api/requestMethods";

// Create Slice for Listing

const initialState = {
  listings: [],
  promoListings: [],
  currentPromoProduct: null,
  numberOfPages: 0,
  currentPage: 1,
  isFetching: false,
  fetched: false,
  error: null,
};

export const fetchListings = createAsyncThunk(
  "listing/fetchListings",
  async (passedData) => {
    // console.log(page, "Pagerr....");
    const { page, selectedCategory } = passedData;
    const {
      data: { data, currentPage, numberOfPages },
    } = await userRequest(
      `/posts/web?page=${page}&category=${selectedCategory}`
    );
    return { data, currentPage, numberOfPages };
  }
);

// Get Promo Listings and Deals
export const fetchPromoListings = createAsyncThunk(
  "listing/fetchPromoListings",
  async () => {
    const {
      data: { data  },
    } = await userRequest("/posts/web/promo");
    return { data };
  }
);

// An asyncThunk for fetching a single promo product's details
export const fetchPromoProductDetails = createAsyncThunk(
  "listing/fetchPromoProductDetails",
  async (promoProductId, { rejectWithValue }) => {
    try {
      const response = await userRequest.get(`/posts/promo-detail/${promoProductId}`);
      return response.data;
    } catch (error) {
      // console.log(error.response.data.message, "errrrr>")
        return rejectWithValue(error.response.data.message);
    }
  }
);


// EndPoint for fetching all listings for All Products without categorization
export const fetchAllListingsNoCategories = createAsyncThunk(
  "listing/fetchAllListingsNoCategories",
  async (passedData) => {
    const { page } = passedData;
    const {
      data: { data, currentPage, numberOfPages },
    } = await userRequest(`/posts/web?page=${page}`);
    return { data, currentPage, numberOfPages };
  }
);

export const fetchListingsBySearch = createAsyncThunk(
  "listing/fetchListingsBySearch",
  async (searchQuery) => {
    const {
      data: { data },
    } = await userRequest(
      `/posts/webproduct_search?searchQuery=${searchQuery.search || "none"}`
    );

    return { data };
  }
);

const listingSlice = createSlice({
  name: "listing",
  initialState,
  reducers: {
    listingsFetched: (state, action) => {
      state.listings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchListings.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchListings.fulfilled, (state, action) => {
        state.listings = action.payload.data;
        state.numberOfPages = action.payload.numberOfPages;
        state.currentPage = action.payload.currentPage;
        state.isFetching = false;
        // state.error = null;
      })
      .addCase(fetchListings.rejected, (state) => {
        state.error = true;
        state.isFetching = false;
      })
      .addCase(fetchPromoListings.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchPromoListings.fulfilled, (state, action) => {
        state.promoListings = action.payload.data;
        state.isFetching = false;
      })
      .addCase(fetchPromoListings.rejected, (state) => {
        state.error = true;
        state.isFetching = false;
      })
      .addCase(fetchPromoProductDetails.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchPromoProductDetails.fulfilled, (state, action) => {
        state.currentPromoProduct = action.payload; 
        state.isFetching = false;
      })
      .addCase(fetchPromoProductDetails.rejected, (state, action) => {
        state.error = action.payload;
        state.isFetching = false;
      })
      .addCase(fetchAllListingsNoCategories.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchAllListingsNoCategories.fulfilled, (state, action) => {
        state.listings = action.payload.data;
        state.numberOfPages = action.payload.numberOfPages;
        state.currentPage = action.payload.currentPage;
        state.isFetching = false;
      })
      .addCase(fetchAllListingsNoCategories.rejected, (state, action) => {
        state.error = true;
        state.isFetching = false;
      })
      .addCase(fetchListingsBySearch.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchListingsBySearch.fulfilled, (state, action) => {
        state.listings = action.payload.data;
        state.numberOfPages = action.payload.numberOfPages;
        state.currentPage = action.payload.currentPage;
        state.isFetching = false;
        state.fetched = true;
        state.error = null;
      })
      .addCase(fetchListingsBySearch.rejected, (state, action) => {
        state.error = action.error;
        state.isFetching = false;
        state.fetched = true;
      });
  },
});

export const { listingsFetched } = listingSlice.actions;

export default listingSlice.reducer;

