import React from "react";
import "./Receipts.css";

const Receipts = () => {
  return (
    <div className="Receipts">
      <div className="ReceiptsContainer">
        <div className="ReceiptsLeft">
          <h2>Automated Receipt Generator</h2>
          {/* <header className="header">
            <h1 className="receiptText">Receipts</h1>
          </header> */}
          <div className="receiptRow">
            <div className="col16">
              <div className="tab">
                <p className="tabLinks">Current Receipts</p>
              </div>
            </div>
            <div className="col16">
              {/* <img
                className="receiptImage"
                src={require("../../assets/img/receipt.png")}
                height="100"
                width="100"
                alt=""
              /> */}
              <p className="noReceipt">
                There are no receipts available at the moment!
              </p>
              {/* Continue Shopping Button */}
              <div className="continueShopping">
                <button className="continueShoppingButton">
                  Continue Shopping
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Receipts;
