// Sidebar imports
import {
  UilEstate,
  UilClipboardAlt,
  UilUsersAlt,
} from "@iconscout/react-unicons";
import { UilUsdSquare, UilMoneyWithdrawal } from "@iconscout/react-unicons";
import Promotions from "../components/Promotions/Promotions";
import Receipts from "../components/Receipts/Receipts";
import UserProfile from "../components/UserProfile/UserProfile";
import UserOrders from "../components/UserOrders/UserOrders";

// Sidebar Data
export const SidebarData = [
  {
    icon: <UilEstate />,
    heading: "My Orders",
    path: "/my_orders",
    component: UserOrders,
  },
  {
    icon: <UilUsersAlt />,
    heading: "Profile",
    path: "/profile",
    component: UserProfile,
  },
  {
    icon: <UilClipboardAlt />,
    heading: "Promotions",
    path: "/promotions",
    component: Promotions,
  },
  // Receipts
  {
    icon: <UilUsersAlt />,
    heading: "Receipts",
    path: "/receipts",
    component: Receipts,
  },
];

// Analytics Cards Data
export const cardsData = [
  {
    title: "Sales",
    color: {
      backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
      boxShadow: "0px 10px 20px 0px #e0c6f5",
    },
    barValue: 70,
    value: "25,970",
    png: UilUsdSquare,
    series: [
      {
        name: "Sales",
        data: [31, 40, 28, 51, 42, 109, 100],
      },
    ],
  },
  {
    title: "Revenue",
    color: {
      backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
      boxShadow: "0px 10px 20px 0px #FDC0C7",
    },
    barValue: 80,
    value: "14,270",
    png: UilMoneyWithdrawal,
    series: [
      {
        name: "Revenue",
        data: [10, 100, 50, 70, 80, 30, 40],
      },
    ],
  },
  {
    title: "Expenses",
    color: {
      backGround:
        "linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255 202 113) -46.42%)",
      boxShadow: "0px 10px 20px 0px #F9D59B",
    },
    barValue: 60,
    value: "4,270",
    png: UilClipboardAlt,
    series: [
      {
        name: "Expenses",
        data: [10, 25, 15, 30, 12, 15, 20],
      },
    ],
  },
];
