import React from 'react';
import Navbar from '../Navbar';
import MainContent from './MainContent.js';

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <MainContent>{children}</MainContent>
    </>
  );
};

export default Layout;
