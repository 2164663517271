// redux/restaurantSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userRequest } from "../api/requestMethods";  // Assuming userRequest is set up correctly to handle API requests

const initialState = {
    restaurantData: [],
    optionGroups: [],
    isFetching: false,
    error: null,
    selectedRestaurant: null,
    details: null,
};

export const fetchRestaurants = createAsyncThunk(
    "restaurants/fetchRestaurants",
    async (_, { rejectWithValue }) => {
        try {
            const response = await userRequest.get("/restaurants");
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const fetchRestaurantDetails = createAsyncThunk(
    "restaurants/fetchRestaurantDetails",
    async (restaurantId, { rejectWithValue }) => {
        try {
            const response = await userRequest.get(`/restaurants/menu-sections?vendorId=${restaurantId}`);
            return { restaurantId, sections: response.data, optionGroups: response.data.optionGroups };
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const restaurantSlice = createSlice({
    name: "restaurants",
    initialState,
    reducers: {
        resetOptionGroups: (state) => {
            state.optionGroups = [];
        },
        setSelectedRestaurant: (state, action) => {
            state.selectedRestaurant = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRestaurants.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchRestaurants.fulfilled, (state, action) => {
                state.restaurantData = action.payload.restaurants;
                state.isFetching = false;
                state.error = null;
            })
            .addCase(fetchRestaurants.rejected, (state, action) => {
                state.error = action.payload;
                state.isFetching = false;
            })
            .addCase(fetchRestaurantDetails.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchRestaurantDetails.fulfilled, (state, action) => {
                const { restaurantId, sections, optionGroups } = action.payload;
                state.details = { restaurantId, sections, optionGroups };
                state.isFetching = false;
                state.error = null;
            })
            .addCase(fetchRestaurantDetails.rejected, (state, action) => {
                state.error = action.payload;
                state.isFetching = false;
            });
    },
});

export const { resetOptionGroups, setSelectedRestaurant } = restaurantSlice.actions;
export default restaurantSlice.reducer;
