import { configureStore, combineReducers } from "@reduxjs/toolkit";
import cartReducer from "./cartRedux";
import userReducer from "./userRedux";
import listingReducer from "./listingRedux";
import categoriesReducer from "./categoriesRedux";
import orderReducer from "./createOrderAction";
import paymentReducer from "./paymentRedux";
import shippingCostReducer from "./shippingCostRedux";
import myOrdersReducer from "./myOrdersRedux";
import sseReducer from "../redux/cardSseRedux";
import airtelSseReducer from "../redux/airtelSseRedux";
import restaurantReducer from "./restaurantSlice.js";
import restaurantCartReducer from "../redux/restaurantCartSlice";
import restaurantOrderReducer from "../redux/restaurantOrderActions";
import promotedVendorsReducer from "../redux/promotedVendorsSlice";
import wholesaleRetailVendorReducer from "../redux/wholesaleRetailVendorSlice";
import retailerWholesalerCartReducer from "../redux/retailerWholesalerCartSlice";
import retailerWholesalerOrderReducer from "../redux/retailerWholesalerOrderActions";

import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  // persistReducer,
} from "redux-persist";

const rootReducer = combineReducers({
  user: userReducer, // This is already a persisted reducer
  cart: cartReducer,
  payment: paymentReducer,
  products: listingReducer,
  categories: categoriesReducer,
  orders: orderReducer,
  userOrders: myOrdersReducer,
  shippingCosts: shippingCostReducer,
  // registerLoader: loadingReducer,
  cardSse: sseReducer, //cardSse data needs to persist a refresh
  airtelSse: airtelSseReducer, //airtelSse data needs to persist a refresh
  restaurants: restaurantReducer,
  restaurantCart: restaurantCartReducer,
  restaurantOrder: restaurantOrderReducer,
  promotedVendors: promotedVendorsReducer,
  wholesaleRetailVendor: wholesaleRetailVendorReducer,
  retailerWholesalerCart: retailerWholesalerCartReducer,
  retailerWholesalerOrder: retailerWholesalerOrderReducer,
});

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);
