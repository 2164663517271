import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  login_nav_container: {
    // height: "75px",
    maxHeight: "60px",
    padding: 0,
  },
  login_container: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    width: "100%",
    height: "75vh",
    alignItems: "center",
    flexDirection: "column",
  },
  login_form_container: {
    position: "relative",
    display: "flex",
    borderRadius: "10px",
    width: "320px",
    alignItems: "center",
    flexDirection: "column",
    padding: "20px",
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
  },
  logo_signin: {
    width: "115px",
    height: "115px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  input: {
    width: "92%",
    height: "40px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    margin: "0 auto 10px",
    padding: "0 10px",
    backgroundColor: "#edf5f3",
  },
  login_button: {
    backgroundColor: "#f50057",
    color: "#fff",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: "10px",
    width: "100%",
  },
  google_sign_in_button: {
    marginTop: "7px",
    width: "100%",
  },
  password_reset_field: {
    outline: "none",
    borderColor: "#3bb19b",
    borderWidth: "1px",
    width: "90%",
    padding: "15px",
    marginBottom: "10px",
    borderRadius: "10px",
    backgroundColor: "#edf5f3",
    margin: "5px 0",
    fontSize: "14px",
  },
  password_reset_field_error: {
    width: "92%",
    padding: "10px",
    margin: "5px 0",
    backgroundColor: "#f34646",
    color: "white",
    borderRadius: "5px",
    fontSize: "12px",
    textAlign: "center",
    fontStyle: "italic",
    fontWeight: "100",
    marginTop: "-10px",
  },
  reset_button: {
    backgroundColor: "#017109",
    color: "#fff",
    border: "none",
    padding: "15px",
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: "10px",
    width: "96%",
    textTransform: "uppercase",
  },
  loadingText: {
    fontSize: "0.7em",
    fontStyle: "italic",
    color: "#0B2559",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  googleSignInLoadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  login_loader: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.7)", // set the background color to white with 70% opacity
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
});

export default useStyles;
