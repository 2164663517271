import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Chip from "@mui/material/Chip";
import ShareIcon from "@material-ui/icons/Share";
import { Chip, IconButton, Tabs, Tab } from "@mui/material";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import parse from 'html-react-parser';

import facebookIcon from "../assets/img/facebook.png";
import twitterIcon from "../assets/img/twitterIcon.png";
import linkedInIcon from "../assets/img/linkedIn.png";

import { publicRequest } from "../api/requestMethods";
import { addToCart } from "../redux/cartRedux";
import Announcement from "../components/Announcement";
import Navbar from "../components/Navbar";
import { mobile } from "../responsive";
import useStyles from "../components/styles/listingStyle.js";

// const Container = styled.div``;

const DetailsContainer = styled.div`
  max-width: 1200px;
  margin: 20px auto;
  padding: 0 20px;
`;

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  ${mobile({ padding: "10px", flexDirection: "column" })}
`;

const ImgContainer = styled.div`
  flex: 1;
`;

const Image = styled.img`
  width: 90%;
  height: 60vh;
  object-fit: cover;
  border-radius: 10px;
  ${mobile({ height: "40vh" })}
`;

const InfoContainer = styled.div`
  flex: 1;
  padding: 0px;
  ${mobile({ padding: "10px" })}
`;

const Title = styled.h1`
  font-weight: 200;
  font-size: 20px;
`;

const Desc = styled.p`
  margin: 15px 0px;
  font-size: 14px;
`;

const Price = styled.span`
  font-weight: 100;
  font-size: 24px;
`;

const AddContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  ${mobile({ width: "100%" })}
`;

const AddButton = styled.button`
  padding: 15px;
  border: none;
  background-color: #135d69;
  color: white;
  cursor: pointer;
  font-weight: 500;
  border-radius: 10px;

  &:hover {
    background-color: #466316;
    border: none;
  }
`;
const TabContentContainer = styled.div`
  min-height: 200px;
  max-height: 200px; 
  overflow-y: auto; 
  padding: 10px;
`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Product = () => {
  const cart = useSelector((state) => state.cart.products);
  // const user = useSelector((state) => state.user.currentUser);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [product, setProduct] = useState({});

  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [activeTab, setActiveTab] = useState('description');
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    const getProduct = async () => {
      try {
        const res = await publicRequest.get(`/posts/listing/${id}`);
        setProduct(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getProduct();
  }, [id]);

  const addToCartHandler = (item) => {
    dispatch(
      addToCart({
        item,
      })
    );
  };

  const isInCart = (prod) => {
    if (cart?.filter((item) => item?._id === prod?._id)?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  // Soc Share
  const handleShareDialogOpen = () => {
    setShareDialogOpen(true);
  };

  const handleShareDialogClose = () => {
    setShareDialogOpen(false);
  };

  const shareOnSocialMedia = (platform) => {
    const productLink = `https://lusuku.ug/product/${id}`;

    let newWindow;

    switch (platform) {
      case "facebook":
        // Share on Facebook
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          productLink
        )}`;
        newWindow = window.open(
          facebookShareUrl,
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "twitter":
        // Share on Twitter
        const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          productLink
        )}`;
        newWindow = window.open(
          twitterShareUrl,
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "linkedin":
        // Share on LinkedIn
        const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          productLink
        )}`;
        newWindow = window.open(
          linkedinShareUrl,
          "_blank",
          "noopener,noreferrer"
        );
        break;
      // Add more cases for other social media platforms
      default:
        break;
    }

    if (!newWindow) {
      alert(
        "A popup blocker may have prevented the share window from opening. You can disable the popup blocker and try again."
      );
    }

    // Close the share dialog
    handleShareDialogClose();
  };

  const copyProductLink = () => {
    const productLink = `https://lusuku.ug/product/${id}`;
    if (navigator.clipboard) {
      navigator.clipboard.writeText(productLink).then(
        () => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
        },
        () => {
          console.error("Failed to copy product link to clipboard.");
        }
      );
    } else {
      // Fallback or show warning message.
      window.alert(
        "Sorry, your browser does not support copying. Please copy the link manually."
      );
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setCopied(false);
  };

    // Function to handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Conditionally check if the description contains HTML tags as old records are plain strings
  function createMarkup(htmlContent) {
    return { __html: htmlContent };
  }
  
  return (
    <>
      <Navbar />
      <DetailsContainer>
        <div
          style={{
            padding: "20px",
          }}
        >
          <Announcement />
        </div>
        <Wrapper>
          <ImgContainer>
            <Image src={product?.product_image} />
          </ImgContainer>
          <InfoContainer>
            <Title>{product?.title}</Title>
            <Desc>
              {" "}
              {product?.measurements} {product?.unit_id?.unit}
            </Desc>
            {/* <Desc>Description: {product?.description}</Desc> */}
            <Price>
              Shs{" "}
              {product?.price
                ?.toString()
                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Price>
                 
            {isInCart(product) ? (
              <Chip
                label="In Cart"
                color="success"
                // className={classes.inCartChip}
                size="small"
                style={{ marginRight: "10px" }}
                // onClick={() => removeFromCartHandler(listing._id)}
              />
            ) : (
              <>
                <AddContainer>
                  <AddButton
                    // className={classes.addToCartButton}
                    onClick={() => addToCartHandler(product)}
                  >
                    ADD TO CART
                  </AddButton>
                </AddContainer>
                <Tooltip title="Share">
                  <IconButton
                    style={{ margin: "5px" }}
                    onClick={handleShareDialogOpen}
                  >
                    <ShareIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
               
                <Dialog
                  open={shareDialogOpen}
                  onClose={handleShareDialogClose}
                  aria-labelledby="share-dialog-title"
                >
                  <DialogTitle id="share-dialog-title">
                    Share Product
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Share this product with others:
                    </DialogContentText>
                    <Snackbar
                      open={copied}
                      autoHideDuration={6000}
                      onClose={handleClose}
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                      <Alert onClose={handleClose} severity="success">
                        Product link copied to clipboard.
                      </Alert>
                    </Snackbar>
                    <Button
                      className={classes.shareButton}
                      startIcon={
                        <img
                          src={facebookIcon}
                          // src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
                          alt="Facebook"
                          style={{
                            width: "24px",
                            height: "24px",
                            objectFit: "contain",
                          }}
                        />
                      }
                      onClick={() => shareOnSocialMedia("facebook")}
                    >
                      Facebook
                    </Button>
                    <Button
                      className={classes.shareButton}
                      startIcon={
                        <img
                          src={twitterIcon}
                          alt="Twitter"
                          style={{
                            width: "21px",
                            height: "21px",
                            objectFit: "contain",
                          }}
                        />
                      }
                      onClick={() => shareOnSocialMedia("twitter")}
                    >
                      Twitter
                    </Button>
                    <Button
                      className={classes.shareButton}
                      startIcon={
                        <img
                          src={linkedInIcon}
                          alt="Linkedin"
                          style={{
                            width: "23px",
                            height: "23px",
                            objectFit: "contain",
                          }}
                        />
                      }
                      onClick={() => shareOnSocialMedia("linkedin")}
                    >
                      LinkedIn
                    </Button>
                    {/* Add more social media share buttons as needed */}
                    <Button
                      className={classes.shareButton}
                      startIcon={<FileCopyIcon />}
                      onClick={copyProductLink}
                    >
                      Copy Link
                    </Button>
                  </DialogContent>
                </Dialog>
              </>
            )}
          {/* Tabs right below the Share button */}
          <div className={classes.tabsWrapper}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              // textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              classes={{ 
                root: classes.tabRoot, 
                indicator: classes.tabsIndicator 
              }}
            >
              <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="Description" value="description" />
              <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="Specification" value="specification" />
              <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="Reviews" value="reviews" />
              <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="Shipping Policy" value="shippingReturns" />
              <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }} label="Offers & Discounts" value="moreOffers" />
            </Tabs>
            {/* Conditional rendering based on the active tab */}
            <div className={classes.tabContent}>
            <TabContentContainer>
            {activeTab === 'description' && (
              <div dangerouslySetInnerHTML={createMarkup(product.description)} />
            )}
            {activeTab === 'specification' && <p style={{fontStyle: "italic"}}>No product specifications!</p>}
            {activeTab === 'reviews' && <p style={{fontStyle: "italic"}}>This product has not been reviewed yet!</p>}
            {activeTab === 'shippingReturns' && <div className={classes.tabContent}>{parse(product.returnPolicy)}</div>}
            {activeTab === 'moreOffers' && <p style={{fontStyle: "italic"}}>This product has no offers currently!</p>}
            </TabContentContainer>
            </div>
          </div>
          </InfoContainer>
        </Wrapper>
      </DetailsContainer>
    </>
  );
};

export default Product;
