import { useEffect, useState } from "react";
import styled from "styled-components";
// import { popularProducts } from "../data";
import Product from "./Product";
// import axios from "axios";
import { fetchListings } from "../redux/listingRedux";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Products = ({ cat, filters, sort }) => {
  Products.propTypes = {
    cat: PropTypes.string,
    filters: PropTypes.object,
    sort: PropTypes.string,
  };

  const [products] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const dispatch = useDispatch();

  // console.log(products, "??????");
  // console.log(products.slice(0, 3), "products");
  // useEffect(() => {
  //   const getProducts = async () => {
  //     try {
  //       const res = await axios.get(
  //         cat
  //           ? // ? `http://localhost:5000/api/products?category=${cat}`
  //             // : "http://localhost:5000/api/products"
  //             `http://localhost:8000/posts?category=${cat}`
  //           : "http://localhost:8000/posts/all-items"
  //       );
  //       setProducts(res.data.data);
  //     } catch (err) {}
  //   };
  //   getProducts();
  // }, [cat]);

  useEffect(() => {
    // if (!listings?.length > 0) {
    dispatch(fetchListings());
    // }
  }, [dispatch]);

  useEffect(() => {
    cat &&
      setFilteredProducts(
        products.filter((item) =>
          Object.entries(filters).every(([key, value]) =>
            item[key].includes(value)
          )
        )
      );
  }, [products, cat, filters]);

  useEffect(() => {
    if (sort === "newest") {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.createdAt - b.createdAt)
      );
    } else if (sort === "asc") {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.price - b.price)
      );
    } else {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => b.price - a.price)
      );
    }
  }, [sort]);

  return (
    <Container>
      {cat
        ? filteredProducts.map((item) => <Product item={item} key={item.id} />)
        : products
            .slice(0, 8)
            .map((item) => <Product item={item} key={item._id} />)}
    </Container>
  );
};

export default Products;
