import React, { useEffect, useState } from "react";
import "./CardPinForm.css";
import { submitCardPin_Otp } from "../../../redux/createOrderAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PinOtpSteps from "./PinOtpSteps";

const CardPinForm = () => {
  const user = useSelector((state) => state.user.currentUser);
  const { paymentCreationStatus, order } = useSelector((state) => state.orders);

  const [pin, setPin] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  // If user is null or not authenticated, redirect to login
  useEffect(() => {
    if (!user) {
      history.push("/login");
    }
  }, [user, history]);

  useEffect(() => {
    if (paymentCreationStatus === "fulfilled" && order.redirectUrl) {
      //  window.location.href = order.redirectUrl; // This assumes the redirect URL is an external URL
      // If it's an internal route, use history.push(order.redirectUrl);
      history.push(order.redirectUrl);
    }
  }, [paymentCreationStatus, order, history]);

  const handlePinChange = (event) => {
    setPin(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(submitCardPin_Otp({ cardPin: pin, client_id: user?.result?._id }));
  };

  // Conditionally show status inside the submit button
  let buttonContent;
  let isButtonDisabled = false;

  switch (paymentCreationStatus) {
    case "pending":
      buttonContent = "Processing...";
      isButtonDisabled = true;
      break;
    case "fulfilled":
      buttonContent = "Payment Successful";
      isButtonDisabled = true;
      break;
    case "rejected":
      buttonContent = "Payment failed. Retry!";
      isButtonDisabled = false;
      break;
    default:
      buttonContent = "Submit";
      isButtonDisabled = pin.length !== 4;
      break;
  }

  return (
    <>
      {/* <Navbar /> */}
      <div className="card-container">
        <div
          // instyle for progress bar and steps
          style={{
            width: "300px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PinOtpSteps step1={true} />
          <progress
            value={50}
            max="100"
            style={{
              width: "100%",
            }}
          ></progress>
        </div>
        <form onSubmit={handleSubmit} className="card">
          <label>
            ENTER PIN:
            <input
              type="number"
              value={pin}
              onChange={handlePinChange}
              min="1000"
              max="9999"
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
            />
          </label>
          <input
            type="submit"
            value={buttonContent}
            disabled={isButtonDisabled}
          />
        </form>
      </div>
    </>
  );
};

export default CardPinForm;
