import React, { useEffect } from "react";
import { Pagination } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllListingsNoCategories,
  fetchListings,
} from "../redux/listingRedux";

import PropTypes from "prop-types";

const Paginate = ({ page, isFetching }) => {
  const { numberOfPages, currentPage } = useSelector((state) => state.products);
  const { selectedCategory } = useSelector((state) => state.categories);

  Pagination.propTypes = {
    selectedCategory: PropTypes.string,
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const abortController = new AbortController();

  useEffect(() => {
    if (!selectedCategory) {
      dispatch(fetchAllListingsNoCategories({ page }));
    } else {
      if (numberOfPages < currentPage) {
        dispatch(fetchListings({ page, selectedCategory }));
        history.push(`/shop?page=1`);
      } else if (selectedCategory === "All") {
        dispatch(fetchAllListingsNoCategories({ page }));
        history.push(`/shop?page=${page}`);
      } else if (selectedCategory) {
        dispatch(fetchListings({ page, selectedCategory }));
        history.push(`/shop?page=${page}`);
      } else {
        dispatch(fetchAllListingsNoCategories({ page }));
      }

      return () => {
        abortController.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, page, numberOfPages]);

  const handleChange = (event, value) => {
    history.push(`/shop?page=${value}`);
  };

  return (
    <Pagination
      count={numberOfPages}
      color="secondary"
      xs={2}
      variant="outlined"
      shape="rounded"
      style={{
        margin: "0 auto",
        display: isFetching ? "none" : "flex",

        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        paddingBottom: "1rem",
      }}
      page={Number(page) || 1}
      onChange={handleChange}
    />
  );
};

export default Paginate;
