import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  media: {
    height: 0,
    paddingTop: "80%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    // backgroundBlendMode: 'darken',
  },
  mediaOuttaStock: {
    height: 0,
    paddingTop: "80%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backgroundBlendMode: "darken",
  },
  pOuttaStock: {
    justifyContent: "center",
    textAlign: "center",
    // color: " rgba(254, 240, 246, 0.8)",
    color: "#D0DDF3",
    fontSize: "1.5rem",
    fontWeight: "bold",
    transform: "rotate(-22deg) skew( -15deg)",
    paddingBottom: "30px",
  },

  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "15px",
    height: "100%",
    position: "relative",
  },
  inCartChip: {
    color: "#037D30",
  },
  moreGrey: {
    color: "#868686",
  },
  overlay2: {
    position: "absolute",
    top: "5px",
    right: "-6px",
    color: "white",
  },
  grid: {
    display: "flex",
  },
  details: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px",
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "left",
    padding: "5 5px",
    paddingBottom: "5px",
  },

  cardActions: {
    padding: "0 16px 8px 16px",
    display: "flex",
    justifyContent: "space-between",
  },
  cardAction: {
    display: "block",
    textAlign: "initial",
  },
  shareButton: {
    padding: "15px",
    borderColor: "#00bcd4",
    borderRadius: "10px",
    backgroundColor: "white",
    cursor: "pointer",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  tabRoot: {
    borderBottom: '1px solid #e8e8e8',
    '&:hover': {
      // color: '#40a9ff',
      color: '#008F8C',
      opacity: 1,
    },
    // '&$tabSelected': {
    //   color: '#1890ff',
    //   fontWeight: theme.typography.fontWeightMedium,
    // },
    '&:focus': {
      // color: '#40a9ff',
      color: '#008F9C',
    },
    '&$tabSelected': {
      color: '#000000',
      fontWeight: "bold",
      borderBottom: '2px solid #ff0000', // red bottom border for selected tab
    },
  },
  tabContent: {
    padding: theme.spacing(2),
    maxWidth: '800px',
    margin: "auto",
    color: "#333333",
    fontFamily: '"Inter", sans-serif',
    fontSize: '14px',
    fontWeight: 300,
    fontStyle: 'normal',
    lineHeight: "1.5",
    boxShadow: "0 6px 12px 0 rgba(0,0,0,0.3)",
    borderBottomLeftRadius: '15px', // Rounded bottom left corner
    borderBottomRightRadius: '15px',
  },
  tabSelected: {},
  tabsIndicator: {
    // backgroundColor: '#1890ff',
    backgroundColor: 'transparent',
  },
  tabsWrapper: { 
    borderWidth: '0.2px',
    borderStyle: 'solid',
    borderColor: '#868686',
    borderRadius: '15px', // Rounded corners on the top
    borderBottomLeftRadius: '15px', // Rounded bottom left corner
    borderBottomRightRadius: '15px',
    borderBottom: 'none', // Remove the bottom border to blend with the indicator
    minWidth: "220px"
  },
}));
