import { useState } from "react";
import { Image } from "react-bootstrap";
import { toast } from "react-toastify";
import { Alert, AlertTitle } from "@material-ui/lab";
import AppBar from "@material-ui/core/AppBar";
// import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "../../assets/img/signin-logo.png";
import { useParams, useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

import CustomAppBar from "./AppBar";
import useStyles from "./loginStyle.js";

const WebClientPasswordResetForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const { userId } = useParams();

  const [resetStatus, setResetStatus] = useState("");
  const [success, setSuccess] = useState(false);

  const [setInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const clearInput = () => {
    setInput({
      password: "",
      confirmPassword: "",
    });
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/user/reset-password/${userId}`, //ProdEnv & DevEnv Ngrok

        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            password: values.password,
          }),
        }
      );
      const data = await response.json();
      setResetStatus(data.message);
      // toast.info(data.message);
      if (data.success) {
        // alert("Password reset successfully");
        toast.success("Password reset successfully!");
        setSuccess(true);
        history.replace("/pwdResetForm/:token/:userId");
        setSubmitting(false);
      } else {
        // alert("Password reset failed");
        toast.info(data.message);
        history.replace("/pwdResetForm/:token/:userId");
        clearInput();
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (success) {
    return (
      <>
        <CustomAppBar />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "40vh",

            backgroundColor: "#f5f5f5",
          }}
        >
          <h4
            style={{
              alignSelf: "center",
              textAlign: "center",
              fontWeight: "bold",
              padding: "7px",
            }}
          >
            Password Reset Successful!
          </h4>
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            <p>{resetStatus} You can now login with your new password</p>
          </Alert>
          <div>
            <Link
              style={{
                textDecoration: "none",
              }}
              to="/login"
            >
              <Button
                variant="contained"
                size="large"
                style={{
                  backgroundColor: "#004c13",
                  color: "#ffff",
                  fontWeight: "bold",
                  marginTop: "10px",
                  width: "300px",
                  height: "50px",
                  borderRadius: "5px",
                  border: "1px solid #004c13",
                }}
              >
                Proceed to Login
              </Button>
            </Link>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        <AppBar position="static" color="inherit">
          <Toolbar>
            <Link to="/">
              <Image
                src={logo}
                alt="logo"
                style={{
                  width: "125px",
                  height: "120px",
                  objectFit: "contain",
                }}
              />
            </Link>
          </Toolbar>
        </AppBar>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: "30px",
          // height: "10vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ margin: "10px 10px" }}>
          <div>
            <div>
              <div>
                <h1
                  style={{
                    alignSelf: "center",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "7px",
                  }}
                >
                  Reset Your Password!
                </h1>
                <Formik
                  initialValues={{
                    password: "",
                    confirmPassword: "",
                  }}
                  validationSchema={Yup.object({
                    password: Yup.string()
                      .required("Password is required!")
                      .min(8, "Password must be between 8 and 20 characters.")
                      .max(20, "Password must be between 8 and 20 characters.")
                      .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        "Password must contain at least one uppercase, one lowercase, one number and one special character."
                      ),
                    confirmPassword: Yup.string()
                      .required("Confirm Password is required*")
                      .oneOf(
                        [Yup.ref("password"), null],
                        "Passwords must match!"
                      ),
                  })}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,

                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <Field
                          type="password"
                          name="password"
                          className={classes.password_reset_field}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />

                        <ErrorMessage
                          name="password"
                          component="div"
                          // className="invalid-feedback"
                          className={classes.password_reset_field_error}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                        <Field
                          type="password"
                          name="confirmPassword"
                          className={classes.password_reset_field}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                        />

                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className={classes.password_reset_field_error}
                        />
                      </div>
                      <div className="form-group">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                        >
                          Reset Password
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebClientPasswordResetForm;
