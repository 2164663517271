import {
  Divider,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetCart } from "../redux/cartRedux";
import { resetOrderCreation } from "../redux/restaurantOrderActions"; // Adjust the import path if needed
import { resetShippingCost } from "../redux/shippingCostRedux";
import Layout from "../components/Global/Layout";

const CODOrderScreen = () => {
  const user = useSelector((state) => state.user.currentUser);
  const userId = user?.result?._id;

  // Access the order from restaurantOrder state
  // const { order } = useSelector((state) => state.orders);

  const { order } = useSelector((state) => state.restaurantOrder);
  const { shippingCost } = useSelector((state) => state.shippingCosts);

  const serverShippingFees = shippingCost.map((cost) => cost.cost);

  const orderTotal_ShippingFees =
    Number(order.itemsPrice) + Number(serverShippingFees);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleContinueShopping = () => {
    dispatch(resetCart());
    dispatch(resetOrderCreation());
    dispatch(resetShippingCost());
    setTrackOrder({});
    history.push("/");
  };

  // Steps
  const steps = [
    "Order Placed",
    "Order Confirmed",
    "Order Dispatched",
    "Order Delivered",
    "Order Cancelled",
  ];

  // Active Step
  const [activeStep, setActiveStep] = useState();

  // getStepContent
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return "Your order has been placed successfully!";
      case 1:
        return "Your order has been confirmed!";
      case 2:
        return "Your order has been dispatched!";
      case 3:
        return "Your order has been delivered 🚚🥳!";
      default:
        return "Order Cancelled 🚫";
    }
  };

  const [trackOrder, setTrackOrder] = useState();

  // Track Order Status with EventSource
  useEffect(() => {
    if (trackOrder?.delivery_status === undefined) {
      const es = new EventSource(
        `${process.env.REACT_APP_SERVER_URL}/mobile/${userId}/stream`
      ); //DevEnv

      es.addEventListener("open", () => {
        console.log("Verifying connection");
      });

      es.addEventListener("message", (event) => {
        const data = JSON.parse(event.data);
        setTrackOrder(data);
      });

      return () => {
        es.close();
      };
    } else {
      const es = new EventSource(
        `${process.env.REACT_APP_SERVER_URL}/mobile/${userId}/stream` //ProdEnv & DevEnv
      );
      es.addEventListener("open", (event) => {
        console.log("Opened - SSE connection.");
      });

      es.addEventListener("message", (event) => {
        const data = JSON.parse(event.data);
        setTrackOrder(data);
      });
      return () => {
        es.close();
      };
    }
  }, [trackOrder, user, userId]);

  // Handle Steps Change with useEffect
  useEffect(() => {
    if (trackOrder) {
      const { delivery_status } = trackOrder;
      if (delivery_status === "PROCESSING") {
        setActiveStep(0);
      } else if (delivery_status === "CONFIRMED") {
        setActiveStep(1);
      } else if (delivery_status === "DISPATCHED") {
        setActiveStep(2);
      } else if (delivery_status === "DELIVERED") {
        setActiveStep(3);
      } else if (delivery_status === "REJECTED") {
        setActiveStep(4);
      } else {
        setActiveStep(4);
      }
    }
  }, [trackOrder]);

  return (
    <Layout>
      <Container className="order-summary-container">
        <div>
          <h2 style={{ fontWeight: "200", marginBottom: "14px" }}>
            Your Cash On Delivery Order Summary!
          </h2>
        </div>

        <div>
          <Typography
            component={"span"}
            style={{
              fontWeight: "200",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            Thank you for Shopping On Lusuku. Your order Number{" "}
            <span
              style={{
                color: "#f50057",
              }}
            >
              {order._id}.
            </span>
          </Typography>
        </div>

        <Divider
          style={{
            margin: "20px 0px",
            backgroundColor: "lightgray",
          }}
        />
        <div>
          <Typography
            component="h2"
            variant="h6"
            color="primary"
            gutterBottom
            style={{ fontWeight: "200" }}
          >
            Ordered Items
          </Typography>
          <div
            style={{
              width: "100%",
              overflowX: "auto",
              margin: "1rem 0",
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "lightgray",
                  }}
                >
                  <TableCell
                    style={{
                      width: "130px",
                    }}
                  >
                    <b>PRODUCT IMAGE</b>
                  </TableCell>
                  <TableCell>
                    <b>NAME</b>
                  </TableCell>
                  <TableCell>
                    <b>QUANTITY</b>
                  </TableCell>
                  <TableCell>
                    <b>PRICE</b>
                  </TableCell>
                  <TableCell>
                    <b>SUM</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order?.orderItems?.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell>
                      <img
                        src={item.image}
                        alt={item.image}
                        style={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "10%",
                        }}
                      />
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.qty}</TableCell>
                    <TableCell>
                      {item.price
                        ?.toString()
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </TableCell>
                    <TableCell>{item.qty * item.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div>
              <div>
                {/*MoMo Transaction ID */}
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: "200", paddingTop: "10px" }}
                >
                  Payment Method:{" "}
                  <span
                    style={{
                      fontSize: "11px",
                      fontStyle: "italic",
                      color: "#003e64",
                    }}
                  >
                    {order?.paymentMethod}
                  </span>
                </Typography>
                {/* Delivery Status */}
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: "200", paddingTop: "10px" }}
                >
                  Delivery Status:{" "}
                  <span
                    style={{
                      fontSize: "11px",
                      fontStyle: "italic",
                      color: "#003e64",
                    }}
                  >
                    {order?.delivery_status}
                  </span>
                </Typography>
                {/* Delivery Address */}
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: "200", paddingTop: "10px" }}
                >
                  Delivery Address:{" "}
                  <span
                    style={{
                      fontSize: "11px",
                      fontStyle: "italic",
                      color: "#003e64",
                    }}
                  >
                    {order?.shippingAddress?.locationName?.name},{" "}
                    {order?.shippingAddress?.locationName?.vicinity}
                  </span>
                </Typography>
                {/* Delivery Contact Mobile Number */}
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: "200", paddingTop: "10px" }}
                >
                  Contact Mobile:{" "}
                  <span
                    style={{
                      fontSize: "11px",
                      fontStyle: "italic",
                      color: "#003e64",
                    }}
                  >
                    {order?.shippingAddress?.customerNumber}{" "}
                  </span>
                </Typography>
                {/* Order Total Cost */}
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontWeight: "200", paddingTop: "10px" }}
                >
                  Your order cost is:{" "}
                  <span
                    style={{
                      fontSize: "12px",
                      fontStyle: "italic",
                      color: "#0074a6",
                    }}
                  >
                    Shs {""}
                    {orderTotal_ShippingFees
                      ?.toString()
                      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    /=
                  </span>
                </Typography>
                {/* TRACKING ORDER HEADER */}
                <Typography
                  variant="h6"
                  color="textSecondary"
                  style={{
                    fontWeight: "200",
                    paddingTop: "10px",
                    margin: "1rem",
                  }}
                >
                  <b>Tracking Order</b>
                  {/* Divider */}
                  <Divider
                    style={{
                      width: "100%",
                      backgroundColor: "lightgray",
                    }}
                  />
                </Typography>
                {/* STEPS WIDGET */}
                <div
                  style={{
                    width: "100%",
                    margin: "1rem 0",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Stepper
                    activeStep={activeStep}
                    orientation="vertical"
                    style={{
                      width: "100%",
                      backgroundColor: "transparent",
                    }}
                  >
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel
                          style={{
                            color: "black",
                            fontWeight: "200",
                          }}
                        >
                          {index + 1}.{label}
                        </StepLabel>
                        <StepContent>
                          <Typography
                            style={{
                              color: "black",
                              fontWeight: "200",
                            }}
                          >
                            {getStepContent(index)}
                          </Typography>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </div>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{
                  width: "100%",
                  backgroundColor: "black",
                  color: "white",
                  fontWeight: "600",
                  margin: "10px 0px 15px 0px",
                }}
                onClick={handleContinueShopping}
              >
                Continue Shopping
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default CODOrderScreen;
