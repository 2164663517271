// import axios from "axios";
import { userRequest } from "../api/requestMethods.js";
import {
  fetchDeliveryAddressError,
  fetchDeliveryAddressLoading,
  fetchDeliveryAddressSuccess,
} from "./cartRedux";

export const getDeliveryZones = () => async (dispatch) => {
  dispatch(fetchDeliveryAddressLoading());
  try {
    // const res = await axios.get(`${API_URL}/delivery-zones`);
    const res = await userRequest("/deliveryzone");
    dispatch(fetchDeliveryAddressSuccess(res.data));
  } catch (err) {
    dispatch(fetchDeliveryAddressError(err));
  }
};
