import { Add, Remove } from "@material-ui/icons";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Announcement from "../components/Announcement";
// import Footer from "../components/Footer";
// import Navbar from "../components/Navbar";
import {
  decrementCartItem,
  incrementCartItem,
  removeItemFromCart,
} from "../redux/cartRedux";
import { mobile } from "../responsive";
import { useHistory } from "react-router-dom";
import Layout from "../components/Global/Layout";

// import StripeCheckout from "react-stripe-checkout";
// import { useEffect, useState } from "react";
// import { userRequest } from "../requestMethods";
// import { useHistory } from "react-router";
// import { useNavigate } from "react-router-dom";

// const KEY = process.env.REACT_APP_STRIPE;

const Container = styled.div`
  // display: flex;
  ${mobile({ padding: "10px 0px" })}
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
`;
const Amount = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 10px;
  border: 1px solid teal;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
`;
const Wrapper = styled.div`
  padding: 20px;
  ${mobile({ padding: "10px" })}
`;

const Title = styled.h1`
  font-weight: 300;
  text-align: center;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

const TopButton = styled.button`
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
  border: ${(props) => props.type === "filled" && "none"};
  background-color: ${(props) =>
    props.type === "filled" ? "black" : "transparent"};
  color: ${(props) => props.type === "filled" && "white"};
`;

const TopTexts = styled.div`
  ${mobile({ display: "none" })}
`;
// const TopText = styled.span`
//   text-decoration: underline;
//   cursor: pointer;
//   margin: 0px 10px;
// `;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobile({ flexDirection: "column" })}
`;

const Info = styled.div`
  flex: 3;
  margin: 0px 20px;
`;

const Product = styled.div`
  display: flex;
  justify-content: space-between;
  ${mobile({ flexDirection: "column" })}
`;

const ProductDetail = styled.div`
  flex: 2;
  display: flex;
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 20%;
  margin: 0px 10px 10px 20px;
  ${mobile({ width: "100px", height: "100px" })}
`;

const Details = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const ProductName = styled.span``;

const ProductId = styled.span``;

// const ProductColor = styled.div`
//   width: 20px;
//   height: 20px;
//   border-radius: 50%;
//   background-color: ${(props) => props.color};
// `;

// const ProductSize = styled.span``;

const PriceDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 30px 0px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
`;

const ProductAmountContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

// const ProductAmount = styled.div`
//   font-size: 24px;
//   margin: 5px;
//   ${mobile({ margin: "5px 15px" })}
// `;

const ProductPrice = styled.div`
  font-size: 20px;
  font-weight: 200;
  ${mobile({ marginBottom: "20px" })}
`;

const Hr = styled.hr`
  background-color: #eee;
  border: none;
  height: 1px;
`;

const Summary = styled.div`
  flex: 1;
  border: 0.5px solid lightgray;
  border-radius: 10px;
  padding: 20px;
  height: 50vh;
`;

const SummaryTitle = styled.h1`
  font-weight: 200;
`;

const SummaryItem = styled.div`
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
  font-weight: ${(props) => props.type === "total" && "500"};
  font-size: ${(props) => props.type === "total" && "24px"};
`;

const SummaryItemText = styled.span``;

const SummaryItemPrice = styled.span``;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  font-weight: 600;
`;

const Cart = () => {
  const products = useSelector((state) => state.cart.products);
  const { total } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const history = useHistory();
  
  const removeFromCartHandler = (item) => {
    dispatch(removeItemFromCart({ _id: item._id }));
  };

  const incrementCartHandler = (item) => {
    dispatch(incrementCartItem({ item }));
  };
  const decrementCartHandler = (item) => {
    dispatch(decrementCartItem({ _id: item._id }));
  };

  const handleContinueShopping = () => {
    // history.push("/shop");
    history.push("/");
  };

  const checkoutHandler = () => {
    history.push("/shipping");
  };
  return (
    <Layout>
      {/* <Navbar /> */}
      <Container>
        <Wrapper>
          <Announcement />
          <Title>Your Cart</Title>
          <Top>
            <TopButton
              style={{
                borderRadius: "10px",
                backgroundColor: "black",
                color: "white",

                padding: "10px",
                margin: "10px",
                cursor: "pointer",
                border: "none",
                outline: "none",
              }}
              onClick={handleContinueShopping}
            >
              CONTINUE SHOPPING
            </TopButton>
            <TopTexts>
              {/* <TopText>Shopping Bag(2)</TopText>
            <TopText>Your Wishlist (0)</TopText> */}
            </TopTexts>
            {/* <TopButton type="filled">CHECKOUT NOW</TopButton> */}
          </Top>
          <Bottom>
            {/* If products length is less or equal to zero, show cart as empty */}
            {!products?.length > 0 && (
              <div style={{ color: "red" }}>
                <div>
                  <p>Your Cart is Empty!</p>
                </div>
              </div>
            )}
            <Info>
              {products.map((item) => (
                <Product key={item._id}>
                  <ProductDetail>
                    <Image src={item.imageUrl} alt={item.product_image} />
                    <Details>
                      <ProductName>
                        <b>Product:</b> {item.name}
                      </ProductName>
                      <ProductId>
                        <b>In Stock:</b> {item.countInStock}
                      </ProductId>
                      {/* <ProductColor color={item.color} /> */}
                      {/* <ProductSize>
                      <b>Size:</b> {item.size}
                    </ProductSize> */}
                    </Details>
                  </ProductDetail>
                  <PriceDetail>
                    <ProductPrice>Shs {item.price * item.qty}</ProductPrice>
                    <ProductAmountContainer>
                      {/* <Add onClick={() => handleAdd("inc")} /> */}
                      {/* <ProductAmount>{item.quantity}</ProductAmount> */}
                      <AmountContainer>
                        {/* <Remove onClick={IncrementQty} /> */}
                        <Remove onClick={() => decrementCartHandler(item)} />
                        <Amount>{item.qty}</Amount>
                        <Add onClick={() => incrementCartHandler(item)} />
                        <Col
                          md={2}
                          style={{
                            paddingLeft: "20px",
                          }}
                        >
                          <OverlayTrigger
                            delay={{ hide: 45, show: 30 }}
                            overlay={(props) => (
                              <Tooltip {...props}>Remove from Cart!</Tooltip>
                            )}
                            placement="top"
                          >
                            <Button
                              // type="button"
                              // variant="light"
                              style={{
                                borderRadius: 30,
                              }}
                              onClick={() => removeFromCartHandler(item)}
                            >
                              {/* <i className="fas fa-trash"></i> */}
                              Delete
                            </Button>
                          </OverlayTrigger>
                        </Col>
                      </AmountContainer>
                    </ProductAmountContainer>
                  </PriceDetail>
                </Product>
              ))}
              <Hr />
            </Info>
            <Summary>
              <SummaryTitle>ORDER SUMMARY</SummaryTitle>
              <SummaryItem>
                <SummaryItemText>Subtotal</SummaryItemText>
                <SummaryItemPrice> {total}</SummaryItemPrice>
              </SummaryItem>
              {/* <SummaryItem>
              <SummaryItemText>Estimated Shipping</SummaryItemText>
              <SummaryItemPrice> 5.90</SummaryItemPrice>
            </SummaryItem> */}
              <SummaryItem>
                <SummaryItemText>Shipping Discount</SummaryItemText>
                <SummaryItemPrice> 0.00</SummaryItemPrice>
              </SummaryItem>
              <SummaryItem type="total">
                <SummaryItemText>Total</SummaryItemText>
                <SummaryItemPrice>
                  Shs{" "}
                  {/* {cart.total?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                  {total?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </SummaryItemPrice>
              </SummaryItem>
              {/* <StripeCheckout
              name="Lama Shop"
              image="https://avatars.githubusercontent.com/u/1486366?v=4"
              billingAddress
              shippingAddress
              description={`Your total is $${cart.total}`}
              amount={cart.total * 100}
              token={onToken}
              stripeKey={KEY}
            >
              <Button>CHECKOUT NOW</Button>
            </StripeCheckout> */}
              <Button
                style={{
                  borderRadius: "10px",
                  cursor: !products?.length > 0 ? "not-allowed" : "pointer",
                  color: "lightgreen",
                  backgroundColor: "black",
                }}
                onClick={checkoutHandler}
                disabled={!products?.length > 0}
              >
                CHECKOUT NOW
              </Button>
            </Summary>
          </Bottom>
        </Wrapper>
        {/* <Footer /> */}
      </Container>
    </Layout>
  );
};

export default Cart;
