import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as ArrowLeftIcon } from "../assets/img/ArrowLeftIcon.svg";
import { ReactComponent as ArrowRightIcon } from "../assets/img/ArrowRightIcon.svg";
import ArrowLeftMuiIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightMuiIcon from "@mui/icons-material/ArrowRight";

import Footer from "../components/Footer";
import playStore from "../assets/img/Play Store.png";
import AppStore from "../assets/img/App store Icon.png";
import {
  fetchPromoListings,
  fetchPromoProductDetails,
} from "../redux/listingRedux.js";
import Layout from "../components/Global/Layout.js";
import { mobile } from "../responsive.js";
import {
  fetchCategories,
  filterCategory,
  selectActiveCategories,
} from "../redux/categoriesRedux";
import QuantityControls from "../components/Buttons/QuantityControlButtons.js";
import { addToCart } from "../redux/cartRedux.js";
import vendorIcon from "../assets/icon.png";
import {
  fetchRestaurants,
  setSelectedRestaurant,
} from "../redux/restaurantSlice.js";
import RestaurantCardSkeleton from "./RestaurantCardSkeleton.js";
import { fetchPromotedVendors } from "../redux/promotedVendorsSlice.js";
import { setSelectedVendor } from "../redux/wholesaleRetailVendorSlice.js";
import placeholderRetailerImage from "../assets/retailerImage4.png";
import placeholderRestaurantImage from "../assets/restaurantPlaceholderResized.png";
import { setCurrentRestaurant } from "../redux/restaurantCartSlice.js";

// Custom arrow components
const PrevArrow = ({ className, style, onClick }) => (
  <ArrowButton className={className} style={{ ...style }} onClick={onClick}>
    <ArrowLeftIcon />
  </ArrowButton>
);

const NextArrow = ({ className, style, onClick }) => (
  <ArrowButton
    className={className}
    style={{ ...style, display: "block" }}
    onClick={onClick}
  >
    <ArrowRightIcon />
  </ArrowButton>
);

const scrollRestaurants = (direction) => {
  const container = document.getElementById("restaurantGrid");
  const scrollAmount = direction * 300; // Adjust based on your layout
  container.scrollBy({ left: scrollAmount, behavior: "smooth" });
};

const scrollVendors = (direction) => {
  const container = document.getElementById("vendorGrid");
  const scrollAmount = direction * 300; // Adjust based on your layout
  container.scrollBy({ left: scrollAmount, behavior: "smooth" });
};

const CategoriesSkeleton = () => {
  return (
    <CategoriesContentContainer>
      <Skeleton
        variant="rect"
        width="100%"
        height={30}
        style={{
          borderBottomLeftRadius: "14px",
          borderBottomRightRadius: "14px",
        }}
      />
      <StyledSliderCategories>
        {" "}
        {/* Remove {...categorySliderSettings} from here */}
        {Array.from(new Array(4)).map((_, index) => (
          <div key={index}>
            <CategoryPillSkeleton>
              <Skeleton
                variant="rect"
                width="100%"
                height="70%"
                className="skeleton-rect"
              />
              <Skeleton
                variant="text"
                width="100%"
                height="30%"
                className="skeleton-text"
              />
            </CategoryPillSkeleton>
          </div>
        ))}
      </StyledSliderCategories>
    </CategoriesContentContainer>
  );
};

// Doc reference
// https://react-slick.neostack.com/docs/example/append-dots
const bannerSettings = {
  dots: true,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 3500,
  adaptiveHeight: true, // Add this if images have different heights
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  afterChange: function () {
    let siblings = document.querySelectorAll(".slick-slide");
    let current = document.querySelector(".slick-current");
    // Set z-index of all slides to 0
    siblings.forEach((slide) => {
      slide.style.zIndex = "0";
    });
    // Set z-index of the current slide to 1 to ensure it's clickable
    if (current) {
      current.style.zIndex = "1";
    }
  },
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 2, // Even fewer items for mobile screens
        slidesToScroll: 1,
      },
    },
  ],
};

// Categories Slider
// const categorySliderSettings = {
//   dots: false,
//   infinite: true,
//   speed: 800,
//   slidesToShow: 4,
//   slidesToScroll: 1,
//   autoplay: autoplay,
//   autoplaySpeed: 3000,
//   pauseOnHover: true,
//   cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)",
//   nextArrow: <CustomNextArrow />,
//   prevArrow: <CustomPrevArrow />,
//   responsive: [
//     {
//       breakpoint: 1200,
//       settings: {
//         slidesToShow: 3,
//         slidesToScroll: 1,
//       },
//     },
//     {
//       breakpoint: 768,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 1,
//         arrows: true, // Keep arrows visible on tablet
//       },
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 1.5, // Show one full and half of next slide
//         slidesToScroll: 1,
//         arrows: true,
//         infinite: true,
//         centerMode: true,
//         centerPadding: "40px",
//       },
//     },
//   ],
// };

// const CustomPrevArrow = ({ className, onClick }) => (
//   <NavigationArrow className={className} onClick={onClick} position="left">
//     <ArrowLeftIcon />
//   </NavigationArrow>
// );

// const CustomNextArrow = ({ className, onClick }) => (
//   <NavigationArrow className={className} onClick={onClick} position="right">
//     <ArrowRightIcon />
//   </NavigationArrow>
// );

// Array of slider images || These will be fetched dynamically from the server anyway!
const sliderImages = [
  {
    id: 11,
    url: "https://res.cloudinary.com/lusuku/image/upload/v1704573713/Sliders/Shop-Now_Jan_2023_bctc4w.jpg",
    alt: "Shop for variety",
    categoryName: "All",
    buttonText: "Variety of products..",
    linkType: "category",
  },
  {
    id: 0,
    url: "https://res.cloudinary.com/lusuku/image/upload/v1703700011/Sliders/Become-a-Vendor-2_kypq0a.jpg",
    alt: "Become a Vendor",
    link: "https://lusuku.shop/vendorRegistration",
    buttonText: "Become a Vendor!",
    linkType: "external",
  },
  {
    id: 1,
    url: "https://res.cloudinary.com/lusuku/image/upload/v1703700013/Sliders/Wide-range-of-assortments_hkpdc8.jpg",
    alt: "Assortment Variety",
    categoryName: "All",
    buttonText: "Shop now 🛍️..",
    linkType: "category",
  },
  {
    id: 2,
    url: "https://res.cloudinary.com/lusuku/image/upload/v1703525724/Sliders/Dry-cleaning-banner_juiew5.jpg",
    alt: "Dry Cleaning at you door",
    categoryName: "Laundry",
    buttonText: "Laundry Services..",
    linkType: "category",
  },
  {
    id: 3,
    url: "https://res.cloudinary.com/lusuku/image/upload/v1703525754/Sliders/Stay-at-Home-Banner_bxx9d1.jpg",
    alt: "Stay home, order online",
    categoryName: "All",
    buttonText: "Browse products..",
    linkType: "category",
  },
];

const CategoriesSection = ({
  categories,
  selectedCategory,
  handleCategoryClick,
  isFetching,
}) => {
  const sliderRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [autoplay, setAutoplay] = useState(true);

  useEffect(() => {
    // Reset autoplay when categories change
    if (categories.length > 0 && !isHovered) {
      setAutoplay(true);
    }
  }, [categories, isHovered]);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: autoplay,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)",
    arrows: true,
    nextArrow: (
      <NavigationArrow position="right">
        <ArrowRightIcon />
      </NavigationArrow>
    ),
    prevArrow: (
      <NavigationArrow position="left">
        <ArrowLeftIcon />
      </NavigationArrow>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          arrows: true,
          infinite: true,
          centerMode: true,
          centerPadding: "40px",
        },
      },
    ],
  };

  return (
    <CategoriesWrapper>
      <CategoriesContentContainer
        onMouseEnter={() => {
          setIsHovered(true);
          setAutoplay(false);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          setAutoplay(true);
        }}
      >
        <CategoriesHeader>
          <HeaderContent>
            <HeaderTitle>
              {isFetching ? (
                <LoadingText>Loading Categories...</LoadingText>
              ) : (
                <AnimatedText>BROWSE CATEGORIES</AnimatedText>
              )}
            </HeaderTitle>
            {!isFetching && (
              <CategoryCount>{categories.length} Categories</CategoryCount>
            )}
          </HeaderContent>
        </CategoriesHeader>

        {isFetching || categories.length === 0 ? (
          <CategoriesSkeleton />
        ) : (
          <AnimatePresence>
            <StyledSliderCategories ref={sliderRef} {...sliderSettings}>
              {categories.map((category, index) => (
                <motion.div
                  key={category._id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                >
                  <CategoryPill
                    id={`category-${category.category}`}
                    isSelected={selectedCategory === category.category}
                    onClick={() => handleCategoryClick(category.category)}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <ImageContainer>
                      <CategoryImage
                        src={
                          category.category_pic ||
                          "https://res.cloudinary.com/lusuku/image/upload/v1702635550/Lusuku_Public_Resources/No_Category_Pic_Placecholder-Prod_ltsbhf.jpg"
                        }
                        alt={category.category}
                      />
                      <ImageOverlay />
                    </ImageContainer>
                    <CategoryName>{category.category}</CategoryName>
                  </CategoryPill>
                </motion.div>
              ))}
            </StyledSliderCategories>
          </AnimatePresence>
        )}
      </CategoriesContentContainer>
    </CategoriesWrapper>
  );
};

const LandingPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const { currentRestaurant } = useSelector((state) => state.restaurantCart);
  const { promoListings, isFetching } = useSelector((state) => state.products);
  const { restaurantData, isFetching: isFetchingRestaurants } = useSelector(
    (state) => state.restaurants
  );
  const { promotedVendors, isFetching: isFetchingPromotedVendors } =
    useSelector((state) => state.promotedVendors);
  const selectedCategory = useSelector(
    (state) => state.categories.selectedCategory
  );

  const [showLeftArrowRestaurants, setShowLeftArrowRestaurants] =
    useState(false);
  const [showRightArrowRestaurants, setShowRightArrowRestaurants] =
    useState(true);

  const [showLeftArrowVendors, setShowLeftArrowVendors] = useState(false);
  const [showRightArrowVendors, setShowRightArrowVendors] = useState(true);

  const cartProducts = useSelector((state) => state.cart.products);

  const sliderRef = useRef();

  // Define the isInCart function
  const isInCart = (productId) => {
    return cartProducts.some((product) => product._id === productId);
  };

  //getQuantity function
  const getQuantity = (productId) => {
    const product = cartProducts.find((p) => p._id === productId);
    return product ? product.qty : 0;
  };

  // Fetch categories from Redux store
  const categories = useSelector(selectActiveCategories);

  // Get Restaurants
  useEffect(() => {
    dispatch(fetchRestaurants());
    dispatch(fetchPromotedVendors());
  }, [dispatch]);

  useEffect(() => {
    if (!promoListings.length) {
      dispatch(fetchPromoListings());
    }
  }, [dispatch, promoListings.length]);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const openPostDetail = (Id) => {
    return () => {
      dispatch(fetchPromoProductDetails(Id));
      history.push(`/promoProduct/${Id}`);
    };
  };

  // const handleRestaurantSelection = (restaurant) => {
  //   history.push({
  //     pathname: `/restaurant/${restaurant._id}`,
  //     state: { restaurant },
  //   });
  // };
  const handleRestaurantSelection = (restaurant) => {
    dispatch(setSelectedRestaurant(restaurant));
    dispatch(setCurrentRestaurant(restaurant.owner));
    history.push(`/restaurant/shop/${restaurant.owner}`);
  };

  const handleVendorSelection = (vendorId) => {
    dispatch(setSelectedVendor(vendorId));
    history.push(`/shop/${vendorId}`);
  };

  // Navigate to Selected Category on Click
  const handleCategoryClick = (categoryName) => {
    dispatch(filterCategory(categoryName));
    history.push("/shop?page=1");
  };

  // Navigate to Selected Category from the Banner
  const handleBannerClick = (categoryNameFromBanner) => {
    dispatch(filterCategory(categoryNameFromBanner));
    history.push("/shop?page=1");
  };

  const handleAddToCartClick = (product, event) => {
    event.preventDefault(); // Prevents any default anchor action
    dispatch(addToCart({ item: product }));
  };

  // //For active Category pill to snap in view
  useEffect(() => {
    if (selectedCategory) {
      // Use setTimeout to wait for the next tick, ensuring that the DOM has updated
      setTimeout(() => {
        const activePill = document.querySelector(
          `#category-${selectedCategory}`
        );
        if (activePill) {
          const sliderList = document.querySelector(".slick-list"); // Adjust the selector if needed
          if (sliderList) {
            const sliderWidth = sliderList.offsetWidth;
            const pillOffsetLeft = activePill.offsetLeft;
            const pillWidth = activePill.offsetWidth;

            if (sliderWidth && pillWidth && pillOffsetLeft) {
              const scrollPosition =
                pillOffsetLeft - sliderWidth / 2 + pillWidth / 2;

              // Use the slickGoTo method if it's available
              if (sliderRef.current && sliderRef.current.slickGoTo) {
                const slideIndex = Math.floor(scrollPosition / pillWidth);
                sliderRef.current.slickGoTo(slideIndex);
              } else {
                console.error("slickGoTo method is not available on the ref");
              }
            } else {
              console.error("One of the required properties is undefined:", {
                sliderWidth,
                pillWidth,
                pillOffsetLeft,
              });
            }
          } else {
            console.error("Unable to find the slick-list element.");
          }
        }
      });
    }
  }, [selectedCategory]);

  useEffect(() => {
    const restaurantGrid = document.getElementById("restaurantGrid");
    const vendorGrid = document.getElementById("vendorGrid");

    const checkScrollRestaurants = () => {
      setShowLeftArrowRestaurants(restaurantGrid.scrollLeft > 0);
      setShowRightArrowRestaurants(
        restaurantGrid.scrollLeft <
          restaurantGrid.scrollWidth - restaurantGrid.clientWidth
      );
    };

    const checkScrollVendors = () => {
      setShowLeftArrowVendors(vendorGrid.scrollLeft > 0);
      setShowRightArrowVendors(
        vendorGrid.scrollLeft < vendorGrid.scrollWidth - vendorGrid.clientWidth
      );
    };

    checkScrollRestaurants();
    checkScrollVendors();

    restaurantGrid.addEventListener("scroll", checkScrollRestaurants);
    vendorGrid.addEventListener("scroll", checkScrollVendors);

    return () => {
      restaurantGrid.removeEventListener("scroll", checkScrollRestaurants);
      vendorGrid.removeEventListener("scroll", checkScrollVendors);
    };
  }, []);

  const activeRestaurants = restaurantData.filter(
    (restaurant) => restaurant.isActive
  );
  const activePromotedVendors = promotedVendors.filter(
    (vendor) => vendor.isActive
  );

  return (
    <>
      <Layout>
        <PageContainer>
          <MainBanner>
            <CustomSliderDots>
              {isFetching || sliderImages.length === 0 ? (
                <BannerSkeleton>
                  <Skeleton variant="rect" width="100%" height="100%" />
                  <Skeleton
                    variant="text"
                    width="60%"
                    height={28}
                    style={{ position: "absolute", bottom: 10, left: "20%" }}
                  />
                </BannerSkeleton>
              ) : (
                <StyledSlider {...bannerSettings}>
                  {sliderImages.map((slide) => (
                    <div key={slide.id}>
                      <SliderImage src={slide.url} alt={slide.alt} />
                      {slide.linkType === "category" ? (
                        <BannerButton
                          onClick={() => handleBannerClick(slide.categoryName)}
                        >
                          {slide.buttonText}
                        </BannerButton>
                      ) : (
                        <a href={slide.link} style={{ textDecoration: "none" }}>
                          <BannerButton>{slide.buttonText}</BannerButton>
                        </a>
                      )}
                    </div>
                  ))}
                </StyledSlider>
              )}
            </CustomSliderDots>
          </MainBanner>

          {/* Top Restaurants */}
          <RestaurantContentContainer>
            <RestaurantProductGridHeader>
              <SectionTitle>Top Restaurants</SectionTitle>
            </RestaurantProductGridHeader>
            <RestaurantProductGrid id="restaurantGrid">
              {isFetchingRestaurants
                ? Array.from(new Array(3)).map((_, index) => (
                    <RestaurantCardSkeleton key={index} />
                  ))
                : activeRestaurants.map((restaurant) => (
                    <RestaurantCard
                      key={restaurant._id}
                      onClick={() => handleRestaurantSelection(restaurant)}
                    >
                      <RestaurantLogo
                        src={restaurant.imageUrl || placeholderRestaurantImage}
                        alt={restaurant.name}
                      />
                      <RestaurantName>{restaurant.name}</RestaurantName>
                      <RestaurantBadge
                        src={vendorIcon}
                        alt="Restaurant Badge"
                      />
                      <PromotionMessage>{restaurant.address}</PromotionMessage>
                    </RestaurantCard>
                  ))}
            </RestaurantProductGrid>
            <LeftArrow
              style={{ display: showLeftArrowRestaurants ? "flex" : "none" }}
              onClick={() => scrollRestaurants(-1)}
            >
              <ArrowLeftMuiIcon style={{ color: "white", fontSize: 25 }} />
            </LeftArrow>
            <RightArrow
              style={{ display: showRightArrowRestaurants ? "flex" : "none" }}
              onClick={() => scrollRestaurants(1)}
            >
              <ArrowRightMuiIcon style={{ color: "white", fontSize: 25 }} />
            </RightArrow>
          </RestaurantContentContainer>

          {/* Top Vendors */}
          <VendorContentContainer>
            <VendorProductGridHeader>
              <SectionTitle>Top Vendors</SectionTitle>
            </VendorProductGridHeader>
            <VendorProductGrid id="vendorGrid">
              {isFetchingPromotedVendors
                ? Array.from(new Array(3)).map((_, index) => (
                    <RestaurantCardSkeleton key={index} />
                  ))
                : activePromotedVendors.map((vendor) => (
                    <VendorCard
                      key={vendor._id}
                      bgColor={vendor.bgColor}
                      onClick={() => handleVendorSelection(vendor.owner)}
                    >
                      <VendorLogo
                        src={vendor.imageUrl || placeholderRetailerImage}
                        alt={vendor.name}
                      />
                      <VendorName>{vendor.name}</VendorName>
                      <VendorBadge src={vendorIcon} alt="Vendor Badge" />
                      <PromotionMessage
                        onClick={() => handleVendorSelection(vendor.owner)}
                      >
                        {vendor.promoCaption || "Shop Now"}
                      </PromotionMessage>
                    </VendorCard>
                  ))}
            </VendorProductGrid>
            <LeftArrow
              style={{ display: showLeftArrowVendors ? "flex" : "none" }}
              onClick={() => scrollVendors(-1)}
            >
              <ArrowLeftMuiIcon style={{ color: "white", fontSize: 25 }} />
            </LeftArrow>
            <RightArrow
              style={{ display: showRightArrowVendors ? "flex" : "none" }}
              onClick={() => scrollVendors(1)}
            >
              <ArrowRightMuiIcon style={{ color: "white", fontSize: 25 }} />
            </RightArrow>
          </VendorContentContainer>

          <ContentContainer>
            <ProductGridHeader>
              <SectionTitle>DEALS & OFFERS</SectionTitle>
              <ViewMore to="/shop">See more products &gt;&gt;</ViewMore>
            </ProductGridHeader>
            <ProductGrid>
              {isFetching || promoListings.length === 0
                ? Array.from(new Array(5)).map((_, index) => (
                    <ProductSkeleton key={index}>
                      <Skeleton
                        variant="rect"
                        width="100%"
                        height={118}
                        className="skeleton-rect"
                      />
                      <Skeleton
                        width="140px"
                        height={20}
                        className="skeleton-text-short"
                      />
                      <Skeleton
                        width="80%"
                        height={20}
                        className="skeleton-text-long"
                      />
                      <Skeleton
                        variant="rect"
                        width="80%"
                        height={36}
                        className="skeleton-button"
                      />
                    </ProductSkeleton>
                  ))
                : promoListings.map((product) => (
                    <Card key={product._id}>
                      <ProductImage
                        src={product.product_image}
                        alt={product.title}
                        onClick={openPostDetail(product._id)}
                      />
                      <ProductName>{product.title}</ProductName>
                      <ProductPrice>
                        Shs {product.price.toLocaleString()}
                      </ProductPrice>
                      {isInCart(product._id) ? (
                        <QuantityControls
                          product={product}
                          quantity={getQuantity(product._id)}
                        />
                      ) : (
                        <AddToCartButton
                          onClick={(event) =>
                            handleAddToCartClick(product, event)
                          }
                        >
                          Add to Cart
                        </AddToCartButton>
                      )}
                    </Card>
                  ))}
            </ProductGrid>
          </ContentContainer>

          {/* Categories Slider */}
          <CategoriesSection
            categories={categories}
            selectedCategory={selectedCategory}
            handleCategoryClick={handleCategoryClick}
            isFetching={isFetching}
          />

          <AppDownload>
            <a
              href="https://play.google.com/store/apps/details?id=com.lusuku&pli=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AppBadge src={playStore} alt="Get it on Google Play" />
            </a>
            <a
              href="https://apps.apple.com/gb/app/lusuku/id1622613658"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AppBadge src={AppStore} alt="Download on the App Store" />
            </a>
          </AppDownload>
        </PageContainer>
        <Footer />
      </Layout>
    </>
  );
};

export default LandingPage;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 480px) {
    padding: 0 5px; // Reduce side padding on mobile
    // margin-top: 3px;
    margin: 15px;
  }
`;

const VendorContentContainer = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  overflow: visible;

  @media (max-width: 480px) {
    padding: 0 5px; // Reduce side padding on mobile
    margin: 15px;
  }
`;

const RestaurantContentContainer = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  overflow: visible;

  @media (max-width: 480px) {
    padding: 0 5px; // Reduce side padding on mobile
    margin: 15px;
  }
`;

const CategoryPillSkeleton = styled.div`
  width: 240px;
  height: 140px;
  border-radius: 10px;
  background-color: #ececec;
  margin: 0.5rem; /* Add margin to create space between skeletons */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .skeleton-rect {
    width: 100%;
    height: 70%;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .skeleton-text {
    width: 100%;
    height: 30%;
  }

  @media (max-width: 768px) {
    width: 180px;
    height: 100px;
  }

  @media (max-width: 480px) {
    width: 150px;
    height: 84px;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  width: 100%;
  align-items: stretch;
  margin: 0 auto;
  padding: 0;
`;

const VendorProductGrid = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 20px 0;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  position: relative;
  margin: 0 8px; // Adjust left and right margin to allow space for arrow

  &::-webkit-scrollbar {
    display: none;
  }
`;

const RestaurantProductGrid = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 20px 0;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  position: relative;
  margin: 0 8px; // Adjust left and right margin to allow space for arrow

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1em;
  justify-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow-y: auto; // Enable vertical scrolling
  max-height: 500px; // Set a max height for the grid container

  @media (max-width: 768px) {
    grid-template-columns: repeat(
      2,
      1fr
    ); // Show two columns on smaller screens
    max-height: 400px; // You can adjust this value based on the desired viewport
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(
      2,
      1fr
    ); // Keep two columns on the smallest screens
    max-height: 300px; // Adjust the height for smaller screens
  }
`;

const ProductName = styled.h3`
  font-size: 1rem;
  margin: 0.5rem 0;
`;

// Styles for the slick slider
const StyledSlider = styled(Slider)`
  .slick-slide img {
    margin: auto;
    padding: 0 10px;
  }
  .slick-list {
    margin: 0 auto;
    padding: 0 20px;
  }
  .slick-track {
    display: flex;
    align-items: center;
  }
  .slick-prev,
  .slick-next {
    z-index: 7;
    font-size: 24px;
    margin: 0 28px;
    // background-color: #BD2A2E;
    background-color: #152618;
  }
`;
const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
`;

const StyledSliderCategories = styled(Slider)`
  position: relative;
  width: 95%; // This will prevent full width while keeping responsiveness
  max-width: 1200px;
  margin: 0 auto;

  .slick-track {
    display: flex;
    gap: 1rem;
    padding: 1rem 0;
  }

  .slick-slide {
    padding: 0.5rem;
  }

  .slick-list {
    margin: 0 -0.5rem;
  }

  // Hide default arrows
  // .slick-prev,
  // .slick-next {
  //   display: none !important;
  // }

  @media (max-width: 480px) {
    width: 90%;
    margin: 0 auto;

    .slick-slide {
      padding: 0.3rem;
    }
  }
`;
// Custom CSS for the dots
const CustomSliderDots = styled.div`
  .slick-dots li button:before {
    color: #515151; // Change to the color for the dots
    font-size: 10px;
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    color: #f4224b;
  }
`;

const SliderImage = styled.img`
  width: 100%; // Ensures the image is responsive and covers the slider area
  max-height: 500px; // Adjust the max-height as necessary
  object-fit: cover; // This will cover the area without stretching the image
`;

const MainBanner = styled.div`
  margin-top: -6px; 
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slick-slide img {
    width: auto; // Maintain the original image width
    height: 300px; // Set a fixed height for the images
  }
  }
  // Use the mobile function for smaller mobile devices (e.g., width <= 480px)

${mobile({
  marginBottom: "5px",
  width: "432px",
  height: "auto",
  ".slick-slide img": {
    height: "auto",
    width: "480px",
    paddingTop: "20px",
  },
})}
`;

const BannerButton = styled.button`
  position: absolute;
  bottom: 20px;
  right: 0;
  margin-right: 20px;
  // left: 50%;
  transform: translateX(-50%);
  padding: 8px 12px;
  border: none;
  background-color: #334d55;
  color: white;
  font-size: 14px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 1px 1px 6px rgba(55, 250, 95, 98);
  &:hover {
    background-color: #598089;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.8);
  }

  @media (max-width: 480px) {
    font-size: 8px;
    padding: 3px 7px;
    bottom: 8px;
    right: 10px;
    margin-right: 10px;
    border-radius: 6px;
  }
`;

const SectionTitle = styled.h2`
  text-align: left;
  font-size: 1.2rem;
  margin: 20px 0;
  padding: 0;
  ${mobile({
    fontSize: "0.9rem",
  })}
`;

const Card = styled.div`
  display: flex;
  flex-direction: column; 
  justify-content: center
  align-items: center;
  border: 1px solid #ccc;
  padding: 1em;
  text-align: center;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: box-shadow 0.3s ease-in-out;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-bottom: 18px;
  &:hover {
    box-shadow: 0 6px 12px 0 rgba(0,0,0,0.3); // Larger shadow on hover for a "lifted" effect
  }
`;

const AppDownload = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  margin: 20px 0;
`;

const AppBadge = styled.img`
  width: 150px;
  height: auto;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const ProductImage = styled.img`
  width: 100%;
  max-width: 200px;
  height: auto;
`;

const AddToCartButton = styled.button`
  color: #f0f0f0;
  background-color: #334d55;
  border: none;
  border-radius: 10px;
  padding: 0.7rem 1.3rem;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease; // Smooth background color transition on hover
  &:hover {
    background-color: #598089;
    color: #f0f0f0;
  }
`;

const ProductGridHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VendorProductGridHeader = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

const RestaurantProductGridHeader = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

const ViewMore = styled(Link)`
  font-size: 14px;
  font-style: italic;
  text-decoration: none;
  padding: 0.3rem 1rem; // Pill shape typically has larger padding
  color: white; // Text color
  background-color: #ef3b47; // Slightly lighter color on hover
  border-radius: 10px; // Fully rounded corners for pill shape
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out; // Smooth transitions for color and transform

  &:hover {
    background-color: #445e69;
    text-decoration: underline;
    transform: scale(1.05); // Slightly increase the size on hover
  }
  ${mobile({
    fontSize: "11px",
    padding: "0.2rem 0.8rem",
  })}
`;

const ProductSkeleton = styled.div`
  border: 1px solid #ccc;
  padding: 1em;
  text-align: center;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-bottom: 18px;
  max-width: 200px; /* Ensure the skeleton matches the product card dimensions */
  width: 100%;
  height: auto;
  margin: 30px; /* Add margin to create space between skeletons */

  .skeleton-rect {
    width: 100%;
    height: 118px;
    margin-bottom: 10px;
    border-radius: 8px; /* Match the image's border radius */
  }

  .skeleton-text-short {
    width: 140px;
    height: 20px;
    margin-bottom: 6px;
  }

  .skeleton-text-long {
    width: 80%;
    height: 20px;
    margin-bottom: 6px;
  }

  .skeleton-button {
    width: 80%;
    height: 36px;
    margin-bottom: 6px;
  }
`;

const ProductPrice = styled.span`
  display: block;
  font-size: 1em;
  color: #333;
  margin: 5px 0;
`;

const BannerSkeleton = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
`;

const CategoryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
`;

const CategoryName = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.8rem;
  color: white;
  font-weight: 500;
  text-align: center;
  background: rgba(0, 0, 0, 0.8); // Darker background
  font-size: 1rem;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    padding: 0.6rem;
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
    font-size: 0.8rem;
  }
`;

const CategoryPill = styled(motion.div)`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  height: 180px;
  position: relative;
  transition: all 0.3s ease;
  border: 2px solid ${(props) => (props.isSelected ? "#FCBA14" : "transparent")};
  margin: 0 10px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

    ${CategoryImage} {
      transform: scale(1.1);
    }
  }

  @media (max-width: 768px) {
    height: 150px;
  }

  @media (max-width: 480px) {
    height: 120px;
  }
`;

const CategoriesHeader = styled.div`
  background-color: #fcba14;
  padding: 16px;
  border-radius: 10px;
  // Shadow at the bottom
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  position: relative;
`;

// const CategoriesHeaderContent = styled.div`
//   margin: 0 auto;
// `;

const ArrowButton = styled.button`
  z-index: 7;
  width: 30px;
  height: 30px;
  background: black;
  border-radius: 15px; // Half of width/height to create circle
  color: white;
  border: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;

  // hide default arrows
  &:before {
    content: none;
  }

  &:hover {
    background: #333; // Darken button on hover
  }
`;

const RestaurantBadge = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
  border: 2px solid #fff;
`;

const VendorBadge = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
  border: 2px solid #fff;
`;

const PromotionMessage = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  color: #333;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 0.9rem;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }
`;

const RestaurantCard = styled.div`
  flex: 0 0 auto;
  width: 300px; // Adjust width as necessary
  margin: 20px;
  background-color: #ffffff;
  color: #333;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  scroll-snap-align: start;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center; // Centering content
  justify-content: center;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  &:last-child {
    margin-right: 0; // Remove right margin for the last card
  }
`;

const VendorCard = styled.div`
  flex: 0 0 auto;
  width: 300px; // Adjust width as necessary
  margin: 20px; // Add margin for spacing around the card
  background-color: #ffffff;
  color: #333;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  scroll-snap-align: start;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center; // Centering content
  justify-content: center;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`;

const VendorArrowButton = styled.button`
  position: absolute;
  top: 57%;
  transform: translateY(-50%);
  width: 22px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  &:hover {
    background-color: #555;
  }
`;

const LeftArrow = styled(VendorArrowButton)`
  left: calc(15px - 10px);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

const RightArrow = styled(VendorArrowButton)`
  right: calc(15px - 10px);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const VendorLogo = styled.img`
  width: 100%;
  height: 190px; // Adjust based on your design
  object-fit: cover; // Ensures the entire image fits within the container without cropping
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0; // Optional: adds a background color for better visibility
`;

const VendorName = styled.h3`
  margin-top: 10px;
  font-size: 1.2rem;
`;

const RestaurantLogo = styled.img`
  width: 100%;
  height: 190px; // Adjust based on requirement
  object-fit: cover; // Ensures the entire image covers the container without cropping
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0; // Optional: adds a background color for better visibility
`;

const RestaurantName = styled.h3`
  margin-top: 10px;
  font-size: 1.2rem;
`;
const CategoriesWrapper = styled.div`
  margin: 2rem 0;
  background: linear-gradient(135deg, #344d55 0%, #1a2f35 100%);
  padding: 1rem 0;
  overflow: hidden;
  position: relative;

  @media (max-width: 480px) {
    margin: 1rem 0;
    padding: 0.5rem 0;
  }
`;

const CategoriesContentContainer = styled.div`
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  overflow: visible;

  @media (max-width: 480px) {
    width: 90%;
    padding: 0 0.5rem;
    border-radius: 10px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;

  @media (max-width: 480px) {
    padding: 0.8rem 1rem;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const AnimatedText = styled(motion.h2)`
  color: #a14;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  letter-spacing: 1px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const LoadingText = styled(motion.div)`
  color: #fcba14;
  font-size: 1.2rem;
  font-style: italic;
`;

const CategoryCount = styled.span`
  color: #fefe;
  font-size: 0.9rem;
  background: rgba(0, 0, 0, 0.3);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.3);
`;

const ImageContainer = styled.div`
  position: relative;
  height: 75%;
  overflow: hidden;
`;
const NavigationArrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => (props.position === "left" ? "left: -25px;" : "right: -25px;")}
  width: 40px;
  height: 40px;
  background: rgba(252, 186, 20, 0.9);
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  opacity: 1 !important; // Force visibility
  visibility: visible !important; // Ensure arrows are always visible

  &:before {
    display: none; // Remove default slick arrow styling
  }

  svg {
    width: 20px;
    height: 20px;
    color: white;
  }

  &:hover {
    background: rgba(252, 186, 20, 1);
    transform: translateY(-50%) scale(1.1);
  }

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    ${(props) => (props.position === "left" ? "left: -15px;" : "right: -15px;")}
  }

  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
    ${(props) => (props.position === "left" ? "left: -10px;" : "right: -10px;")}
  }
`;
